import { EditorState, convertToRaw } from 'draft-js';
import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html'; // Library not typed
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
  onChange: (rawHtml: string) => void
}

export type EditorHandle = {
  clear: () => void,
}

const RawMarkdownEditor: React.ForwardRefRenderFunction<EditorHandle, Props> = ({ onChange }, forwardedRef) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const ref = React.createRef<Editor>();

  useEffect(() => {
    const rawHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (rawHTML === '<p></p>\n') {
      onChange('');
    } else { onChange(rawHTML); }
  }, [editorState, setEditorState, onChange]);

  useImperativeHandle(forwardedRef, () => ({
    clear: () => { setEditorState(EditorState.createEmpty()); },
  }));

  return (
    <Editor
      ref={ref}
      toolbarClassName=""
      editorClassName="px-5 border-t rounded-sm"
      wrapperClassName="border rounded"
      editorStyle={{
        minHeight: '180px',
      }}
      toolbarStyle={{
        border: 'none',
      }}
      toolbar={{
        options: ['inline', 'list'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
      }}
      placeholder="Skriv inn.."
      editorState={editorState}
      onEditorStateChange={setEditorState}
    />
  );
};

export default forwardRef(RawMarkdownEditor);

export enum Icon{
  Smiley,
  CreditCard,
}

export enum BateIcon {
  Bonus = '/icons/bonus.png',
  Borettslag = '/icons/borettslag.png',
  Fordeler = '/icons/fordeler.png',
  Forfatter = '/icons/forfatter_publiserer.png',
  Innlogging = '/icons/innlogging_generelt.png',
  Kontakt = '/icons/kontakt.png',
  KR = '/icons/kr.png',
  LoggInn = '/icons/logg_inn.png',
  LoggUt = '/icons/logg_ut.png',
  Mail = '/icons/mail.png',
  Sykkel = '/icons/medlemsfordeler_1_sykkel.png',
  Drill = '/icons/medlemsfordeler_2_drill.png',
  Stol = '/icons/medlemsfordeler_3_stol.png',
  Klipper = '/icons/medlemsfordeler_4_klipper.png',
  MerInformasjon = '/icons/mer_informasjon.png',
  Prosent = '/icons/prosent.png',
  Time = '/icons/time.png',
  Hammer = '/icons/hammer.png',
  Oppslagstavle = '/icons/oppslagstavle.png',
  Blomst = '/icons/blomst.png',
}

export enum BoxSize {
  Small,
  Medium,
  Large
}

export enum TransactionStatus {
  NotStarted,
  Started,
  Success,
  Failed,
}

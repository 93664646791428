import React from 'react';

interface Props {
  width?: number;
  isBlue?: boolean;
}

const Divider: React.FC<Props> = ({ width = 1, isBlue = false }) => {
  const borderColor = isBlue ? '#f0f4f8' : '#DC151D';

  return (
    <hr
      className="my-10"
      style={{ borderTopWidth: `${width}px`, borderColor }}
    />
  );
};

export default Divider;

import React from 'react';
import { Link } from 'react-router-dom';
import { SimpleLinkItemModel } from '../../../common/types/sanity';
import styles from './index.module.scss';

interface Props {
  linkItem: SimpleLinkItemModel;
  children?: any;
}

const SimpleLinkItem: React.FC<Props> = ({ linkItem, children }) => {
  if (!linkItem.url) {
    return null;
  }

  const isRelativeLink = linkItem.url.startsWith('/');

  return (
    <>
      {children}
      {isRelativeLink ? (
        <Link
          to={{
            pathname: linkItem.url,
          }}
          className={styles.link}
        >
          {linkItem.text}
        </Link>
      ) : (
        <a
          href={linkItem.url}
          target={linkItem.openInNewWindow ? '_blank' : undefined}
          rel="noreferrer"
          className={styles.link}
        >
          {linkItem.text}
        </a>
      )}

    </>
  );
};

SimpleLinkItem.defaultProps = {
  children: undefined,
};

export default SimpleLinkItem;

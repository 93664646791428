import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { post } from '../../../api';
import Selector from '../../../common/components/selector/Selector';
import BateButton from '../../../components/Elements/BateButton';
import { useAppContext } from '../../../context/AppContext';
import { useForegroundLoader } from '../../../context/LoadingContext';
import { DigitalCastVoteEnum, DigitalVotingTypeEnum, IMeetingSectionModel } from '../../../types/digitalMeetings';
import Accordion, { AccordionHandle } from './Accordion';
import { CommentBox } from './CommentBox';
import { MeetingAttachment } from './MeetingAttachment';
import { Voter } from './Voter';
import { VotingStatus } from './VotingStatus';

type Props = {
  voting?: boolean;
  watching?: boolean;
  section: IMeetingSectionModel;
  index: number;
  subIndex?: number;
  meetingId: string;
  userBoardFunction: string;
  propertyId: string;
  onVoted: () => void,
}

export const CaseAccordion: React.FC<Props> = ({
  voting, watching, section, subIndex = 0, index, meetingId, userBoardFunction, propertyId, onVoted,
}) => {
  const { user } = useAppContext();

  const [hasVoted, setHasVoted] = useState(section.currentVoting !== DigitalCastVoteEnum.IkkeAngitt);
  const [comments, setComments] = useState(section.userComments);
  const [newComment, setNewComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<'new' | 'old'>('new');

  const accordionRef = useRef<AccordionHandle>(null);

  useForegroundLoader([loading]);

  const postComment = useCallback(
    async () => {
      if (newComment.length !== 0) {
        try {
          setLoading(true);
          await post(`/digitalmeetings/${meetingId}/section/${section.id}/comment`, {
            comment: newComment,
            boardFunctionDescription: userBoardFunction,
          });

          setComments([
            ...comments, {
              comment: newComment,
              commentedBy: `${user.firstname} ${user.surname}`,
              commentedByFunction: userBoardFunction,
              when: new Date().toISOString(),
              commentedByNameId: '',
              documentSectionId: section.id,
              id: '',
              isCachable: false,
              isDeleted: false,
              isUpdated: false,
              lastUpdated: '',
            }]);
          setNewComment('');
          setCommentError('');
        } catch {
          setCommentError('Kunne ikke publisere kommentar');
        } finally {
          setLoading(false);
        }
      } else {
        setCommentError('Du må skrive noe før du sender inn en kommentar');
      }
    },
    [newComment, meetingId, section, userBoardFunction, comments, user],
  );

  const filteredComments = useMemo(() => {
    if (comments) {
      // Return list ordered by oldest first
      return comments.sort((a, b) => {
        const aDate = new Date(a.when!);
        const bDate = new Date(b.when!);
        if (selectedFilter === 'old') {
          return aDate.getTime() - bDate.getTime();
        }
        return bDate.getTime() - aDate.getTime();
      });
    }
    return [];
  }, [comments, selectedFilter]);

  const isElection = useMemo(() => {
    return section.votingOptions === 'Valg';
  }, [section]);

  const isOrientation = useMemo(() => {
    return section.votingOptions === DigitalVotingTypeEnum.TilOrientering;
  }, [section]);

  const accordionHasContent = section.description || section.suggestedDescision || isElection || isOrientation
  || section.boardRecommendation || section.attachments.length > 0 || comments.length > 0;

  return (
    <Accordion
      ref={accordionRef}
      disableToggle={!accordionHasContent}
      header={(
        <h1 className="heading-underline text-base font-clanot-black mr-12 md:mr-24">
          <span className="pr-2">
            {index + 1}
            .
            {subIndex}
          </span>
          {section.title}
        </h1>
      )}
      subHeader={
        <VotingStatus section={section} hasVoted={hasVoted} />
    }
      bottomRightComponent={comments.length > 0 && (
        <div className="flex font-bold">
          <div className="mr-1">
            {comments.length}
          </div>
          <FiMessageSquare
            size={25}
            style={{
              transform: 'rotateY(180deg)',
            }}
          />
        </div>
      )}
    >
      <div className={`bg-light-blue px-5 py-8 lg:px-8 lg:py-10
        rounded-bate border-2 border-medium-light-grey ${voting ? 'pb-8' : 'mb-3'}`}
      >
        {/* Beskrivelse */ }
        {section.description && (
        <>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} className="">
            {section.description}
          </ReactMarkdown>
          <hr className="mt-5 mb-8 h-0.5 bg-medium-light-grey" />
        </>
        )}

        {/* Forslag */}
        {(section.suggestedDescision || isElection) && (
          <>
            <div className="font-clanot-black heading-underline mb-5">
              {isElection ? 'Kandidater' : 'Forslag til vedtak'}
            </div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {section.suggestedDescision}
            </ReactMarkdown>

            { isElection && (
            <>
              <div className="mb-2">
                Det skal stemmes over
                {' '}
                {section.election.candidates.length}
                {' '}
                {section.election.candidates.length === 1 ? 'kandidat' : 'kandidater'}
              </div>
              {section.election.candidates.map((candidate) => (
                <div key={candidate.id} className="flex items-center mb-5 pl-5">
                  {candidate.name}
                  {candidate.reelection ? ' ( Gjenvalg )' : ''}
                </div>
              ))}
            </>
            )}

            <hr className="mt-5 mb-8 h-0.5 bg-medium-light-grey" />
          </>
        )}

        {/* Styrets innstilling */}
        {section.boardRecommendation && (
        <>
          {' '}
          <div className="font-clanot-black heading-underline mb-5">Styrets innstilling</div>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} className="">
            {section.boardRecommendation}
          </ReactMarkdown>
          <hr className="mt-5 mb-8 h-0.5 bg-medium-light-grey" />
        </>
        )}

        {/* Vedlegg */}
        {section.attachments.length > 0 && (
          <>
            {' '}
            <div className="font-clanot-black heading-underline mb-5">Vedlegg</div>
            {section.attachments.length
              ? section.attachments.map((attachment) => (
                <MeetingAttachment
                  key={attachment.id}
                  path={`/digitalmeetings/${meetingId}/attachment/${attachment.id}`}
                  name={attachment.filnavn}
                  filename={attachment.filnavn}
                />
              ))
              : <div>Ingen vedlegg</div>}
            <hr className="mt-5 mb-8 h-0.5 bg-medium-light-grey" />
          </>
        )}

        {comments.length > 0 && (
        <>
          {/* Kommentarer */}
          <div className="font-clanot-black heading-underline mb-5">Kommentarer</div>
          {/* Filter list */}
          {comments.length > 1 && (
          <div className="flex justify-start">
            <Selector
              data={[{ name: 'Vis nyeste først', value: 0, stringVal: 'new' },
                { name: 'Vis eldste først', value: 1, stringVal: 'old' }]}
              onSelect={({ stringVal }) => {
                setSelectedFilter(stringVal as 'new' | 'old');
              }}
            />
          </div>
          )}
          {/* List of comments */}
          {
          filteredComments.map((comment, i) => (<CommentBox key={i} comment={comment} />))
        }
        </>
        )}

        {(!voting && !watching) && (
          <>

            {/* Submit comment */}
            <div className="font-clanot-black heading-underline my-5">Skriv kommentar</div>
            <div className="my-5">
              Viktig: ikke skriv personsensitive opplysninger i din kommentar.
              Den vil være synlig for alle møtets deltakere.
            </div>

            <textarea
              className="bg-white px-5 py-8 lg:px-8 lg:py-10
              rounded-bate border-2 border-medium-light-grey w-full h-48"
              placeholder="Skriv kommentar.."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />

            <div className="mt-5 pb-10 flex justify-between items-center">
              <div className="text-red-600">
                {commentError}
              </div>
              <BateButton onClick={postComment} className={newComment.length > 0 ? 'btn-primary' : ''}>
                Publiser kommentar
              </BateButton>
            </div>
          </>
        )}
      </div>

      {/* Votingbox */}
      {voting && (
        <Voter
          section={section}
          meetingId={meetingId}
          propertyId={propertyId}
          voted={hasVoted}
          onVoted={
            (closeAccordion) => {
              setHasVoted(true);
              if (closeAccordion) {
                accordionRef.current?.close();
              }
              onVoted();
            }
          }
        />
      )}
    </Accordion>
  );
};

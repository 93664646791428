import { put, post, deleter } from '../../../api';
import { IMeetingSectionModel, DigitalCastVoteEnum } from '../../../types/digitalMeetings';

export const executeVotingForSection = async (
  section: IMeetingSectionModel,
  meetingId: string,
  vote: DigitalCastVoteEnum | string,
  propertyId: string,
  votingId: string,
  voted: boolean,
): Promise<string | undefined> => {
  const payload = {
    vote,
    propertyId,
    sectionId: section.sectionId,
  };

  // If the section is not voted for
  if (voted) {
    await put(`/digitalmeetings/${meetingId}/${votingId}/voting`, payload);
    return undefined;
  }
  const res = await post(`/digitalmeetings/${meetingId}/voting`, payload);
  return res as string;
};

export const executeVotingForBoardMember = async (
  section: IMeetingSectionModel,
  meetingId: string,
  candidateId: string,
  propertyId: string,
): Promise<string> => {
  const payload = {
    candidateId,
    propertyId,
    sectionId: section.sectionId,
  };

  const result = await post(`/digitalmeetings/${meetingId}/board/voting`, payload);
  return result as string;
};

export const deleteVotingForBoardMemberOnSection = async (
  meetingId: string,
  candidateVotingId: string,
): Promise<void> => {
  await deleter(`/digitalmeetings/${meetingId}/${candidateVotingId}/board/voting`);
};

export const initialVotingState = (section: IMeetingSectionModel): (DigitalCastVoteEnum | string | null) => {
  // If a candidate is selected
  if (section.currentVoting === DigitalCastVoteEnum.Valgt) {
    // Return the selected candidate id
    return section.election.candidates.find((candidate) => candidate.votedFor)?.id || null;
  }
  return section.currentVoting;
};

import React, { useState, useEffect } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ISuggestedSection } from '../../../types/digitalMeetings';
import { formatDate } from '../../../utils/date';
import CaseAttachmentDownloadButton from './CaseAttachmentDownloadButton';

type Props = {
    section: ISuggestedSection;
    meetingId: string;
}

export const Case: React.FC<Props> = ({ section, meetingId }) => {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  const ref = React.createRef<HTMLDivElement>();

  // Use to transition height of accordion
  useEffect(() => {
    const scrollHeight = ref.current?.scrollHeight || 0;
    setMaxHeight(scrollHeight);
  }, [ref]);

  return (
    <div className="border-b">
      <button
        className="w-full flex justify-between py-4 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        type="button"
      >
        <div className={`ml-3 transition-all duration-500 ${open ? 'opacity-0' : 'opacity-100'}`}>
          { section.title}
        </div>
        <div className="flex justify-center item-center border-b border-black">
          <span>
            {open ? 'Lukk' : 'Les saken'}
          </span>
          <BsChevronUp
            size={20}
            className={`ml-5 transition-all duration-500 ${!open ? 'transform rotate-180' : ''}`}
          />
        </div>
      </button>

      {/* Case content */}
      <div
        ref={ref}
        className={`px-3 transition-all duration-500 overflow-hidden w-full ${open ? 'mt-5 mb-5' : 'not-sr'}`}
        style={{
          maxHeight: open ? `${maxHeight}px` : '0px',
        }}
      >
        <div className="text-sm my-5">
          Meldt
          {' '}
          {section.created && formatDate(section.created)}
        </div>
        <div className="text-xs mb-1">
          Tittel
        </div>
        <div className="text-md">
          {section.title}
        </div>

        <div className="text-xs mt-5 mb-1">
          Beskrivelse
        </div>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="text-md">
          {section.description}
        </ReactMarkdown>

        <div className="text-xs mt-5 mb-1">
          Forslag til vedtak
        </div>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="text-md">
          {section.suggestedDescision}
        </ReactMarkdown>

        <div className="text-xs mt-5 mb-1">
          Vedlegg
        </div>
        <ul>
          {section.attachments.map((file) => (
            <li key={file.id} className="flex flex-row gap-4 my-4 items-center">
              <CaseAttachmentDownloadButton attachment={file} meetingId={meetingId} />
              <span>
                {file.filename}
              </span>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

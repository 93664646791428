import React from 'react';
import BateLink from '../Elements/BateLink';
import { Image } from '../../common/types/sanity';
import { useAppContext } from '../../context/AppContext';
import { BateIcon } from '../../types/enums';

interface Props {
  name?: string;
  title?: string;
  text?: string;
  linkText?: string;
  image?: Image;
  imageSrc?: string;
  linkUrl?: string;
  useLink?: boolean;
  icon?: BateIcon;
}

const Greeting: React.FC<Props> = ({
  name, title, text, linkText, linkUrl = '', useLink, image, imageSrc = '', icon,
}) => {
  const { user } = useAppContext();

  return (
    <div className="flex flex-col justify-between order-last lg:order-first">
      <div className="flex flex-col flex-wrap justify-center md:justify-start md:flex-row text-center md:text-left items-center md:gap-y-4">
        { icon && (
        <div className="w-24 md:w-32 justify-center">
          <img src={icon} alt="icon" className="max-w-24 max-h-24 md:max-w-28 md:max-h-28 flex-none" />
        </div>
        )}

        { imageSrc && (
        <div className="w-24 md:w-32 justify-center">
          <div className="rounded-full w-24 h-24 md:w-28 md:h-28 overflow-hidden">
            <img
              className="min-w-auto"
              src={`https://api.bbld.io/personimage/user/${user.id}/image/?${Date.now()}`}
              alt="profilbilde"
            />
          </div>
        </div>
        )}
        { name && (
        <div
          className="text-3xl md:text-4.5xl font-clanot-ultra text-bate-red lg:py-12"
          style={{ wordBreak: 'break-word' }}
        >
          Hei,
          {` ${name}`}
          !
        </div>
        )}

        {title && (
        <div
          className="text-3xl md:text-4.5xl font-clanot-ultra text-bate-red lg:py-12"
          style={{ wordBreak: 'break-word' }}
        >
          {title}
        </div>
        )}
      </div>
      {text && (
      <div className="pb-5 lg:text-xl">
        {text}
      </div>
      )}
      {linkText && (
        <div className="md:text-right pt-6">
          <BateLink className="hidden md:inline-flex" href="">{linkText}</BateLink>
          <BateLink className="md:hidden" href="">{linkText}</BateLink>
        </div>
      )}
    </div>
  );
};

Greeting.defaultProps = {
  name: '',
  title: '',
  text: '',
  linkText: '',
};

export default Greeting;

import React, { useMemo } from 'react';
import { BiStopwatch } from 'react-icons/bi';
import { MONTHS_NORWEGAIN } from '../../../common/utils/constants';

type Props = {
  title: string | undefined;
  dueDate: Date | undefined;
}

export const Deadline: React.FC<Props> = ({
  title, dueDate,
}) => {
  const dueParams = useMemo(() => {
    if (dueDate) {
      const date = new Date(dueDate);

      const minute = date.getMinutes();
      const hour = date.getHours();

      return {
        day: date?.getDate(),
        month: date && MONTHS_NORWEGAIN[(date.getMonth())],
        // Use two digits for hours and minutes
        time: `${hour < 10 ? `0${hour}` : hour}.${minute < 10 ? `0${minute}` : minute}`,
        year: date?.getFullYear(),
      };
    }
    return {
      day: undefined,
      month: undefined,
      time: undefined,
      year: undefined,
    };
  }, [dueDate]);

  return (
    <div className="bg-white rounded-bate relative w-full shadow-lg px-7 py-8 lg:p-10 my-6">
      <div className="font-clanot-black">
        {title}
      </div>
      <div className="flex flex-col sm:flex-row sm:items-end">
        <div className="my-2 sm:my-0 sm:w-6/12 sm:border-r-2 sm:border-medium-light-grey">
          <div className="text-2xl md:text-3xl font-clanot-ultra text-bate-red">
            {dueParams.day}
            .
            {' '}
            {dueParams.month}
            {' '}
            {dueParams.year}
          </div>
        </div>

        <div className="sm:pl-5 md:pl-10 sm:pb-1 text-dark text-xl">
          Klokken
          {' '}
          {dueParams.time}
        </div>
      </div>
    </div>
  );
};

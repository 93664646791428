/* eslint-disable react/jsx-props-no-spreading */
import { useIsAuthenticated } from '@azure/msal-react';
import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteSection } from '../../common/utils/enums';
import { useAppContext } from '../../context/AppContext';
import {
  useHasMemberOrResidenceOwnerRole,
  useHasMemberRole, useHasOnlyMemberRole, useHasOnlyResidenceOwnerRole, useHasResidenceOwnerRole,
} from '../../utils/hooks';
import Loader from '../Modules/Loader';

export interface AppRouteProps {
  path: string;
  component: React.ElementType;
  isPrivate: boolean;
  section: RouteSection;
}

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component, path, isPrivate, section, ...rest
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { roles } = useAppContext();

  const isMember = useHasMemberRole(roles);

  const isOnlyMember = useHasOnlyMemberRole(roles);
  const isResidenceOwner = useHasResidenceOwnerRole(roles);
  const isOnlyResidenceOwner = useHasOnlyResidenceOwnerRole(roles);
  const isMemberOrResidence = useHasMemberOrResidenceOwnerRole(roles);

  let redirectToPath = isPrivate && !isAuthenticated ? '/login' : null;
  if (!redirectToPath && roles) {
    // Need an extra check for if the current user can access the URL.
    // Only need to check the restricted sections, default is to do nothing more.
    // Should only check if roles have been loaded, since we otherwise might restrict too early.
    // That is of course not a secure way to do it, but since this is client code that is not the goal.
    // The goal is to prevent showing a page with modules that won't load based on the user's roles.

    let redirectToLogin = false;

    // eslint-disable-next-line default-case
    switch (section) {
      case RouteSection.Member:
        redirectToLogin = !isMember;
        break;
      case RouteSection.Residence:
        redirectToLogin = !isResidenceOwner;
        break;
      case RouteSection.All:
        redirectToLogin = !isMemberOrResidence;
        break;
    }

    if (redirectToLogin) {
      redirectToPath = '/login';
    } else if (path === '/oversikt') {
      if (isOnlyMember) {
        redirectToPath = '/medlemsfordeler';
      } else if (isOnlyResidenceOwner) {
        redirectToPath = '/bolig';
      }
    }
  }

  if (redirectToPath) {
    // Should not attempt to redirect to previous URL anymore
    localStorage.removeItem('postLoginRedirectUri');
  }

  return (
    <Route
      path={path}
      render={(props) => (redirectToPath ? (
        <Redirect
          to={{ pathname: redirectToPath, state: { from: window.location.pathname } }}
        />
      ) : (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      ))}
      {...rest}
    />
  );
};

export default AppRoute;

import { InternalLinkType, StartPageType } from './enums';

const getUrl: (link: any) => string = (link) => {
  // eslint-disable-next-line default-case
  switch (link.type) {
    case 'landingPage': {
      // eslint-disable-next-line default-case
      switch (link.pageType) {
        case StartPageType.ForYou:
          return `/for-deg/${link.slug}`;
        case StartPageType.ForBoard:
          return `/for-styret/${link.slug}`;
        case StartPageType.ForDeveloper:
          return `/for-utbygger/${link.slug}`;
      }
      return `/${link.slug}`;
    }
    case 'benefit-settings':
      return '/fordeler';
    case 'benefit-benefit':
      return `/medlemskap/fordeler/${link.slug}`;
    case 'residenceLandingPage':
      return '/bolig';
    case 'residenceCategory':
      return `/bolig/${link.slug}`;
    case 'residence':
      return `/bolig/${link.category}/${link.slug}`;
    case 'residentialProject':
      return `/bolig/boligprosjekt/${link.slug}`;
    case 'blogLandingPage':
      return '/batebloggen';
    case 'post':
      return `/batebloggen/${link.slug}`;
    case 'event':
      return `/kurs/${link.slug}`;
    case 'eventGuidance':
      return `/kurs/veiledning/${link.slug}`;
    case 'eventSettings':
      return '/kurs';
    case 'service':
      return `/tjenester/${link.targetGroup}/${link.slug}`;
    case 'serviceTargetGroup':
      return `/tjenester/${link.slug}`;
    case 'customerService':
      return '/kundeservice';
    case 'overviewPage':
      return '/oversikt';
    case 'bonusPage':
      return '/bonus';
    case 'benefitsPage':
      return '/medlemsfordeler';
    case 'housingOverviewPage':
      return '/bolig';
    case 'housingAssociationPage':
      return '/bolig/borettslag';
    case 'jointCostsPage':
      return '/bolig/felleskostnader';
    case 'rentalPage':
      return '/bolig/utleie';
    case 'contactBoardPage':
      return '/bolig/kontakt-styret';
    case 'article':
      return `/artikkel/${link.slug}`;
  }

  if (link.slug === '/') {
    return link.slug;
  }

  // Even if the start page has the slug for-deg, we use route / for this specific start page
  if (link.slug === 'for-deg') {
    return '/';
  }

  return `/${link.slug}`;
};

export const getInternalLinkType: (link: any) => InternalLinkType = (link) => {
  switch (link.type) {
    case 'overviewPage':
    case 'bonusPage':
    case 'benefitsPage':
    case 'housingOverviewPage':
    case 'housingAssociationPage':
    case 'jointCostsPage':
    case 'rentalPage':
    case 'contactBoardPage':
    case 'benefit-benefit':
    case 'article': {
      return InternalLinkType.Minside;
    }
    default:
      return InternalLinkType.Web;
  }
};

export const resolveDocumentUrl: (link: any, postfix?: string, internalLinkType?: InternalLinkType) => string = (link, postfix?, internalLinkType?) => {
  let url = getUrl(link);
  if (postfix) {
    url += postfix;
  }

  // Use relative path for min side links
  if (internalLinkType === InternalLinkType.Minside) {
    return url;
  }

  // Use absolute path for web links
  return `${process.env.REACT_APP_BATE_NO_URL}${url}`;
};

import { BlogPost } from '../types/blog';

const getBlogPostModel: (node: any) => BlogPost = (node) => {
  const blogPost: BlogPost = {
    title: node.title,
    slug: node.slug,
    image: node.image,
  };
  return blogPost;
};

export default getBlogPostModel;

import React from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { formatDate } from '../../../utils/date';
import { IMeetingCommentModel } from '../../../types/digitalMeetings';
import { BateIcon } from '../../../types/enums';

type Props = {
  comment: IMeetingCommentModel
}

export const CommentBox: React.FC<Props> = ({ comment }) => {
  return (
    <>
      <div className="bg-white rounded-bate mt-5 relative w-full
      px-5 py-8 lg:px-8 lg:py-10 border-2 border-medium-light-grey"
      >
        {/* Content */}
        <div className="flex justify-between pb-5 mb-5 border-b-2 border-medium-light-grey">
          <div>
            {comment.comment}
          </div>
          <div>
            <FiMessageSquare
              className="text-bate-red"
              size={25}
              style={{
                transform: 'rotateY(180deg)',
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          {/* Timestamp */}
          <div className="flex items-center text-sm mr-5">
            <img src={BateIcon.Time} alt="Publiseringsdato" className="w-12 sm:w-16 mr-1" />
            <div>
              {formatDate(comment.when!, true)}
            </div>
          </div>
          {/* Written by */}
          <div className="flex items-center text-sm">
            <img src={BateIcon.Forfatter} alt="Skrevet av" className="w-12 sm:w-16 mr-1" />
            <div>
              Av:
              {' '}
              {comment.commentedBy}
              {' '}
              {comment.commentedByFunction ? `( ${comment.commentedByFunction} )` : ''}
            </div>
          </div>
        </div>
      </div>
      {/* Spacer */}
      <div className="p-2" />
    </>
  );
};

import React, { useCallback, useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { ImDownload3 } from 'react-icons/im';
import { downloadFile } from '../../../api';
import { IAttachmentMetadataModel } from '../../../types/digitalMeetings';

interface AttachmentJson {
  fileContents: string;
  contentType: string;
  fileDownloadName: string;
  enableRangeProcessing: boolean;
}

interface CaseAttachmentDownloadButtonProps {
  attachment: IAttachmentMetadataModel;
  meetingId: string;
}

const CaseAttachmentDownloadButton: React.FC<CaseAttachmentDownloadButtonProps> = ({ attachment, meetingId }) => {
  const [downloading, setDownloading] = useState(false);

  const download = useCallback(async () => {
    if (downloading) return;

    setDownloading(true);
    const path = `/digitalmeetings/${meetingId}/attachment/${attachment.id}`;

    // Returns a blob of the type `application/json` with the structure of `AttachmentJson`
    const res = await downloadFile(path);

    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const jsonString = event.target?.result;
      if (!jsonString) return;

      const json: AttachmentJson = JSON.parse(jsonString.toString());

      const { fileContents, contentType, fileDownloadName } = json;

      const linkSource = `data:${contentType};base64,${fileContents}`;
      const downloadLink = document.createElement('a');
      const fileName = fileDownloadName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      setDownloading(false);
    };

    // Extracts the content of the blob as a json string
    fileReader.readAsText(res);
  }, [attachment.id, downloading, meetingId]);

  return (
    <button
      onClick={() => download()}
      type="button"
      className="flex items-center border-b-4 border-bate-red"
    >
      <div className="text-xl pr-2 pb-1 pt-1 rounded-full ">
        {downloading
          ? <CgSpinner className="animate-spin" />
          : <ImDownload3 />}
      </div>
      <div className="pt-1">
        {downloading
          ? 'Laster ned'
          : 'Last ned'}
      </div>
    </button>
  );
};

export default CaseAttachmentDownloadButton;

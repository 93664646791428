import React, {
  useCallback, useMemo, useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { BiCheck } from 'react-icons/bi';
import Modal from 'react-modal';
import { get } from '../../../api';
import { useAppContext } from '../../../context/AppContext';
import { IBoard } from '../../../types';
import {
  DigitalCastVoteEnum,
  DigitalVotingTypeEnum,
  IMeetingDetailModel, IMeetingQueryModel, IMeetingSectionModel, MeetingStateEnum,
} from '../../../types/digitalMeetings';
import { formatDate } from '../../../utils/date';
import { useFetchDataWithDependenciesAndLoading } from '../../../utils/hooks';
import { HasVotedContextProvider } from '../context/HasVotedContext';
import { CaseAccordion } from './CaseAccordion';
import { Deadline } from './Deadline';
import { MeetingAttachment } from './MeetingAttachment';
import { PhaseHeader } from './PhaseHeader';
import { MODAL_STYLE_NARROW } from '../../../common/utils/constants';
import BateButton from '../../../components/Elements/BateButton';

export interface SectionNode extends IMeetingSectionModel{
    children: IMeetingSectionModel[];
}

type Props = {
  meeting: IMeetingQueryModel
}

const haveAllBeenVotedOn = (sectionsTree: SectionNode[]): boolean => {
  if (!sectionsTree?.length) {
    return false;
  }

  let allVoted = true;

  sectionsTree.forEach((section) => {
    if (section.children?.length) {
      // There are subsections that must be checked
      section.children.forEach((child) => {
        if (child.votingOptions === DigitalVotingTypeEnum.Valg) {
          // This is an election, check that at least one candidate have had a vote cast
          if (child.election.candidates.every((candidate) => !candidate.votedFor)) {
            allVoted = false;
          }
        } else if (child.currentVoting === DigitalCastVoteEnum.IkkeAngitt) {
          // The subsection has not been voted on
          allVoted = false;
        }
      });
    } else if (section.votingOptions === DigitalVotingTypeEnum.Valg) {
      // This is an election, check that at least one candidate have had a vote cast
      if (section.election.candidates.every((candidate) => !candidate.votedFor)) {
        allVoted = false;
      }
    } else if (section.currentVoting === DigitalCastVoteEnum.IkkeAngitt) {
      // The section has not been voted on
      allVoted = false;
    }
  });

  return allVoted;
};

export const CasesList: React.FC<Props> = ({ meeting }) => {
  // Get the selected meeting
  const { user } = useAppContext();

  const { propertyId } = useParams<{ propertyId: string }>();

  const [allVoted, setAllVoted] = useState(false);
  const [allVotedModalIsOpen, setAllVotedModalIsOpen] = useState(false);

  // Get the meeting details
  const fetchMeethingDetails = useCallback(() => {
    const details = get<IMeetingDetailModel>(
      `/digitalmeetings/${meeting?.id}/property/${propertyId || meeting.propertyIds[0]}`,
    );
    return details;
  }, [meeting?.id, meeting.propertyIds, propertyId]);

  const { data: details } = useFetchDataWithDependenciesAndLoading(
    { fetch: fetchMeethingDetails, dependencies: [meeting] },
  );

  // Get the meeting details
  const fetchBoardMembers = useCallback(async () => {
    const board = await get<IBoard[]>(`/clients/${meeting?.clientId}/boardswithmembers`);
    return board;
  }, [meeting]);

  const { data: board } = useFetchDataWithDependenciesAndLoading(
    { fetch: fetchBoardMembers, dependencies: [meeting] },
  );

  const sectionsTree = useMemo(() => {
    // Make sections nested in a tree as subsections
    if (details) {
      // Loop through all sections
      const tree: SectionNode[] = [];
      for (let i = 0; i < details.sections.length; i += 1) {
        // Find the section in the tree
        const section = details.sections[i];

        // If the parent index is 0, we have a parent-section, else we have a leaf.
        if (section.parentIndex === 0) {
          tree.push({ ...section, children: [] });
        } else {
          // Find index of parent section in the tree list
          const parentIndex = tree.findIndex((s) => s.index === section.parentIndex);
          if (parentIndex !== -1) {
            // Add the section to the tree
            tree[parentIndex].children.push(section);
          }
        }
      }
      return tree;
    }
    return [];
  }, [details]);

  const dueProps = useMemo(() => {
    // Switch case over status
    switch (meeting?.meetingState) {
      case MeetingStateEnum.Preview:
        return {
          title: 'Du kan kommentere sakene:',
          dueDate: meeting.commentsStart ? meeting?.commentsStart : undefined,
        };
      case MeetingStateEnum.Voting:
        return {
          title: 'Stemmefrist',
          dueDate: meeting.deadline ? meeting?.deadline : undefined,
        };
      case MeetingStateEnum.Comments:
        return {
          title: 'Siste frist for å legge inn kommentarer:',
          dueDate: meeting.commentsDeadline ? meeting?.commentsDeadline : undefined,
        };
      case MeetingStateEnum.None:
        return {
          title: 'Avstemming starter',
          dueDate: meeting.start ? meeting?.start : undefined,
        };
      default:
        return {
          title: undefined,
          dueDate: undefined,
        };
    }
  }, [meeting]);

  const phaseName = useMemo(() => {
    switch (meeting?.meetingState) {
      case MeetingStateEnum.Preview:
        return '';
      case MeetingStateEnum.Voting:
        return '';
      case MeetingStateEnum.Comments:
        return '';
      case MeetingStateEnum.None:
        return 'Pause';
      default:
        return '';
    }
  }, [meeting]);

  const phaseText = useMemo(() => {
    switch (meeting?.meetingState) {
      case MeetingStateEnum.Voting:
        return [
          `Vi ønsker deg velkommen til ${meeting.title.toLowerCase()}  i ${meeting.clientName}.`,
          `Årets generalforsamling gjennomføres elektronisk, og du kan avgi dine stemmer i perioden 
          ${formatDate(meeting.start, true)} til ${formatDate(meeting.deadline, true)}.`,
          `Som andelseier har du én stemme i generalforsamlingen, også om du eier flere andeler. For 
          en andel med flere eiere, kan det bare bli avgitt én stemme. Når du avlegger din stemme, er 
          den også gjeldende for eventuelle medeiere. Det er mulig å endre på avgitte stemmer frem til 
          stemmefristens utløp ${formatDate(meeting.deadline, true)}.`,
          'Din totale stemme i møtet utgjør: 1',
        ];
      case MeetingStateEnum.Preview:
        return [
          `Nå kan du se sakene som er innmeldt. Du kan legge inn kommentarer når møtet starter 
          ${formatDate(meeting.commentsStart, true)}. 
          Inntil videre kan du gjøre deg kjent med sakene som skal behandles i møtet.`,
        ];
      case MeetingStateEnum.Comments:
        return [
          `Vi ønsker deg velkommen til ${meeting.title.toLowerCase()} i ${meeting.clientName}.
          Årets generalforsamling gjennomføres elektronisk, og du kan nå gjøre deg 
          kjent med sakene som skal behandles.`,
          `Hver sak har et kommentarfelt du kan bruke til å kommentere sakens innhold eller 
          til å stille spørsmål i perioden ${formatDate(meeting.commentsStart, true)} 
          til ${formatDate(meeting.commentsDeadline, true)}.`,
          'Du vil motta nytt varsel når generalforsamlingen åpner for at du kan avgi dine stemmer.',

        ];
      default:
        return null;
    }
  }, [meeting]);

  const phaseHeaderBadge = useMemo(() => {
    if (details) {
      return details?.proxyId ? 'Fullmaktshaver'
        : `Eier ${meeting.propertyIds.length} 
        ${meeting.propertyIds.length > 1 ? 'boliger' : 'bolig'}`;
    }
    return '';
  }, [details, meeting.propertyIds.length]);

  const voting = useMemo(() => meeting?.meetingState === MeetingStateEnum.Voting, [meeting]);
  const watching = useMemo(() => meeting?.meetingState === MeetingStateEnum.Preview, [meeting]);

  const userBoardFunction = useMemo(() => {
    if (board) {
      const userBoard = board[0].members?.find((member) => member.person.id === user.id);
      if (userBoard) {
        return userBoard.memberFunction;
      }
    }
    return '';
  }, [board, user]);

  useEffect(() => {
    setAllVoted(haveAllBeenVotedOn(sectionsTree));
  }, [sectionsTree]);

  const onVoted = (): void => {
    const allVotedOn = haveAllBeenVotedOn(sectionsTree);

    setAllVotedModalIsOpen(allVotedOn);
    setAllVoted(allVotedOn);
  };

  if (!meeting && !propertyId) {
    return null;
  }

  return (
    <>
      <div className="bg-light-blue w-full mt-12 md:mt-20">
        <div className="bate-container">
          <PhaseHeader
            title={meeting?.title || '. . .'}
            pretitle={phaseName}
            subtitle={`for ${meeting?.clientName}`}
            proxy={details?.proxyOwnerName}
            subtexts={phaseText}
            topLeftBadge={phaseHeaderBadge}
            lastSubtextBold={meeting?.meetingState === MeetingStateEnum.Voting}
          />

          <Deadline title={dueProps.title} dueDate={dueProps.dueDate} />
        </div>
      </div>
      <div className="bg-light-blue pb-8 md:pb-28 md:-mb-24 w-full body flex-grow">
        <div className="bate-container">
          {allVoted ? (
            <div className="flex justify-center">
              <div className="flex flex-col items-center pt-4 pb-3 px-8 rounded-md grow-0 text-xl">
                <BiCheck className="text-bate-red" size={90} />
                Alle stemmer avgitt
              </div>
            </div>
          ) : null}
          {meeting?.meetingState === MeetingStateEnum.None && (
            <div className="bg-white rounded-bate relative w-full shadow-lg px-7 py-8 lg:p-10 mb-6">
              <div>
                {meeting.title}
                {' '}
                er nå mellom høringsrunden og avstemming. Du vil motta nytt varsel når avstemmingsperioden begynner.
              </div>
              <div className="mt-5">
                <MeetingAttachment
                  filename="Møteinnkalling.pdf"
                  name="Møteinnkalling"
                  path={`/digitalmeetings/${meeting.id}/attachment/notice`}
                />
              </div>
            </div>
          )}

          {/* List over cases */}
          <HasVotedContextProvider sections={sectionsTree}>
            <>
              { sectionsTree.length > 0 && (
                <p className="font-clanot-black text-bate-red mt-10 mb-6">Saksliste</p>
              ) }
              {
            sectionsTree.map((section, index) => (
              <div
                key={section.id}
              >
                <CaseAccordion
                  voting={voting}
                  watching={watching}
                  section={section}
                  index={index}
                  meetingId={meeting.id}
                  userBoardFunction={userBoardFunction}
                  propertyId={propertyId}
                  onVoted={onVoted}
                />
                {/* List over subsections */}
                {
                  section.children.map((subsection, subIndex) => (
                    <CaseAccordion
                      key={subsection.id}
                      voting={voting}
                      watching={watching}
                      section={subsection}
                      index={index}
                      subIndex={subIndex + 1}
                      meetingId={meeting.id}
                      userBoardFunction={userBoardFunction}
                      propertyId={propertyId}
                      onVoted={onVoted}
                    />
                  ))
                }
              </div>
            ))
          }
              <Modal
                isOpen={allVotedModalIsOpen}
                style={MODAL_STYLE_NARROW}
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 overflow-y-auto p-5"
                className="bg-white !rounded-bate !px-6 !py-10"
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                preventScroll
                onRequestClose={() => setAllVotedModalIsOpen(false)}
              >

                <div className="flex flex-col items-center w-full">
                  <BiCheck className="text-bate-red" style={{ fontSize: '100px' }} />
                  <div className="md:text-xl mt-4">
                    Alle stemmer avgitt
                  </div>
                  <div className="mt-10 flex flex-wrap justify-center">
                    <BateButton href="/bolig/arsmote" useLink className="btn-secondary my-2 mr-4">
                      Avslutt
                    </BateButton>
                    <BateButton onClick={() => setAllVotedModalIsOpen(false)} className="btn-primary my-2">
                      Se sakslisten
                    </BateButton>
                  </div>
                </div>
              </Modal>
            </>
          </HasVotedContextProvider>
        </div>
      </div>
    </>
  );
};

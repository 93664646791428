import React, { useEffect, useState } from 'react';
import { getBenefitInfo, getMembership } from '../../api';
import { useAppContext } from '../../context/AppContext';
import { IMemberBenefitsInfo, IMembership } from '../../types';
import { formatDate } from '../../utils/date';
import RoundedBox from '../Containers/RoundedBox';

interface Props{
  isCardStyle: boolean;
}

const MembershipInfo: React.FC<Props> = ({ isCardStyle }) => {
  const [membership, setMembership] = useState<IMembership | null>();
  const { benefitInfo, user } = useAppContext();

  useEffect(() => {
    const fillData = async () : Promise<void> => {
      setMembership(await getMembership());
    };

    fillData();
  }, []);

  if (!membership) {
    return null;
  }

  return (
    <RoundedBox
      bgColor="bg-bate-red"
      className={`text-white !px-7 !py-6 rounded-3xl ${isCardStyle ? 'w-full max-w-xs' : 'px-6 pb-5 pt-10'}`}
    >

      <div className={`h-full flex flex-col justify-between ${!isCardStyle ? 'pt-3' : ''}`}>
        <div className="flex justify-between items-center mb-2">
          <p className="font-clanot-black pt-1">Medlemsbevis</p>
          <img width="54" height="17" alt="Bate logo" src="/Bate_Logo_Hvit_ORIG.png" />
        </div>
        <div className={`h-full flex flex-col justify-evenly gap-1.5 ${isCardStyle ? '' : ''}`}>
          <div className="flex text-sm font-clanot-news flex-col gap-1 w-full">
            {user ? (
              <div>
                {user.firstname}
                {' '}
                {user.surname}
              </div>
            ) : null}
            <div className="flex justify-between">
              <span className="pr-2">Medlemsnr.</span>
              <span>
                {membership?.membershipNo}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="pr-2">Medlem fra</span>
              <span>
                {formatDate(membership.seniorityDate)}
              </span>
            </div>
            {user?.memberBenefitCardNo ? (
              <div className="flex justify-between">
                <span className="pr-2">Medlemskortnr.</span>
                <span>{user.memberBenefitCardNo}</span>
              </div>
            ) : null}

          </div>
        </div>

      </div>
    </RoundedBox>
  );
};

export default MembershipInfo;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { LinkButtonModel } from '../../types/sanity';
import { InternalLinkType, LinkButtonType } from '../../utils/enums';

interface Props {
  button: LinkButtonModel;
  className?: string;
}

const LinkButton: React.FC<Props> = ({ button, children, className = '' }) => {
  if (!button.url) return null;

  const btnClass = `btn mt-2 rounded-bate btn-animate-arrow-right ${className} ${
    button.type === LinkButtonType.Primary ? 'btn-primary' : 'btn-secondary'
  }`;
  const target = button.blank ? '_blank' : null;

  return button.isInternal && button.internalLinkType === InternalLinkType.Minside ? (
    <Link to={button.url} className={btnClass}>
      <div className="flex gap-x-2 text-sm md:text-base font-clanot-black items-center">
        {button.text}
        {children}
        <ImArrowRight2 className="text-xl bounce flex-none" />
      </div>
    </Link>
  ) : (
    <a href={button.url} target={target || undefined} className={btnClass}>
      <div className="flex gap-x-2 text-sm md:text-base font-clanot-black items-center">
        {button.text}
        {children}
        <ImArrowRight2 className="text-xl bounce flex-none" />
      </div>
    </a>
  );
};

LinkButton.defaultProps = {
  className: '',
};

export default LinkButton;

import { InternalLink } from '../types/sanity';
import { resolveDocumentUrl, getInternalLinkType } from '../utils/resolvers';

const getInternalLinkModel: (mark: any, children?: any, postfix?: string) => InternalLink = (mark, children, postfix) => {
  const internalLinkType = getInternalLinkType(mark);

  const link: InternalLink = {
    text: children ? children.join('') : '',
    link: {
      href: resolveDocumentUrl(mark, postfix ?? mark.postfix, internalLinkType),
    },
    postfix,
    type: internalLinkType,
  };
  return link;
};

export default getInternalLinkModel;

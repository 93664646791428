/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from 'react';
import ReactCrop, {
  centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

interface ImageCropProps {
  src: string;
  onImageLoad?(e: React.SyntheticEvent<HTMLImageElement>): void;
  onCropUpdated?(crop: PixelCrop, percentageCrop: PercentCrop): void;
  currentCrop?: PixelCrop | PercentCrop;
}

const ImageCrop: React.FC<ImageCropProps> = ({
  src, onImageLoad, onCropUpdated, currentCrop,
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const handleOnLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    if (currentCrop != null) return;
    const { width, height } = e.currentTarget;

    const percentCrop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 100,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    ) as PercentCrop;

    const pixelCrop = {
      unit: 'px',
      x: Math.round(width * (percentCrop.x / 100)),
      y: Math.round(height * (percentCrop.y / 100)),
      width: Math.round(width * (percentCrop.width / 100)),
      height: Math.round(height * (percentCrop.height / 100)),
    } as PixelCrop;

    setCrop(percentCrop);
    if (onCropUpdated) onCropUpdated(pixelCrop, percentCrop);

    if (onImageLoad) onImageLoad(e);
  }, [currentCrop]);

  return (
    <ReactCrop
      crop={crop}
      onChange={(c) => setCrop(c)}
      onComplete={onCropUpdated}
      aspect={1}
      circularCrop
    >
      <img src={src} onLoad={handleOnLoad} alt="Profilbilde" crossOrigin="anonymous" />
    </ReactCrop>
  );
};

export default ImageCrop;

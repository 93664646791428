import React, { useCallback, useMemo, useState } from 'react';
import BateButton from '../../../components/Elements/BateButton';
import { DigitalCastVoteEnum, DigitalVotingTypeEnum, IMeetingSectionModel } from '../../../types/digitalMeetings';
import { useHasVoted } from '../context/HasVotedContext';
import { executeVotingForSection, initialVotingState } from './votingHelpers';

type Props = {
    section: IMeetingSectionModel
    voted: boolean
    onVoted: (closeAccordion? :boolean) => void
    meetingId: string,
    propertyId: string
}

export const NormalVoting: React.FC<Props> = ({
  section, voted, onVoted, meetingId, propertyId,
}) => {
  const [selected, setSelected] = useState<DigitalCastVoteEnum | string | null>(initialVotingState(section));
  const [initialVoting, setInitialVoting] = useState<DigitalCastVoteEnum | string | null>(initialVotingState(section));

  const [loading, setLoading] = useState(false);
  const [votingId, setVotingId] = useState<string>(section.votingId);
  const [error, setError] = useState<boolean>(false);

  const { setVoted } = useHasVoted({ sectionId: section.id });

  const votingOption = useMemo(() => {
    if (section.votingOptions === DigitalVotingTypeEnum.IngenStemming) {
      return null;
    } if (section.votingOptions === DigitalVotingTypeEnum.Akklamasjon) {
      return {
        type: section.votingOptions,
        radioButtons: [
          {
            label: 'Jeg stemmer for',
            value: DigitalCastVoteEnum.For,
          },
          {
            label: 'Jeg stemmer blankt',
            value: DigitalCastVoteEnum.Blankt,
          },
        ],
      };
    } if (section.votingOptions === DigitalVotingTypeEnum.Beslutningssak) {
      return {
        type: section.votingOptions,
        radioButtons: [
          {
            label: 'Jeg stemmer for',
            value: DigitalCastVoteEnum.For,
          },
          {
            label: 'Jeg stemmer mot',
            value: DigitalCastVoteEnum.Mot,
          },
          {
            label: 'Jeg stemmer blankt',
            value: DigitalCastVoteEnum.Blankt,
          },
        ],
      };
    } if (section.votingOptions === DigitalVotingTypeEnum.TilOrientering) {
      return {
        type: section.votingOptions,
        radioButtons: [
          {
            label: 'Tatt til orientering',
            value: DigitalCastVoteEnum.TattTilOrientering,
          },
        ],
      };
    }
    return null;
  }, [section.votingOptions]);

  const voteOnSection = useCallback(async () => {
    if (selected && selected !== initialVoting) {
      try {
        setLoading(true);
        const newVotingId = await executeVotingForSection(section, meetingId, selected, propertyId, votingId, voted);
        if (newVotingId) {
          setVotingId(newVotingId);
        }
        onVoted(true);
        setVoted(section.id);
        setInitialVoting(selected);
        setError(false);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [meetingId, onVoted, propertyId, section, selected, setVoted, voted, votingId]);

  return (
    <div className="bg-white py-5 mb-3 relative">
      <div className="font-clanot-black text-bate-red heading-underline my-5">
        {voted ? 'Stemme avgitt' : 'Avgi stemme'}
      </div>
      <div className="mt-10">
        {
              votingOption?.radioButtons.map((radioButton) => (
                <button
                  key={`${radioButton.label}-${selected}`}
                  className="flex mt-5 items-center"
                  type="button"
                  onClick={() => { setSelected(radioButton.value); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g
                      id="Ellipse_1174"
                      data-name="Ellipse 1174"
                      fill="#fff"
                      stroke="#DCE3EA"
                      strokeWidth="2"
                    >
                      <circle cx="15" cy="15" r="15" stroke="none" />
                      <circle cx="15" cy="15" r="14" fill="none" />
                    </g>
                    {selected === radioButton.value && (
                    <circle
                      id="Ellipse_1175"
                      data-name="Ellipse 1175"
                      cx="10"
                      cy="10"
                      r="10"
                      transform="translate(5 5)"
                      fill="#E21219"
                    />
                    )}
                  </svg>

                  <div
                    className="pt-1 ml-5"
                    style={{ color: '#333' }}
                  >
                    {radioButton.label}

                  </div>
                </button>
              ))
            }
        <div className="flex items-center text-red-500 font-clanot-news mt-10">
          <div>
            {error && 'En feil oppstod ved innsendelse av stemmen, prøv igjen.'}
          </div>
          <div>
            <BateButton
              className={`my-2 ${loading || (selected === initialVoting) ? '' : 'btn-primary'}`}
              onClick={voteOnSection}
              disabled={loading || (selected === initialVoting)}
            >
              {voted ? 'Endre stemme' : 'Avgi stemme'}
            </BateButton>
          </div>
        </div>
      </div>
    </div>
  );
};

import { Image } from '../types/sanity';

const getImageModel: (node: any) => Image = (node) => {
  const image: Image = {
    caption: node.caption,
    asset: node.asset,
    alt: node.alt,
    credit: node.credit,
    crop: node.crop,
    hotspot: node.hotspot,
  };
  return image;
};

export default getImageModel;

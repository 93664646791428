import { RouteSection } from '../common/utils/enums';
import routes from '../config/routes';
import { IRole } from '../types';
import { SearchResultModel } from '../types/sanity/types';
import { hasMemberOrResidenceOwnerRole, hasMemberRole, hasResidenceOwnerRole } from './roles';

export const filterSearchResultsByRoles = (searchResults: SearchResultModel[], userRoles: IRole[]): SearchResultModel[] => {
  const isMember = hasMemberRole(userRoles);
  const isResidenceOwner = hasResidenceOwnerRole(userRoles);
  const isMemberOrResidence = hasMemberOrResidenceOwnerRole(userRoles);

  const filteredResults = searchResults.filter((result) => {
    if (result === undefined) {
      return false;
    }

    // Members should see benefits
    if (result._type === 'benefit-benefit') {
      return isMember;
    }

    // Articles should be visible for everyone
    if (result._type === 'article') {
      return true;
    }

    // :: Verify that route is available for current user based on user roles
    // Find route by url
    const route = routes.find((r) => r.path === result.url);

    if (!route) {
      // No route, don't include.
      return false;
    }

    // Include page if current user can access route
    switch (route.section) {
      case RouteSection.Unrestricted:
        return true;
      case RouteSection.Member:
        // Route is available for members
        return isMember;
      case RouteSection.Residence:
        // Route is available for residence owners
        return isResidenceOwner;
      case RouteSection.All:
        // Route is available for both members and residence owners
        return isMemberOrResidence;
      default:
        // Don't include pages that does not have access set explicitly.
        // It should not happen since it is required.
        return false;
    }
  });

  return filteredResults;
};

export const getSearchResultUrl = (result: SearchResultModel): string => {
  if (result._type === 'benefit-benefit') {
    return `/medlemsfordeler/${result.slug}`;
  }
  if (result._type === 'article') {
    return `/artikkel/${result.slug}`;
  }

  return result.url;
};

import React, {
  SetStateAction, useEffect, useRef, useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoCloseOutline, IoSearchOutline } from 'react-icons/io5';
import { BiMenu } from 'react-icons/bi';
import { BsChevronDown, BsChevronRight, BsChevronUp } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useMediaQuery } from 'beautiful-react-hooks';
import { MdMailOutline } from 'react-icons/md';
import { Section } from '../types/section';
import styles from '../styles/Header.module.scss';
import { getClient } from '../utils/sanity.jsx';
import { previewSearchResultsQuery } from '../utils/queries';
import { filterSearchResultsByRoles } from '../utils/search';
import { SearchResultModel } from '../types/sanity/types';
import QuickSearchResults from './Modules/Search/QuickSearchResults';
import { useAppContext } from '../context/AppContext';
import {
  useHasBoardPortalRole,
  useHasMemberAndResidenceOwnerRole,
  useHasMemberOrResidenceOwnerRole,
  useHasMemberRole,
  useHasResidenceOwnerRole,
  useHasOnlyResidenceOwnerRole,
  useHasOnlyMemberRole,
  useIsBrl,
} from '../utils/hooks';
import { sortSearchResults } from '../common/utils/methods';
import MembershipInfoToggle from './Modules/MembershipInfoToggle';

interface Props{
  navigationMenuOpen: boolean;
  setNavigationMenuOpen: React.Dispatch<SetStateAction<boolean>>;
  setPageBackground: React.Dispatch<SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ navigationMenuOpen, setNavigationMenuOpen, setPageBackground }) => {
  const history = useHistory();
  const {
    user, benefitInfo, notificationCount, updateNotificationCount, currentResidence,
  } = useAppContext();

  const location = useLocation();

  const getLinkClass = (pathname: string, mobile: boolean): string => {
    if (mobile) {
      return `${location.pathname.startsWith(pathname) ? 'text-bate-red' : ''}`;
    }
    return `mr-2 lg:mr-4 flex flex-col justify-center hover:bg-light-blue
      ${location.pathname.startsWith(pathname) ? ' bg-light-blue' : ''}`;
  };

  const getSubLinkClass = (pathname: string): string => {
    return `mr-10 pt-4 pb-3 flex flex-col justify-center
    ${location.pathname === pathname
      || (pathname.includes('/medlemskap/fordeler')
      && location.pathname.includes('/medlemskap/fordeler'))
    ? styles.subMenuActiveLink
    : ''}
    ${styles.subMenuLink}`;
  };

  const [shouldDisplaySearch, setShouldDisplaySearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchList, setSearchList] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResultModel[]>([]);

  let { roles } = useAppContext();
  roles = roles || [];

  const isMemberAndResidenceOwner = useHasMemberAndResidenceOwnerRole(roles);
  const isMemberOrResidenceOwner = useHasMemberOrResidenceOwnerRole(roles);
  const isMember = useHasMemberRole(roles);
  const isResidenceOwner = useHasResidenceOwnerRole(roles);
  const isOnlyResidenceOwner = useHasOnlyResidenceOwnerRole(roles);
  const isOnlyMember = useHasOnlyMemberRole(roles);
  const isBoardPortalUser = useHasBoardPortalRole(roles);

  const isBrl = useIsBrl(currentResidence);
  const menuLabelBonus = isOnlyMember ? 'Oversikt' : 'Bonus';

  const sectionList: { [key: string]: Section } = {};
  sectionList.oversikt = {
    title: 'Oversikt',
    path: '/oversikt',
    showMemberCard: isMember,
    hideSection: isMemberOrResidenceOwner && !isMemberAndResidenceOwner,
  };

  if (isMember) {
    sectionList.bonus = {
      title: menuLabelBonus,
      path: '/bonus',
      showMemberCard: true,
    };

    sectionList.medlemsfordeler = {
      title: 'Medlemsfordeler',
      path: '/medlemsfordeler',
      showMemberCard: true,
    };
  }

  const menuLabelResidence = isOnlyResidenceOwner ? 'Oversikt' : 'Min bolig';

  if (isResidenceOwner) {
    sectionList.bolig = {
      title: menuLabelResidence,
      path: '/bolig',
      subSections: [
        { title: menuLabelResidence, path: '/bolig' },
        // { title: 'Mitt borettslag', path: '/bolig/borettslag' },
        { title: 'Felleskostnader', path: '/bolig/felleskostnader' },
        { title: 'Utleie', path: '/bolig/utleie' },
        { title: 'Kontakt styret', path: '/bolig/kontakt-styret' },
        { title: isBrl ? 'Generalforsamling' : 'Årsmøte', path: '/bolig/arsmote' },
      ],
    };
  }

  if (user) {
    sectionList.profil = {
      title: 'Min profil',
      path: '/profil',
    };
  }

  const getActiveSection = (): Section => {
    return sectionList[location.pathname.split('/')[1]];
  };

  const getActiveSubSection = (): Section | void => {
    const activeSection = getActiveSection();
    if (!activeSection?.subSections) {
      return;
    }
    const pathParts = location.pathname.split('/');
    if (pathParts.length !== 3) {
      return;
    }

    const subSection = activeSection.subSections.filter((x) => x.path === `/${pathParts[2]}`)[0];
    // eslint-disable-next-line consistent-return
    return subSection;
  };

  const activeSection = getActiveSection();
  const activeSubSection = getActiveSubSection();

  const [navigationMenuExpandedSection, setNavigationMenuExpandedSection] = useState('');
  const searchInputDesktopRef = useRef<HTMLInputElement>(null);
  const searchInputMobileRef = useRef<HTMLInputElement>(null);

  // https://stackoverflow.com/a/66404585
  useEffect(() => {
    const resetMenu = history.listen(() => {
      setNavigationMenuExpandedSection('');
      setNavigationMenuOpen(false);
      // Update notification count when navigating to new page
      updateNotificationCount();
    });
    return function cleanup() {
      resetMenu();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    let currentSearchList = searchText?.split(' ') || [];
    currentSearchList = currentSearchList.filter((x) => x.length >= 3);
    setSearchList(currentSearchList);
  }, [searchText]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const sanityData: any[] = await getClient().fetch(
        previewSearchResultsQuery(searchList),
      );

      const filteredResults = filterSearchResultsByRoles(sanityData, roles ?? []);
      const sortedResults = sortSearchResults(filteredResults, searchList);
      setSearchResults(sortedResults);
    }

    if (searchList && searchList.length) {
      fetchData();
    } else {
      setSearchResults([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList]);

  useEffect(() => {
    setShouldDisplaySearch(false);

    // After redesign, no pages should have special background color.
    // Keeping the call, in case this changes, but should consider hardcoding in App.tsx if the
    // requirements do not change.
    setPageBackground('bg-light-blue');
  }, [location, setPageBackground, benefitInfo]);

  const isMobile = useMediaQuery('(max-width: 767px)');

  // Opening quick search should set focus to search input
  useEffect(() => {
    // ?: Should display quick search?
    if (shouldDisplaySearch) {
      // -> Yes, add focus to the search input field.
      if (isMobile && searchInputMobileRef?.current) {
        searchInputMobileRef.current.focus();
      } else if (searchInputDesktopRef?.current) {
        searchInputDesktopRef.current.focus();
      }
    }
  }, [shouldDisplaySearch, isMobile]);

  const searchResultsAreShowing = shouldDisplaySearch && searchResults && searchResults.length;
  const searchQuery = searchList.join('+');

  return (
    <>
      <div className={`bg-white font-clanot-news 
        ${!activeSection?.subSections ? 'md:border-b-2 border-light-blue' : ''}`}
      >
        <div
          className={`bate-container items-center flex justify-between md:justify-start mx-5 md:mx-0 ${styles.header}`}
        >

          <Link to="/" className="flex flex-none items-center h-full mr-6">
            <img alt="Bate logo" src="/Bate-Logo-Red.png" />
          </Link>

          <ul className={`hidden nm:flex lg:flex-grow h-full transition ease-in-out duration-500 overflow-hidden 
            ${shouldDisplaySearch ? 'relative' : ''}`}
          >
            {!isMemberOrResidenceOwner || isMemberAndResidenceOwner ? (
              <li className={getLinkClass('/oversikt', false)}>
                <Link to="/oversikt" className="flex h-full px-3 md:px-4 items-center">Oversikt</Link>
              </li>
            ) : null}
            {isMember ? (
              <>
                <li className={getLinkClass('/bonus', false)}>
                  <Link to="/bonus" className="flex h-full px-3 md:px-4 items-center">{menuLabelBonus}</Link>
                </li>
                <li className={getLinkClass('/medlemsfordeler', false)}>
                  <Link to="/medlemsfordeler" className="flex h-full px-3 md:px-4 items-center">Medlemsfordeler</Link>
                </li>
              </>
            ) : null}
            {isResidenceOwner ? (
              <li className={getLinkClass('/bolig', false)}>
                <Link to="/bolig" className="flex h-full px-3 md:px-4 items-center">{menuLabelResidence}</Link>
              </li>
            ) : null }
          </ul>

          <div className="gap-2 flex flex-auto justify-end h-full">
            { /* My profile start */ }
            <div className="flex items-center my-auto transition-all">
              <Link
                to="/profil"
                className={`flex flex-none items-center my-auto rounded-full 
                h-12 w-auto nm:border-2 nm:border-light-grey 
                ${styles.headerButton}
                ${location.pathname.startsWith('/profil') ? 'bg-light-blue' : ''}`}
              >
                {user && (
                  <>
                    <span className="hidden nm:flex pl-4 pr-2 whitespace-nowrap">Min profil</span>
                    <img
                      className="max-h-[3rem] max-w-[3rem] rounded-full opacity-80 relative
                      border-2 border-light-grey nm:right-[-2px] object-cover"
                      src={`https://api.bbld.io/personimage/user/${user.id}/image/?${Date.now()}`}
                      alt="profilbilde"
                    />
                  </>
                )}
              </Link>
            </div>
            { /* My profile end */ }
            { /* Notice start */ }
            <div className="flex flex-none items-center my-auto text-xl">
              <Link
                to="/oppslagstavle"
                className={
                            `flex p-2.5 h-full rounded-full relative 
                            items-center border-2 border-light-grey text-2xl
                            ${shouldDisplaySearch ? 'nm:hidden' : ''}
                            ${styles.headerButton} 
                            ${location.pathname.startsWith('/oppslagstavle') ? 'bg-light-blue' : ''} `
                          }
              >
                {/* <MdMailOutline size={24} /> */}
                <img src="/icon-mail.png" alt="Oppslagstavle" className="h-6 w-6" />
                {
                  notificationCount != null && notificationCount !== 0
                    ? (
                      <span
                        className={`absolute top-0 right-0 ${styles.notificationBadge} leading-none 
                        text-white bg-bate-red rounded-full text-xs font-bold`}
                      >
                        {notificationCount}
                      </span>
                    ) : null
                }
              </Link>
            </div>
            { /* Notice end */ }
            { /* Search start */ }
            <div className="flex relative w-12 items-center">
              <div
                className={`flex items-center text-xl rounded-full bg-white border-2 border-light-grey 
                justify-end transition nm:absolute nm:right-0 hover:bg-light-blue ease-in-out
                  ${shouldDisplaySearch ? 'bg-light-blue nm:w-80' : ''}
                  ${shouldDisplaySearch && searchText.length
                  ? 'nm:border-t-2 nm:border-l-2 nm:border-r-2 nm:border-black'
                  : ''} `}
              >
                <div
                  className={`hidden nm:flex nm:flex-col justify-center p-3 pr-0 nm:p-4 nm:pr-0 
                    ${styles.headerSearch} ${shouldDisplaySearch ? styles.headerSearchExpand : ''}`}
                >
                  <input
                    type="text"
                    className={`w-full text-sm p-2 ${styles.headerInput} `}
                    placeholder="Søk..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    ref={searchInputDesktopRef}
                  />
                </div>
                {shouldDisplaySearch && searchText && (
                  <button
                    type="button"
                    className="hidden nm:block"
                    onClick={() => setSearchText('')}
                  >
                    <CgClose />
                  </button>
                )}
                <button
                  type="button"
                  className={`p-2.5 h-full rounded-full text-2xl ${styles.headerButton} 
                    ${shouldDisplaySearch ? 'bg-light-blue' : ''} `}
                  onClick={() => setShouldDisplaySearch(!shouldDisplaySearch)}
                >
                  <IoSearchOutline />
                </button>
                {shouldDisplaySearch ? (
                  <div
                    className={`hidden absolute nm:block z-50 bg-light-blue text-sm rounded-b-3xl 
                      ${searchText.length
                      ? 'p-3 border-b-2 border-l-2 border-r-2 border-black mx-4 top-[2.9rem] left-0 right-0'
                      : ''}`}
                  >
                    {!searchResultsAreShowing && searchText.length > 0 && searchText.length < 3 ? (
                      <>
                        Velg et søkeord med minst 3 tegn
                      </>
                    ) : null}
                    {!searchResultsAreShowing && searchText.length >= 3 ? (
                      <>
                        Søket etter “
                        <span className="break-words">{searchText}</span>
                        “ ga 0 treff
                      </>
                    ) : null}
                    {searchResultsAreShowing ? (
                      <QuickSearchResults searchResults={searchResults} searchQuery={searchQuery} />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            { /* Search end */ }

            {isBoardPortalUser ? (
              <a
                href="https://bate.bbl.no/portal"
                target="_blank"
                rel="noreferrer"
                className={`hidden nm:flex items-center my-auto rounded-full 
                h-12 px-4 w-auto border-2 border-light-grey hover:bg-light-blue
                ${styles.headerButton}`}
              >
                Portalen
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`flex flex-col bg-light-blue p-3 sm:p-4 items-center 
      ${shouldDisplaySearch ? 'nm:hidden' : 'hidden'} `}
      >
        <div className={`flex w-full max-w-lg items-center text-xl rounded-full border-2 
        border-light-grey justify-end transition bg-white pl-3 
          ${searchText.length
          ? 'border-t-2 border-l-2 border-r-2 border-black'
          : ''} `}
        >
          <input
            type="text"
            className="w-full text-sm p-2 bg-transparent focus:outline-none"
            placeholder="Søk"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            ref={searchInputMobileRef}
          />
          {searchText && (
          <button
            type="button"
            className="focus:outline-none"
            onClick={() => setSearchText('')}
          >
            <CgClose />
          </button>
          )}
          <button
            type="button"
            className="p-2.5 pr-4 h-full rounded-full text-2xl"
            onClick={() => setShouldDisplaySearch(!shouldDisplaySearch)}
          >
            <IoSearchOutline />
          </button>
        </div>
        {shouldDisplaySearch ? (
          <>
            {!searchResultsAreShowing && searchText.length > 0 && searchText.length < 3 ? (
              <div className="md:hidden bg-light-blue p-4">
                Velg et søkeord med minst 3 tegn
              </div>
            ) : null}
            {!searchResultsAreShowing && searchText.length >= 3 ? (
              <div className="md:hidden bg-light-blue p-4">
                Søket etter “
                <span className="break-words">{searchText}</span>
                “ ga 0 treff
              </div>
            ) : null}
            {searchResultsAreShowing ? (
              <div className="nm:hidden bg-light-blue p-4 w-full max-w-lg">
                <QuickSearchResults searchResults={searchResults} searchQuery={searchQuery} />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      {!navigationMenuOpen && (
        <div className="bg-light-blue">
          <div className="flex p-4 nm:hidden items-center">
            <div className="flex-grow">
              {activeSection?.title}
              {activeSubSection ? ` / ${activeSubSection.title}` : ''}
            </div>
            <button className="text-3xl" type="button" aria-label="Meny" onClick={() => setNavigationMenuOpen(true)}>
              <BiMenu color="#E21219" fontSize="2.5rem" />
            </button>
          </div>
          {activeSection && activeSection.subSections && (
            <div className="bate-container flex justify-between">
              <ul className="text-sm hidden nm:flex ">
                {activeSection.subSections.map((section) => (
                  <li key={section.path} className={getSubLinkClass(section.path)}>
                    <Link to={section.path}>{section.title}</Link>
                  </li>
                ))}
              </ul>
              {activeSection.showMemberCard ? (
                <div className={`hidden lg:flex ${styles.membershipDropdown}`}>
                  <MembershipInfoToggle containerCssClass="rounded-b-3xl" />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
      {navigationMenuOpen && (
      <div className="px-4 mb-8">
        <div className="border-medium-light-grey border-b-2">
          <button
            type="button"
            className="py-4 flex justify-between items-center w-full"
            onClick={() => setNavigationMenuOpen(false)}
          >
            <span>Lukk meny</span>
            <IoCloseOutline color="#E21219" fontSize="2.5rem" />
          </button>
        </div>
        {Object.keys(sectionList).map((key, index) => {
          const section = sectionList[key];
          if (section.hideSection) {
            return null;
          }
          const sectionRender = (
            <div
              key={key}
              className={`py-4 border-medium-light-grey 
                ${index < Object.keys(sectionList).length - 1 ? 'border-b-2' : ''}`}
            >
              {section.subSections?.length
                ? (
                  <button
                    type="button"
                    className="flex justify-between w-full"
                    onClick={() => {
                      return navigationMenuExpandedSection === section.path
                        ? setNavigationMenuExpandedSection('')
                        : setNavigationMenuExpandedSection(section.path);
                    }}
                  >
                    <div className={getLinkClass(section.path, true)}>
                      <Link to={section.path} className="flex-grow font-clanot-black p-2 pl-0 underline">
                        {section.title}
                      </Link>
                    </div>
                    {section.subSections?.length && (
                      <>
                        {navigationMenuExpandedSection === section.path
                    && <BsChevronUp color="#E21219" fontWeight="bold" fontSize="1.25rem" strokeWidth={1} />}
                        {navigationMenuExpandedSection !== section.path
                    && <BsChevronDown color="#E21219" fontWeight="bold" fontSize="1.25rem" strokeWidth={1} />}
                      </>
                    )}
                  </button>
                )
                : (
                  <div
                    key={section.path}
                    className={`${getLinkClass(section.path, true)}`}
                  >
                    <Link to={section.path} className="flex justify-between font-clanot-black underline">
                      {section.title}
                      <BsChevronRight color="#E21219" fontWeight="bold" fontSize="1.25rem" strokeWidth={1} />
                    </Link>
                  </div>
                )}
              {navigationMenuExpandedSection === section.path && (
              <div className="pl-4 mt-4 border-medium-light-grey border-t-2">
                {section.subSections?.map((subSection, i) => (
                  <div
                    key={subSection.path}
                    className={`py-4 border-medium-light-grey 
                      ${i < (section.subSections?.length ?? 0) - 1 ? 'border-b-2' : ''} 
                      ${getLinkClass(section.path + subSection.path, true)}`}
                  >
                    <Link to={subSection.path} className="flex justify-between underline">
                      {subSection.title}
                      <BsChevronRight color="#E21219" fontWeight="bold" fontSize="1.25rem" strokeWidth={1} />
                    </Link>
                  </div>
                ))}
              </div>
              )}
            </div>
          );
          return sectionRender;
        })}

        { isBoardPortalUser && (
          <div className="py-4 border-medium-light-grey border-t-2">
            <a
              href="https://bate.bbl.no/portal"
              target="_blank"
              rel="noreferrer"
              className="flex justify-between underline font-clanot-black"
            >
              Portalen
              <BsChevronRight color="#E21219" fontWeight="bold" fontSize="1.25rem" strokeWidth={1} />
            </a>
          </div>
        )}
      </div>
      )}
    </>
  );
};

export default Header;

export enum RouteSection {
  Unrestricted,
  Member,
  Residence,
  All
}
export enum StartPageType {
  ForYou = 1,
  ForBoard = 2,
  ForDeveloper = 3,
}

export enum LinkButtonType {
  Primary = 1,
  Secondary = 2,
}

export enum ResidenceType {
  NotSet = 0,
  New = 1,
  Used = 2,
  Rental = 3,
}

export enum ModalType {
  RegisterInterest = 'register-interest-modal',
  PreemptiveRights = 'preemptive-rights-modal',
}

export enum FactsLayoutType {
  Background = 1,
  Border = 2,
}

export enum InternalLinkType {
  NotSet = 0,
  Web = 1,
  Minside = 2,
}

export enum PageSectionSize {
  Large = 1,
  Medium = 2,
  Small = 3,
}

export enum LinkVisibility {
  Everyone = 0,
  Members = 1,
  ResidenceOwners = 2,
  MembersAndResidenceOwners = 3,
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { InternalLink as LinkType } from '../../types/sanity';
import { InternalLinkType } from '../../utils/enums';

interface Props {
  link: LinkType;
  className?: string;
  inline: boolean;
  shouldDisplayButton?:boolean;
}

const InternalLink: React.FC<Props> = ({
  link, className = null, inline, shouldDisplayButton = false,
}) => {
  return link.type === InternalLinkType.Minside ? (
  // Min side link
    <Link
      to={link.link.href}
      className={`
      ${shouldDisplayButton
        ? 'btn btn-primary rounded-bate btn-animate-arrow-right items-center gap-x-2 !inline-flex'
        : (inline ? '' : ' font-clanot-black arrow-link btn-animate-arrow-right')} 
        ${className}`}
    >
      {inline ? link.text : (
        <div className="flex gap-x-2 items-center">
          {link.text}
          <ImArrowRight2 className={`
            text-xl bounce flex-none
            ${shouldDisplayButton
            ? ''
            : 'text-bate-red'}`}
          />
        </div>
      )}
    </Link>
  ) : (
  // Bate web link
    <a
      href={link.link.href}
      target="_blank"
      rel="noreferrer"
      className={`
      ${shouldDisplayButton
        ? 'btn btn-primary rounded-bate btn-animate-arrow-right items-center gap-x-2 !inline-flex'
        : (inline ? '' : 'font-clanot-black arrow-link btn-animate-arrow-right')} 
        ${className}`}
    >
      {inline ? link.text : (
        <div className="flex gap-x-2 items-center">
          {link.text}
          <ImArrowRight2 className={`
            text-xl bounce flex-none
            ${shouldDisplayButton
            ? ''
            : 'text-bate-red'}`}
          />
        </div>
      )}
    </a>
  );
};

export default InternalLink;

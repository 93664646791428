import { QuoteModel } from '../types/sanity';

const getQuoteModel: (node: any) => QuoteModel = (node) => {
  const quote: QuoteModel = {
    quote: node.quote,
    name: node.name,
    position: node.position,
  };
  return quote;
};

export default getQuoteModel;

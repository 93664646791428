export const createImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
  image.src = url;
});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  percentCrop: { x: number; y: number; width: number; height: number; },
  fileType = 'image/jpeg',
): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // Get image bounding box
  const { width: bBoxWidth, height: bBoxHeight } = image;

  // Calculate pixel positions from percent crop
  const x = bBoxWidth * (percentCrop.x / 100);
  const y = bBoxHeight * (percentCrop.y / 100);
  const width = bBoxWidth * (percentCrop.width / 100);
  const height = bBoxHeight * (percentCrop.height / 100);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.drawImage(
    image,
    0,
    0,
    bBoxWidth,
    bBoxHeight,
  );

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    x,
    y,
    width,
    height,
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = width;
  canvas.height = height;

  // paste generated image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file != null) {
        resolve(file);
      }
      reject(new Error('Canvas is empty'));
    }, fileType);
  });
}

import { FactsModel } from '../types/sanity';

const getFactsModel: (node: any) => FactsModel = (node) => {
  const facts: FactsModel = {
    image: node.figure,
    title: node.title,
    text: node.text,
    layout: node.layout,
  };
  return facts;
};

export default getFactsModel;

import React from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  href: string;
  bold?: boolean;
  openInNewWindow?: boolean;
  useLink?: boolean;
}

const BateLink: React.FC<Props> = ({
  children, className, href, bold, openInNewWindow, useLink,
}) => {
  if (useLink) {
    return (
      <Link
        to={href}
        className={`inline-flex gap-6 font-${bold ? 'bold border-b-4' : 'normal border-b-2'} border-black ${className}`}
      >
        {children}
        <ImArrowRight2 className="mt-1" />
      </Link>
    );
  }

  return (
  // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      target={openInNewWindow ? '_blank' : ''}
      rel={openInNewWindow ? 'noreferrer' : ''}
      className={`inline-flex gap-6 font-${bold ? 'bold border-b-4' : 'normal border-b-2'} border-black ${className}`}
    >
      {children}
      <ImArrowRight2 className="mt-1" />
    </a>
  );
};

BateLink.defaultProps = {
  className: '',
  bold: true,
  openInNewWindow: false,
};

export default BateLink;

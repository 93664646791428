import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import BateButton from '../../../components/Elements/BateButton';
import { IMeetingElectionCandidateModel, IMeetingElectionVoteModel, IMeetingSectionModel } from '../../../types/digitalMeetings';
import { ElectionCheckbox } from './ElectionCheckbox';
import { deleteVotingForBoardMemberOnSection, executeVotingForBoardMember } from './votingHelpers';

type Props = {
    section: IMeetingSectionModel
    onVoted: (closeAccordion? :boolean) => void
    meetingId: string,
    propertyId: string
}

export const ElectionVoting: React.FC<Props> = ({
  meetingId,
  onVoted,
  propertyId,
  section,
}) => {
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState<IMeetingElectionCandidateModel[]>(section?.election?.candidates || []);
  const [error, setError] = useState<boolean>(false);

  // Store temp candidate votes
  const [votes, setVotes] = useState<IMeetingElectionVoteModel[]>([]);

  const initializeVotes = useCallback(() => {
    setVotes(candidates.map<IMeetingElectionVoteModel>(
      (candidate) => {
        return { candidate, oldValue: candidate.votedFor, newValue: candidate.votedFor };
      },
    ));
  }, [candidates]);

  useEffect(() => {
    initializeVotes();
  }, [initializeVotes]);

  const voted = useMemo(() => {
    return candidates.some((c) => c.votedFor);
  }, [candidates]);

  const voteForBoardMember = useCallback(async (candidateId: string) => {
    try {
      setLoading(true);
      const candidateVotingId = await executeVotingForBoardMember(section, meetingId, candidateId, propertyId);

      // Set candidate voting id on the candidate
      const index = candidates.findIndex((candidate) => candidate.id === candidateId);
      candidates[index].votedFor = true;
      candidates[index].candidateVotingId = candidateVotingId;
      setCandidates([...candidates]);
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [candidates, meetingId, propertyId, section]);

  const deleteVotingForBoardMember = useCallback(async (candidate: IMeetingElectionCandidateModel) => {
    try {
      setLoading(true);
      await deleteVotingForBoardMemberOnSection(meetingId, candidate.candidateVotingId);
      // Find index of the selected candidate
      const index = candidates.findIndex((c) => c.id === candidate.id);
      // Update the selected candidate
      candidates[index].votedFor = false;
      // Update the state
      setCandidates([...candidates]);
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [candidates, meetingId]);

  const addVote = (candidateId: string, votedFor: boolean): void => {
    const index = votes.findIndex((c) => c.candidate.id === candidateId);
    votes[index].newValue = votedFor;
    setVotes([...votes]);
  };

  const saveVotes = useCallback(async (currentVotes: IMeetingElectionVoteModel[]) => {
    const votePromises: Promise<void>[] = [];
    currentVotes.forEach((vote) => {
      if (vote.newValue !== vote.oldValue) {
        if (vote.newValue) {
          votePromises.push(voteForBoardMember(vote.candidate.id));
        } else {
          votePromises.push(deleteVotingForBoardMember(vote.candidate));
        }
      }
    });

    await Promise.all(votePromises);

    initializeVotes();
    onVoted(true);
  }, [deleteVotingForBoardMember, initializeVotes, onVoted, voteForBoardMember]);

  const anyVotesChanged: boolean = useMemo(() => {
    return votes.some((vote) => vote.oldValue !== vote.newValue);
  }, [votes]);

  const moreThanOneVote = (section.election.numberOfPersons || 0) > 1;

  const candidatesText = moreThanOneVote ? 'kandidater' : 'kandidat';
  const votesText = moreThanOneVote ? 'stemmer' : 'stemme';

  const maxNumberOfVotes = section.election.numberOfPersons;
  const numberOfCurrentVotes = votes.filter((c) => c.newValue).length;

  return (
    <div className="bg-white py-5 mb-3 relative">
      <div className="font-clanot-black text-bate-red heading-underline my-5">
        {voted ? 'Stemme avgitt' : 'Avgi stemme'}
      </div>
      <div>
        Du kan stemme på inntil
        {' '}
        {maxNumberOfVotes}
        {' '}
        {candidatesText}
        .
        {' '}
        <b>
          {numberOfCurrentVotes}
          {' '}
          av
          {' '}
          {maxNumberOfVotes}
        </b>
        {' '}
        {votesText}
        {' '}
        avgitt.
      </div>
      <div className="italic mt-5">
        Det stilles ikke krav til å avgi stemme i saken.
        Dersom du ikke avgir stemme, registreres det som blank stemme ved opptelling.
      </div>
      <div className="mt-5">
        {
              votes.map((vote) => (
                <ElectionCheckbox
                  key={vote.candidate.id}
                  disabled={
                    loading
                    // If max number of votes have been cast, disable rest of inactive vote check boxes
                    || (!vote.newValue && numberOfCurrentVotes === maxNumberOfVotes)
                  }
                  checked={vote.newValue}
                  onClick={() => addVote(vote.candidate.id, !vote.newValue)}
                >

                  <div className="ml-5 pt-1">
                    {vote.newValue ? (
                      <>
                        Stemmer for:
                        {' '}
                        <b>{vote.candidate.name}</b>
                      </>
                    ) : vote.candidate.name}
                  </div>
                </ElectionCheckbox>
              ))
            }
      </div>
      <div className="flex items-center mt-10">
        <div className={`${error ? '' : 'hidden'} text-bate-red font-bold`}>
          Det skjedde en feil, vennligst prøv igjen.
        </div>
        <div>
          <BateButton
            className="btn-primary my-2"
            onClick={() => saveVotes(votes)}
            disabled={loading || !anyVotesChanged}
          >
            {voted ? 'Endre' : 'Avgi' }
            {' '}
            {votesText}
          </BateButton>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { ImArrowDown2 } from 'react-icons/im';
import { CgClose } from 'react-icons/cg';
import MembershipInfo from './MembershipInfo';

import styles from './MembershipInfoToggle.module.scss';

interface Props {
  containerCssClass?: string;
}

const MembershipInfoToggle: React.FC<Props> = ({ containerCssClass = '' }) => {
  const [shouldDisplayMembership, setShouldDisplayMembership] = useState(false);

  return (
    <div className="relative flex w-full mb-10">
      <div className={`shadow-md text-white bg-bate-red absolute top-0 right-0 left-0 z-10 ${containerCssClass}`}>
        <button
          type="button"
          onClick={() => setShouldDisplayMembership(true)}
          className={`w-full py-5 px-7 flex items-center justify-between 
                        ${shouldDisplayMembership ? 'hidden' : 'flex'} `}
        >
          <span className="font-clanot-black">Mitt medlemsbevis</span>
          <ImArrowDown2 className="ml-4 text-xl" />
        </button>
        <div className={`flex-col w-full ${shouldDisplayMembership ? 'flex' : 'hidden'} `}>
          <button
            type="button"
            onClick={() => setShouldDisplayMembership(false)}
            className={`self-end text-2xl relative z-10 pr-6 ${styles.membershipInfoClose} `}
            aria-label="Close"
          >
            <CgClose />

          </button>
          <div className="relative">
            <MembershipInfo isCardStyle={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipInfoToggle;

import React from 'react';
import { QuoteModel } from '../../types/sanity';
import styles from './Quote.module.scss';

interface Props {
  quote: QuoteModel;
}

const Quote: React.FC<Props> = ({ quote }) => {
  const hasFigCaption = quote.name || quote.position;

  return (
    <figure className={styles.figure}>
      <q className={hasFigCaption ? 'mb-0' : ''}>{quote.quote}</q>
      {hasFigCaption && (
        <figcaption
          className={`pl-10 mb-8 font-clanot-news ${styles.figcaption}`}
        >
          {quote.name && <div>{quote.name}</div>}
          {quote.position && <div>{quote.position}</div>}
        </figcaption>
      )}
    </figure>
  );
};

export default Quote;

import React from 'react';
import { FactsModel } from '../../types/sanity';
import { FactsLayoutType } from '../../utils/enums';
import styles from './Facts.module.scss';
// eslint-disable-next-line import/no-cycle
import { urlFor, shouldDisplayImage, PortableText } from '../../../utils/sanity.jsx';

interface Props {
  facts: FactsModel;
}

const Facts: React.FC<Props> = ({ facts }) => {
  const {
    image, title, text, layout,
  } = facts;

  const useBorder = layout === FactsLayoutType.Border;

  return useBorder ? (
    <div className={`${styles.container} border-t-2 border-primary my-10`}>
      <div className="lg:flex lg:justify-center mt-4">
        <div className="lg:w-4/12">
          {shouldDisplayImage(image) && (
          <figure>
            <img
              className="w-full"
              src={urlFor(image)
                .width(260)
                .height(160)
                .fit('crop')
                .auto('format')
                .url() || undefined}
              alt={image.alt}
            />
          </figure>
          )}
        </div>
        <div className="lg:w-8/12">
          <div className="lg:ml-6 xl:ml-8">
            <div className="body my-4 lg:my-0">
              {title && <div className="h3">{title}</div>}
              {text && <PortableText blocks={text} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.container} bg-blue my-10 p-6 rounded-lg lg:-mx-4`}
    >
      <div className="flex mb-4">
        <div className="flex-shrink-0">
          <img
            src="/lightbulb.png"
            alt="Lyspære"
            className="h-8 w-8 mr-4"
          />
        </div>
        {title && <div className="h3 mt-1">{title}</div>}
      </div>
      {text && <PortableText blocks={text} />}
    </div>
  );
};

export default Facts;

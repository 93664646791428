import React from 'react';
import { Link } from 'react-router-dom';
import { SearchResultModel } from '../../../types/sanity/types';
import { getSearchResultUrl } from '../../../utils/search';

interface Props {
  searchResults: SearchResultModel[];
  searchQuery: string;
}
const QuickSearchResults: React.FC<Props> = ({
  searchResults,
  searchQuery,
}) => {
  return (
    <>
      {searchResults.slice(0, 5).map((result, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="pb-3">
          <Link to={getSearchResultUrl(result)} className="flex flex-col p-3 hover:bg-white">
            <div className="text-xs">{result.category}</div>
            <div className="text-sm">{result.title}</div>
          </Link>
        </div>
      ))}
      <div className="py-4 px-3">
        <Link to={`/sok?q=${searchQuery}`} className="font-bold border-b border-black text-sm">
          Se fullt søkeresultat
        </Link>
      </div>
    </>
  );
};

export default QuickSearchResults;

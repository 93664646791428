import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AccountInfo } from '@azure/msal-browser';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { msalInstance } from '../msal';
import {
  IMembership,
  IUserDetails,
  IPerson,
  IMemberBenefitsInfo,
  IHousingUnit,
  IJointCostsTableVm,
  IInvoiceDto,
  IInvoiceOverviewVm,
  IRole,
  IBoard,
  IAnnualCycle,
  IStoreboxApiCardListModel,
  IStoreboxApiCardFrameModel,
  IBulletin,
  IComment,
  IBulletinAttachmentInfo,
  IPublication,
  IPublicationAttachment,
  IPersonImage,
  IMembershipTransferReceiptModel,
  IMembershipTransfer,
  IMembershipTransferType,
  IInvoiceOverviewItemVm,
  IStoreboxApiCardModel,
} from '../types';
import { loginRequest } from '../msal/authConfig';

let navneregisterid: string;
let activeAccount: AccountInfo;

const setActiveAccountAndNavneRegisterId: () => void = () => {
  const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
  if (!account) {
    return;
  }
  activeAccount = account;
  navneregisterid = activeAccount
    && jwt_decode<any>((<any>activeAccount?.idTokenClaims).idp_access_token)?.navneregisterid;
};

const activeAccountIsSet: () => boolean = () => {
  if (!activeAccount) {
    setActiveAccountAndNavneRegisterId();
  }
  if (!activeAccount) {
    return false;
  }
  return true;
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = Axios.create({ baseURL });
instance.interceptors.request.use(
  async (request) => {
    if (!activeAccount) {
      /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
      throw Error('User is not signed in.');
    }

    const acquireToken = msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: activeAccount,
    });
    acquireToken.catch(() => { msalInstance.acquireTokenRedirect(loginRequest); });

    const response = await acquireToken;

    request.headers.Authorization = `Bearer ${response.accessToken}`;
    // request.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS';
    // Axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
    return request;
  },
);

// Axios.defaults.headers.common['Access-Control-Request-Headers'] = 'access-control-allow-origin,authorization,content-type,access-control-allow-methods';
// Axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';

export const getRoles = async (): Promise<IRole[] | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IRole[]>(`${baseURL}/roles`);
  return response.data;
};

export const getUser = async (): Promise<IUserDetails | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IUserDetails>(`${baseURL}/users/${navneregisterid}`);
  return response.data;
};

export const updateUser = async (params: IUserDetails): Promise<string | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.put<string>(`${baseURL}/users/${navneregisterid}`, params);
  return response.data;
};

export const deletePersonImage = async (): Promise<string | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }
  const response = await instance.delete<string>(`${baseURL}/users/${navneregisterid}/image`);
  return response.data;
};

export const postPersonImage = async (personImage: IPersonImage, fileName?: string): Promise<string | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  if (!personImage.file) return null;

  const formData = new FormData();
  formData.append('file', personImage.file, fileName ?? personImage.file.name);

  const response = await instance.post(`${baseURL}/users/${navneregisterid}/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getPerson = async (): Promise<IPerson> => {
  const response = await instance.get<IPerson>(`${baseURL}/people`);
  return response.data;
};

export const postTransferMembership = async (
  membershipTransfer: IMembershipTransfer,
): Promise<AxiosResponse<IMembershipTransferReceiptModel> | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.post<IMembershipTransferReceiptModel>(
    `${baseURL}/users/${navneregisterid}/membership/transfer`,
    { ...membershipTransfer },
  );
  return response;
};

export const postTransferMembershipByEmail = async (
  membershipTransfer: IMembershipTransfer,
): Promise<string | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.post<string>(
    `${baseURL}/users/${navneregisterid}/membership/transfer-by-email`,
    { ...membershipTransfer },
  );
  return response.data;
};

export const getMembership = async (): Promise<IMembership | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IMembership>(`${baseURL}/users/${navneregisterid}/membership`);
  return response.data;
};

export const getBenefitInfo = async (): Promise<IMemberBenefitsInfo | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IMemberBenefitsInfo>(`${baseURL}/users/${navneregisterid}/membership/bonus`);
  return response.data;
};

export const getUnits = async (): Promise<IHousingUnit[] | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IHousingUnit[]>(`${baseURL}/users/${navneregisterid}/housingswithowners`);
  return response.data;
};

export const getJointCosts = async (residenceId: string | null, year: number): Promise<IJointCostsTableVm | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IJointCostsTableVm>(
    `${baseURL}/housings/${residenceId}/joint-costs-table?year=${year}`,
  );
  return response.data;
};

export const getInvoiceList = async (residenceId?: string): Promise<IInvoiceOverviewItemVm[]> => {
  const response = await instance.get(`${baseURL}/invoices?${residenceId ? `housingId=${residenceId}` : ''}`);
  return response.data;
};

export const getLastInvoice = async (residenceId?: string): Promise<IInvoiceDto | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IInvoiceDto>(`${baseURL}/invoices/last?housingId=${residenceId}`);

  return response.data;
};

export const getInvoiceOverview = async (residenceId: string, fromInvoiceDate?: string)
  : Promise<IInvoiceOverviewVm | null> => {
  if (!activeAccountIsSet()) {
    return null;
  }

  const response = await instance.get<IInvoiceOverviewVm>(
    `${baseURL}/invoices/overview?housingId=${residenceId}${fromInvoiceDate ? `&fromDate=${fromInvoiceDate}` : ''}`,
  );
  return response.data;
};

export const getBoards = async (clientId: string | null): Promise<IBoard[] | null> => {
  if (!clientId) {
    return null;
  }

  const response = await instance.get<IBoard[]>(`${baseURL}/clients/${clientId}/boardswithmembers`);
  return response.data;
};

// Generic put request
export const put = async <T, Q>(path: string, payload: Q): Promise<T> => {
  const response = await instance.put<T>(`${baseURL}${path}`, payload);
  return response.data;
};

// Generic post request
export const post = async <T, Q>(path: string, payload: Q, config?: AxiosRequestConfig): Promise<T> => {
  const response = await instance.post<T>(`${baseURL}${path}`, payload, config);
  return response.data;
};

export const deleter = async (path: string): Promise<void> => {
  await instance.delete(`${baseURL}${path}`);
};

// Generic get request
export const get = async <T>(path: string): Promise<T> => {
  const response = await instance.get<T>(`${baseURL}${path}`);
  return response.data;
};

// Download file
export const downloadFile = async (path: string): Promise<Blob> => {
  const response = await instance.get(`${baseURL}${path}`, { responseType: 'blob' });
  return response.data;
};

export const getUserCardsAndAccounts = async (): Promise<IStoreboxApiCardListModel> => {
  const response = await instance.get<IStoreboxApiCardListModel>(
    `${baseURL}/users/${navneregisterid}/membership/bonus/cards`,
  );
  return response.data;
};

export const getAddCardIFrame = async (type: number): Promise<IStoreboxApiCardFrameModel> => {
  const response = await instance.get<IStoreboxApiCardFrameModel>(`${baseURL}/users/${navneregisterid}/membership/bonus/addCard/${type}`);
  return response.data;
};

export const deleteUserCardOrAccount = async (cardId: string): Promise<string> => {
  const response = await instance.delete<string>(`${baseURL}/users/${navneregisterid}/membership/bonus/cards/${cardId}`);
  return response.data;
};

export enum CardUpdateType {
  AddedCard = 'PersonCards.Created',
  RemovedCard = 'PersonCards.Deleted',
  SelectedForBonus = 'Person.MemberCashbackAccount'
}

export const reportUserCardOrAccountChange = async (
  { updateType, userDetails, card }:
    { updateType: CardUpdateType, userDetails?: IUserDetails, card?: IStoreboxApiCardModel },
): Promise<string> => {
  const response = await instance.post(
    `${baseURL}/users/${navneregisterid}/membership/bonus/cards/report-update`,
    {
      updateType,
      payload: {
        card,
        memberCashbackAccount: userDetails,
      },
    },
  );

  return response.data;
};

export const getAnnualCycle = async (unitId: string | null): Promise<IAnnualCycle | null> => {
  if (!unitId) {
    return null;
  }

  const response = await instance.get<IAnnualCycle>(`${baseURL}/housings/${unitId}/annualcycle`);
  return response.data;
};

export const getBulletinBoardsByClient = async (
  clientId: string,
  onlyUnread = false,
  includeInactive = false,
): Promise<IBulletin[] | null> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-client/${clientId}?onlyUnread=${onlyUnread}&includeInactive=${includeInactive}`,
  );
  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getBulletinBoardsByUser = async (
  userId: string,
  onlyUnread = false,
  includeInactive = false,
): Promise<IBulletin[] | null> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-user/${userId}?onlyUnread=${onlyUnread}&includeInactive=${includeInactive}`,
  );
  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getLatestUnreadBulletinBoardByClient = async (
  clientId: string,
  includeInactive = false,
): Promise<IBulletin | null> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-client/${clientId}/latest-unread?includeInactive=${includeInactive}`,
  );
  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getLatestBulletinBoardByClient = async (
  clientId: string,
  prioritizeUnread = false,
  includeInactive = false,
): Promise<IBulletin | null> => {
  let url = `${baseURL}/bulletinboards/by-client/${clientId}/latest`;

  const filters = [];
  if (prioritizeUnread) {
    filters.push(`prioritizeUnread=${prioritizeUnread}`);
  }
  if (includeInactive) {
    filters.push(`includeInactive=${includeInactive}`);
  }

  if (filters.length) {
    url += `?${filters.join('&')}`;
  }

  const response = await instance.get(url);

  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getLatestUnreadBulletinBoardByUser = async (
  userId: string,
  includeInactive = false,
): Promise<IBulletin | null> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-user/${userId}/latest-unread?includeInactive=${includeInactive}`,
  );
  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getLatestBulletinBoardByUser = async (
  userId: string,
  prioritizeUnread = false,
  includeInactive = false,
): Promise<IBulletin | null> => {
  let url = `${baseURL}/bulletinboards/by-user/${userId}/latest`;

  const filters = [];
  if (prioritizeUnread) {
    filters.push(`prioritizeUnread=${prioritizeUnread}`);
  }
  if (includeInactive) {
    filters.push(`includeInactive=${includeInactive}`);
  }

  if (filters.length) {
    url += `?${filters.join('&')}`;
  }

  const response = await instance.get(url);
  if (response.status === 204) {
    return null;
  }
  return response.data;
};

export const getUnreadBulletinCountByClient = async (
  clientId: string,
  includeInactive = false,
): Promise<number> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-client/${clientId}/unread-count?includeInactive=${includeInactive}`,
  );
  return response.data;
};

export const getUnreadBulletinCountByUser = async (userId: string, includeInactive = false): Promise<number> => {
  const response = await instance.get(
    `${baseURL}/bulletinboards/by-user/${userId}/unread-count?includeInactive=${includeInactive}`,
  );
  return response.data;
};

export const getBulletinBoard = async (bulletinId: string, includeInactive = false): Promise<IBulletin | null> => {
  const response = await instance.get(`${baseURL}/bulletinboards/${bulletinId}?includeInactive=${includeInactive}`);
  return response.data;
};

export const setBulletinRead = async (bulletinId: string, read = true): Promise<string> => {
  const response = await instance.put(`${baseURL}/bulletinboards/${bulletinId}/set-read-state?read=${read}`);
  return response.data;
};

export const getBulletinComments = async (bulletinId: string): Promise<IComment[] | null> => {
  const response = await instance.get(`${baseURL}/bulletinboards/${bulletinId}/comments`);
  return response.data.sort((a: IComment, b: IComment) => Date.parse(b.createdDate) - Date.parse(a.createdDate));
};

export const getBulletinComment = async (bulletinId: string, commentId: string): Promise<IComment | null> => {
  const response = await instance.get(`${baseURL}/bulletinboards/${bulletinId}/comments/${commentId}`);
  return response.data;
};

export const postBulletinComment = async (bulletinId: string, comment: string): Promise<Record<string, unknown>> => {
  const response = await instance.post(`${baseURL}/bulletinboards/${bulletinId}/comments`, { text: comment });
  return {
    message: response.data.message,
    location: response.headers.location,
  };
};

export const putBulletinComment = async (bulletinId: string, commentId: string, comment: string): Promise<unknown> => {
  const response = await instance.put(
    `${baseURL}/bulletinboards/${bulletinId}/comments/${commentId}`,
    { text: comment },
  );
  return response.data;
};

export const deleteBulletinComment = async (bulletinId: string, commentId: string): Promise<string> => {
  const response = await instance.delete(`${baseURL}/bulletinboards/${bulletinId}/comments/${commentId}`);
  return response.data;
};

export const getBulletinAttachment = async (bulletinId: string, attachmentId: string): Promise<string> => {
  const response = await instance.get(`${baseURL}/bulletinboards/${bulletinId}/attachments/${attachmentId}`);
  return response.data;
};

// Based on the js-file-downloader package
// https://github.com/AleeeKoi/js-file-downloader/blob/master/src/index.js
export const downloadAttachment = async (
  response: AxiosResponse,
  type: string,
  fileName: string,
): Promise<File | Blob> => {
  let file: File | Blob;

  try {
    file = new File([response.data], fileName, { type });
  } catch (e) {
    file = new Blob([response.data], { type });
  }

  const objectUrl = window.URL.createObjectURL(file);

  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = objectUrl;
  link.download = fileName;

  let event;
  // initMouseEvent is deprecated (but still supported by most browsers).
  // But not all browsers have moved over to the new way of handling events.
  try {
    event = new MouseEvent('click');
  } catch (e) {
    event = document.createEvent('MouseEvent');
    event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }

  link.dispatchEvent(event);

  // Cleanup
  setTimeout(() => {
    (window.URL || window.webkitURL || window).revokeObjectURL(objectUrl);
  }, 1000 * 10);

  return file;
};

export const downloadBulletinAttachment = async (attachment: IBulletinAttachmentInfo): Promise<File | Blob> => {
  const type = attachment.mimetype;
  const response = await instance.get(
    `${baseURL}/bulletinboards/${attachment.bulletinId}/attachments/${attachment.id}`,
    {
      headers: {
        Accept: type,
      },
      responseType: 'blob',
    },
  );

  return downloadAttachment(response, type, attachment.filename);
};

export const getPublication = async (publicationId: string): Promise<IPublication | null> => {
  const response = await instance.get(`${baseURL}/publications/${publicationId}`);
  return response.data;
};

export const getPublications = async (includeInactive = false): Promise<IPublication[] | null> => {
  const response = await instance.get(`${baseURL}/publications?includeInactive=${includeInactive}`);
  return response.data;
};

export const getMembershipTransferTypes = async (): Promise<IMembershipTransferType[]> => {
  const response = await instance.get(`${baseURL}/users/membership-transfer-types`);
  return response.data.data;
};

export const getPublicationsByClient = async (
  clientId: string,
  includeInactive = false,
): Promise<IPublication | null> => {
  const response = await instance.get(
    `${baseURL}/publications/by-client/${clientId}?includeInactive=${includeInactive}`,
  );
  return response.data;
};

export const setPublicationRead = async (publicationId: string, read = true): Promise<IPublication | null> => {
  const response = await instance.put(`${baseURL}/publications/${publicationId}/set-read-state?read=${read}`);
  return response.data;
};

export const downloadPublicationAttachment = async (publicationId: string, attachment: IPublicationAttachment)
  : Promise<File | Blob> => {
  const type = attachment.mimeType;
  const response = await instance.get(
    `${baseURL}/publications/${publicationId}/attachments/${attachment.blobId}`,
    {
      headers: {
        Accept: type,
      },
      responseType: 'blob',
    },
  );

  return downloadAttachment(response, type, attachment.fileName);
};

export const getUnreadPublicationCount = async (includeInactive = false): Promise<number> => {
  const response = await instance.get(`${baseURL}/publications/unread-count?includeInactive=${includeInactive}`);
  return response.data;
};

export const getAllNotices = async (
  userId: string,
  includeInactive = false,
): Promise<Array<IBulletin | IPublication>> => {
  const bulletinPromise = getBulletinBoardsByUser(userId, includeInactive);
  const publicationPromise = getPublications(includeInactive);

  const result = await Promise.allSettled([bulletinPromise, publicationPromise]);

  const bulletins = result[0];
  const publications = result[1];

  const notices: Array<IBulletin | IPublication> = [];

  if (bulletins.status === 'fulfilled' && bulletins.value?.length) {
    notices.push(...bulletins.value);
  }
  if (publications.status === 'fulfilled' && publications.value?.length) {
    notices.push(...publications.value);
  }

  notices.sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate));

  return notices;
};

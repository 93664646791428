import React from 'react';

type Props = {
    pretitle: string;
    title: string;
    subtitle: string;
    proxy?: string;
    subtexts? : string[] | null;
    lastSubtextBold? : boolean;
    topLeftBadge?: string,
}

export const PhaseHeader: React.FC<Props> = ({
  pretitle, subtitle, title, proxy, subtexts, topLeftBadge, lastSubtextBold = false,
}) => {
  return (
    <div
      className="flex flex-col justify-between order-last md:order-first text-black"
    >
      <div className="flex flex-col relative">
        {topLeftBadge && (
          <div className="bg-white flex- rounded-full px-5 py-3 self-end
          md:absolute md:-top-6 right-0 mb-4 font-clanot-black"
          >
            {topLeftBadge}
          </div>
        )}
        <div className="font-clanot-black mb-2">
          { pretitle }
        </div>
        <div className="heading-underline text-3xl md:text-4.5xl font-clanot-ultra text-bate-red">
          {title}
        </div>
        <div className="pt-6 text-md">
          { subtitle }
        </div>
        { proxy && (
        <div className="pt-6 text-md">
          Fullmaktshaver for
          {' '}
          { proxy }
        </div>
        )}
      </div>

      {subtexts && (
      <div>
        {subtexts.map((subtext, i) => (
          <div key={i} className={`mt-5 ${i + 1 === subtexts.length && lastSubtextBold ? 'font-bold' : ''}`}>
            {subtext}
          </div>
        ))}
      </div>
      )}

    </div>
  );
};

import Modal from 'react-modal';

const MONTHS_SHORT = [
  'jan',
  'feb',
  'mar',
  'apr',
  'mai',
  'jun',
  'jul',
  'aug',
  'sep',
  'okt',
  'nov',
  'des',
];

const MONTHS_NORWEGAIN = [
  'januar',
  'februar',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'desember',
];

const MODAL_STYLE: Modal.Styles = {
  overlay: {
    zIndex: 1200,
  },
  content: {
    position: 'relative',
    height: 'auto',
    width: '100%',
    maxWidth: '768px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '250px',
    marginBottom: '250px',
    inset: 0,
  },
};

const MODAL_STYLE_NARROW = {
  ...MODAL_STYLE,
  content: {
    ...MODAL_STYLE.content,
    maxWidth: '470px',
    padding: '50px',
  },
};

const BATE_RED_HEADING_STYLE = 'heading-underline text-xl text-bate-red pb-2 font-clanot-black';

export {
  MONTHS_SHORT, MONTHS_NORWEGAIN, MODAL_STYLE, MODAL_STYLE_NARROW, BATE_RED_HEADING_STYLE,
};

import React from 'react';
import { DigitalVotingTypeEnum, IMeetingSectionModel } from '../../../types/digitalMeetings';
import { useHasVoted } from '../context/HasVotedContext';
import { ElectionVoting } from './ElectionVoting';
import { NormalVoting } from './NormalVoting';

type Props = {
  section: IMeetingSectionModel
  onVoted: (closeAccordion? :boolean) => void
  meetingId: string
  propertyId: string
  voted: boolean
}

export const Voter: React.FC<Props> = ({
  section, onVoted, meetingId, propertyId, voted,
}) => {
  const { canVote } = useHasVoted({ sectionId: section.id });

  if (section.votingOptions === DigitalVotingTypeEnum.IngenStemming) {
    return <div className="mt-3" />;
  }

  // If there is an election
  if (section.votingOptions === DigitalVotingTypeEnum.Valg) {
    return (
      <div className="relative">
        {!canVote && (
        <div className="absolute z-50 p-5 flex items-center content-center h-full w-full text-center text-lg font-bold">
          Det er krav til at stemmegivning gjøres kronologisk etter sakslisten.
          For å avgi stemme på denne saken, må du ha avgitt stemme på tidligere saker.
        </div>
        )}
        <div className={`${!canVote ? 'opacity-20 pointer-events-none' : ''}`}>
          <ElectionVoting
            meetingId={meetingId}
            onVoted={onVoted}
            section={section}
            propertyId={propertyId}
          />
        </div>
      </div>
    );
  }

  // If there is a normal voting
  return (
    <div className="relative">
      {!canVote && (
        <div className="absolute z-50 p-5 flex items-center content-center h-full w-full text-center text-lg font-bold">
          Det er krav til at stemmegivning gjøres kronologisk etter sakslisten.
          For å avgi stemme på denne saken, må du ha avgitt stemme på tidligere saker.
        </div>
      )}
      <div className={`${!canVote ? 'opacity-20 pointer-events-none' : ''} relative`}>
        <NormalVoting
          section={section}
          voted={voted}
          onVoted={onVoted}
          meetingId={meetingId}
          propertyId={propertyId}
        />
      </div>
    </div>
  );
};

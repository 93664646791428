export const articleQuery: any = (id: string, articlesKey: string) => {
  return `
    'articles':*[_type == "sectionArticles" && _id == "${id}"][0].${articlesKey}[]->{
        ...,
        "image": select(
          defined(image) => image,
          defined(figure) => figure,
          defined(images[0]) => images[0],
          defined(bannerImage) => bannerImage
        ),
      }`;
};

const searchMatchExpressions = [
  'title',
  'intro',
  'description',
  'body[].children[].text',
];

const searchTypes = [
  {
    document: 'benefit-benefit',
    category: 'Medlemsfordel',
    summary: 'description',
  },
  {
    document: 'article',
    category: 'Artikkel',
    summary: 'intro',
  },
];

// :: Search
// Search should include pages that are part of min side which includes: articles, benefits and min side pages.
// We search a set of fields on articles and benefits which is defined in <searchMatchExpressions>
// like title, ingress (intro or descrption) and text.
// For min side pages we search the field <search.words>
// We include title, url, preview text and image from sanity for min side pages.
// For articles and benefits we include slug aswell to create a dynamic url for those pages,
// like /medlemskap/fordeler/<slug> and /artikkel/<slug>
export const previewSearchResultsQuery: any = (searchList: string[]) => {
  return `
    *[((_type == 'article' || _type == 'benefit-benefit') && (${searchMatchExpressions
    .map((expression) => {
      return searchList.map((text) => `${expression} match '*${text}*' || searchWords match '*${text}*'`).join(' ||');
    })
    .join(' ||')})) ||
      ((
      _type == 'housingOverviewPage' ||
      _type == 'housingAssociationPage' ||
      _type == 'jointCostsPage' ||
      _type == 'rentalPage' ||
      _type == 'contactBoardPage' ||
      _type == 'benefitsPage' ||
      _type == 'bonusPage'
    ) &&
    ${searchList.map((searchWord) => `search.words match '*${searchWord}*'`)
    .join(' ||')})
    ] | order(_updatedAt desc) {
      _id,
      title,
      'category': 'Side',
      'url': pageUrl,
      'previewText': search.previewText,
      'previewImage': search.previewImage,
      ${searchTypes.map((item) => {
    return `_type == '${item.document}' => {
          _id,
          _type,
          title,
          'category': '${item.category}',
          'previewText': ${item.summary},
          'previewImage': figure,
          'slug': slug.current,
          }`;
  })}
    }[defined(title)]
  `;
};

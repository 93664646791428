export const formatDate = (date?: Date | string | null, clock = false): string => {
  if (!date) {
    return '';
  }
  const formatedDate = new Intl.DateTimeFormat('nb-NO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(date));

  if (clock) {
    const time = new Intl.DateTimeFormat('nb-NO', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));

    return `${formatedDate} kl. ${time}`;
  }

  return formatedDate;
};

export const timeUntil = (date: Date, allowNegative = false): {days: number, hours: number, minutes: number } => {
  // eslint-disable-next-line no-param-reassign
  date = new Date(date); // ensure we have a Date object
  const now = new Date();
  const diff = (date.getTime() - now.getTime()) / 1000;
  let days = Math.floor(diff / 86400);
  let hours = Math.floor(diff / 3600) % 24;
  let minutes = Math.floor(diff / 60) % 60;

  // Ensure days hours and minutes are always positive or 0
  if (!allowNegative) {
    if (days < 0) {
      days = 0;
    }
    if (hours < 0) {
      hours = 0;
    }
    if (minutes < 0) {
      minutes = 0;
    }
  }

  return { days, hours, minutes };
};

export const formatDateAndMonth = (date?: string | Date): string => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('nb-NO', {
    month: 'long',
    day: 'numeric',
  }).format(new Date(date));
};

import React, { useEffect } from 'react';

type Props = {
    data: {name: string, value: number, stringVal?: string }[],
    onSelect: (selected: {name: string, value: number, stringVal?: string}) => void,
    width?: string
}

const Selector: React.FC<Props> = ({ data = [], onSelect, width = '100%' }) => {
  const [selected, setSelected] = React.useState(0);

  useEffect(() => {
    onSelect(data[selected]);
  }, [data, onSelect, selected, setSelected]);

  return (
    <div className="select-filter" style={{ width }}>
      <select
        className="p-2"
        onChange={(e) => {
          setSelected(e.target.value as unknown as number);
        }}
        value={selected}
      >
        {data.map((value, index) => (
          <option key={value.name} value={index}>
            {value.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Selector;

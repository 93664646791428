/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { FitMode } from '@sanity/image-url/lib/types/types';
// eslint-disable-next-line import/no-cycle
import { urlFor, shouldDisplayImage } from '../../../utils/sanity.jsx';
import { Image } from '../../types/sanity';
import styles from './Figure.module.scss';

interface Props {
  image: Image;
  width?: number;
  height?: number;
  fitMode?: FitMode;
  noMargin?: boolean;
  hideCaption?: boolean;
  imgClass?: string;
  hideCredit?: boolean;
  figureClass?: string;
}

const Figure: React.FC<Props> = ({
  image,
  width,
  height,
  fitMode = 'fill',
  noMargin = false,
  hideCaption = false,
  imgClass = 'max-w-full',
  hideCredit = false,
  figureClass = '',
}) => {
  if (!image) return null;
  if (!shouldDisplayImage(image)) return null;

  let builder = urlFor(image).fit(fitMode).auto('format');

  if (height) {
    builder = builder.height(height);
  }

  if (width) {
    builder = builder.width(width);
  }

  const imgUrl = builder.url();

  return (
    <figure className={`${styles.figure} ${noMargin ? styles.noMargin : ''} ${figureClass}`}>
      <img className={imgClass} src={imgUrl || undefined} alt={image.alt} />
      {!hideCaption && (image.caption || image.credit) && (
        <figcaption
          className={`${styles.figcaption} hidden lg:block text-right pt-1 pr-5 text-xs`}
        >
          {image.caption}
          {image.credit && !hideCredit && <div>Foto: {image.credit}</div>}
        </figcaption>
      )}
    </figure>
  );
};

Figure.defaultProps = {
  width: undefined,
  height: undefined,
  fitMode: 'fill',
  noMargin: false,
  hideCaption: false,
  imgClass: 'max-w-full',
  hideCredit: false,
  figureClass: '',
};

export default Figure;

import { lazy } from 'react';
import { AppRouteProps } from '../components/AppRoute';
import { RouteSection } from '../common/utils/enums';
import { DigitalMeetingPage } from '../pages/DigitalMeeting';
import { ActiveMeetingpage } from '../pages/DigitalMeeting/ActiveMeeting';

// Lazyimports to chunk up solution
const Login = lazy(() => import('../pages/Login'));
const Overview = lazy(() => import('../pages/Overview'));
const MembershipBonus = lazy(() => import('../pages/Membership/bonus'));
const Benfits = lazy(() => import('../pages/Membership/Benefits'));
const BenefitsDetails = lazy(() => import('../pages/Membership/Benefits/Details'));
const Search = lazy(() => import('../pages/Search'));
const NoticeBoard = lazy(() => import('../pages/NoticeBoard'));
const JointCostsIndex = lazy(() => import('../pages/Housing/JointCost'));
const OverviewResidence = lazy(() => import('../pages/Housing'));
const Profile = lazy(() => import('../pages/Profile'));
const EventsIndex = lazy(() => import('../pages/Housing/Events'));
const EventDetails = lazy(() => import('../pages/Housing/Events/EventDetails'));
const GuidanceDetails = lazy(() => import('../pages/Housing/Events/GuidanceDetails'));
const ContactOverview = lazy(() => import('../pages/Housing/ContactOverview'));
const MyHousingAssociationPage = lazy(() => import('../pages/Housing/MyHousingAssociation'));
const ArticlePage = lazy(() => import('../pages/Article/ArticlePage'));
const Rental = lazy(() => import('../pages/Housing/Rental'));
const Notice = lazy(() => import('../pages/NoticeBoard/Notice'));
const InfoApp = lazy(() => import('../pages/InfoApp'));
const ErrorPage = lazy(() => import('../pages/Error'));

// Top-down priority: Uses the first path that matches, so more detailed paths should be put on the top.
const routes: AppRouteProps[] = [
  {
    path: '/profil',
    component: Profile,
    isPrivate: true,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/oversikt',
    component: Overview,
    isPrivate: true,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/bonus',
    component: MembershipBonus,
    isPrivate: true,
    section: RouteSection.Member,
  },
  {
    path: '/medlemsfordeler/:slug',
    component: BenefitsDetails,
    isPrivate: true,
    section: RouteSection.Member,
  },
  {
    path: '/medlemsfordeler',
    component: Benfits,
    isPrivate: true,
    section: RouteSection.Member,
  },
  {
    path: '/bolig/felleskostnader',
    component: JointCostsIndex,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/kurs/veiledning/:slug',
    component: GuidanceDetails,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/kurs/:slug',
    component: EventDetails,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/kurs',
    component: EventsIndex,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/kontakt-styret',
    component: ContactOverview,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/borettslag',
    component: MyHousingAssociationPage,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  // Digital meeting pages
  {
    path: '/bolig/arsmote/:meetingId/:propertyId',
    component: ActiveMeetingpage,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/arsmote/:meetingId',
    component: ActiveMeetingpage,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/arsmote/:meetingId/meld-sak',
    component: ActiveMeetingpage,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/arsmote',
    component: DigitalMeetingPage,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig/utleie',
    component: Rental,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/bolig',
    component: OverviewResidence,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/oppslagstavle/publikasjon/:slug',
    component: Notice,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/oppslagstavle/:slug',
    component: Notice,
    isPrivate: true,
    section: RouteSection.Residence,
  },
  {
    path: '/oppslagstavle',
    component: NoticeBoard,
    isPrivate: true,
    section: RouteSection.All,
  },
  {
    path: '/artikkel/:slug',
    component: ArticlePage,
    isPrivate: true,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/sok',
    component: Search,
    isPrivate: true,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/info-app',
    component: InfoApp,
    isPrivate: false,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/error',
    component: ErrorPage,
    isPrivate: false,
    section: RouteSection.Unrestricted,
  },
  {
    path: '/',
    component: Login,
    isPrivate: false,
    section: RouteSection.Unrestricted,
  },
];

export default routes;

import React, {
  forwardRef, useEffect, useState, useImperativeHandle,
} from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { useWindowSize } from '../../../utils/hooks';

type Props = {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  onChange?: (isOpen: boolean) => void;
  disableAnimation?: boolean;
  bottomRightComponent?: React.ReactNode;
  disableToggle?: boolean;
}

export type AccordionHandle = {
  close: () => void,
  resetMaxHeight: () => void
}

const Accordion: React.ForwardRefRenderFunction<AccordionHandle, Props> = ({
  children, header, subHeader, onChange, disableAnimation = false, bottomRightComponent, disableToggle = false,
}, forwardedRef) => {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const ref = React.createRef<HTMLDivElement>();

  const winndowSize = useWindowSize();

  useImperativeHandle(forwardedRef, () => ({
    close: () => setOpen(false),
    resetMaxHeight: () => setMaxHeight(ref.current?.scrollHeight || 0),
  }));

  useEffect(() => {
    onChange?.(open);
  }, [onChange, open]);

  // Use to transition height of accordion
  useEffect(() => {
    setMaxHeight(ref.current?.scrollHeight || 0);
  }, [ref, winndowSize]);

  return (
    <div
      className="bg-white rounded-bate mt-5 relative w-full shadow-lg px-5 sm:px-7 py-8 lg:p-10"
    >
      <button
        className="w-full text-left"
        onClick={!disableToggle ? () => setOpen(!open) : undefined}
        type="button"
      >
        {header}
        {subHeader}

        {/* Open/Close indicator */}
        { !disableToggle && (
          <div
            className="openIndicator absolute top-7 right-8 lg:right-10
            rounded-tr transition-all duration-500 text-bate-red"
          >
            <BsChevronUp
              size={30}
              className="duration-500 transform"
              style={{
                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                color: 'text-bate-red',
              }}
            />
          </div>
        )}
        {/* BottomRight on closed state */}
        <div
          className={`absolute bottom-8 right-8 md:bottom-auto md:top-8 md:right-18 lg:right-20
         text-bate-red transition-all
        duration-500 ${open ? 'opacity-0 md:opacity-100' : ' opacity-100'}`}
        >
          {bottomRightComponent}
        </div>
      </button>

      {(!disableAnimation || open) && (
        <div
          ref={ref}
          className={`transition-all duration-500 overflow-hidden ${open ? 'mt-5' : 'not-sr'}`}
          style={{
            // eslint-disable-next-line no-nested-ternary
            maxHeight: disableAnimation ? 'none' : (open ? `${maxHeight}px` : '0px'),
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default forwardRef(Accordion);

import React, { useCallback } from 'react';
import { ImArrowDown2 } from 'react-icons/im';
import { get } from '../../../api';

type Props = {
    // attachment: ISectionAttachmentModel;
    // meetingId: string;
    filename: string;
    path: string;
    name: string;
}

export const MeetingAttachment: React.FC<Props> = ({ filename, path, name }) => {
  const downloadAttachment = useCallback(
    async () => {
      const file = await get<{fileContents: string}>(path);
      const binary = atob(file.fileContents.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i += 1) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: 'application/pdf' });
      const a = document.createElement('a');
      const blobURL = URL.createObjectURL(blob);
      a.download = filename;
      a.href = blobURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    [filename, path],
  );

  return (
    <div className="flex flex-wrap justify-between">
      <div className="font-bold">{name}</div>
      <button
        onClick={downloadAttachment}
        className="flex items-center border-b border-dark hover:font-bold btn-animate-arrow"
        type="button"
      >
        <ImArrowDown2 className="mr-2 bounce" />
        <div>
          Last ned
        </div>
      </button>
    </div>
  );
};

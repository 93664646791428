import React from 'react';
import { MdClose } from 'react-icons/md';

type Props = {
    onClick: () => void;
    color? : string;
    iconColor? : string;
}

const CloseButton: React.FC<Props> = ({ onClick, color = 'bg-white', iconColor = 'text-dark' }) => {
  return (
    <button
      type="button"
      className={`${color} rounded-full`}
      aria-label="Close message"
      onClick={onClick}
    >
      <MdClose className={`text-4xl ${iconColor}`} />
    </button>
  );
};
export default CloseButton;

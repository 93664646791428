import React, {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Modules/Loader';

type LoadingContextType = {
   loadingSet: {[key: string]: Set<string>};
    loading: boolean;
    setLoading: (id: string, state: boolean) => void;
    clearLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType>({
  loadingSet: {},
  loading: false,
  setLoading: () => {
    // eslint-disable-next-line no-console
    console.error('setLoading not initiated yet');
  },
  clearLoading: () => {
    // eslint-disable-next-line no-console
    console.error('clearLoading not initiated yet');
  },
});

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingSet, setLoadingSet] = useState<{[key: string]: Set<string>}>({});

  const location = useLocation();

  // When the location changes, clear the loading state.
  useEffect(() => {
    Object.keys(loadingSet).forEach((key) => {
      if (key !== location.key) {
        delete loadingSet[key];
      }
    });
  }, [location]);

  // Clear loading state when navigating away from page
  const clearLoading = (): void => {
    setLoadingSet({});
  };

  const setLoading = (id: string, state: boolean): void => {
    const key = location?.key as string;
    if (!loadingSet[key]) {
      loadingSet[key] = new Set();
    }

    if (state) {
      loadingSet[key].add(id);
    } else {
      loadingSet[key].delete(id);
    }

    if (loadingSet[key].size === 0) {
      setLoadingState(false);
    } else {
      setLoadingState(true);
    }
  };

  const state = useMemo(() => {
    return {
      loading,
      setLoading,
      clearLoading,
      loadingSet,
    };
  }, [loading, loadingSet]);

  return (
    <LoadingContext.Provider value={state}>
      {loading ? <Loader /> : null}
      {children}
    </LoadingContext.Provider>
  );
};

type LoadingContextConsumerType = {
    loading: boolean;
    id: string;
    setLoading: (state: boolean) => void;
    clearLoading: () => void;
}

export const useLoading = (): LoadingContextConsumerType => {
  // generate random id for this hook call
  const id = useMemo(() => Math.random().toString(), []);

  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  const { loading, clearLoading, setLoading: setLoadingContext } = context;

  const setLoading = (state: boolean): void => {
    setLoadingContext(id, state);
  };

  return {
    loading, clearLoading, setLoading, id,
  };
};

export const useForegroundLoader = (loadingDependencies: boolean[]): { loading: boolean; id: string; } => {
  const { setLoading, loading, id } = useLoading();

  useEffect(() => {
    // If any of the dependencies are loading, set the app to loading
    if (loadingDependencies.some((dependency) => dependency)) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    return () => setLoading(false);
  // Makes no sence to update one setLoading dependency
  }, [...loadingDependencies]);

  return { loading, id };
};

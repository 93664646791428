import React from 'react';
import { BiCheck, BiMinus } from 'react-icons/bi';
import { DigitalVotingTypeEnum, IMeetingSectionModel } from '../../../types/digitalMeetings';

type Props = {
    section : IMeetingSectionModel;
    hasVoted : boolean;
}

export const VotingStatus: React.FC<Props> = ({ section, hasVoted }) => {
  if (section.votingOptions === DigitalVotingTypeEnum.IngenStemming) {
    return null;
  }

  return (
    <div className="flex items-center mt-5">
      {hasVoted ? <BiCheck size={25} color="green" /> : <BiMinus size={25} color="red" />}
      <span className="pl-2">
        Stemme
        {' '}
        {hasVoted ? '' : 'ikke'}
        {' '}
        avgitt
      </span>
    </div>
  );
};

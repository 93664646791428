import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import { AppWrapper } from './context/AppContext';

import { msalInstance } from './msal';

const AppProvider: React.FunctionComponent = () => (
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppWrapper>
        <App />
      </AppWrapper>
    </MsalProvider>
  </React.StrictMode>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'));

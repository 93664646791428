import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import { formatDate } from '../../../utils/date';
import { useInterval } from '../../../utils/hooks';
import { CasesList } from '../Components/CasesList';
import { Deadline } from '../Components/Deadline';
import { MeetingAttachment } from '../Components/MeetingAttachment';
import { MyCases } from '../Components/MyCases';
import { PhaseHeader } from '../Components/PhaseHeader';

export const ActiveMeetingpage: React.FC = () => {
  const {
    activeMeetings, activeSubmittalMeetings, physicalMeetings, refetchActiveMeetings,
  } = useAppContext();
  const { meetingId } = useParams<{ meetingId: string, propertyId: string }>();

  useInterval(refetchActiveMeetings, 1000 * 60 * 10);

  const activeMeeting = useMemo(() => {
    return activeMeetings?.find((m) => m.id === meetingId);
  }, [activeMeetings, meetingId]);

  const submittalMeeting = useMemo(() => {
    return activeSubmittalMeetings?.find((m) => m.id === meetingId);
  }, [activeSubmittalMeetings, meetingId]);

  const physicalMeeting = useMemo(() => {
    return physicalMeetings?.find((m) => m.id === meetingId);
  }, [physicalMeetings, meetingId]);

  if (activeMeeting) {
    return (
      <CasesList meeting={activeMeeting} />
    );
  }
  if (submittalMeeting) {
    return (
      <>
        <div className="bg-light-blue w-full mt-12 md:mt-20">
          <div className="bate-container">
            <PhaseHeader
              title={submittalMeeting ? submittalMeeting?.title : 'Kunne ikke finne møte'}
              pretitle="Innmeldingsfase"
              subtitle={submittalMeeting ? `for ${submittalMeeting?.clientName}` : ''}
            />
          </div>
        </div>
        <div className="bg-light-blue pb-8 md:pb-28 md:-mb-24 w-full flex-grow">
          <div className="bate-container">
            {submittalMeeting && <MyCases meeting={submittalMeeting} />}
          </div>
        </div>
      </>
    );
  }
  if (physicalMeeting) {
    return (
      <div className="bg-light-blue w-full mt-12 md:mt-20">
        <div className="bate-container">
          <PhaseHeader
            title={physicalMeeting ? physicalMeeting?.meetingTitle : 'Kunne ikke finne møte'}
            pretitle=""
            subtitle={physicalMeeting ? `for ${physicalMeeting?.clientName}` : ''}
            proxy={physicalMeeting?.proxyOwnerName}
          />
          <Deadline title="Møtet holdes" dueDate={physicalMeeting.meetingDate} />

          <div className="bg-white rounded-bate relative w-full shadow-lg px-7 py-8 lg:p-10 my-6">
            <div>
              {physicalMeeting.meetingTitle}
              {' '}
              i
              {' '}
              {physicalMeeting.clientName}
              {' '}
              avholdes
              {' '}
              {formatDate(physicalMeeting.meetingDate, true)}
              . Frem til møtet begynner kan du gjøre deg kjent med sakene som skal behandles.
            </div>
            <div className="mt-5">
              <MeetingAttachment
                filename="Møteinnkalling.pdf"
                name="Møteinnkalling"
                path={`/digitalmeetings/${physicalMeeting.id}/attachment/notice`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    null
  );
};

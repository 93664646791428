import React from 'react';
import { BsChevronLeft, BsChevronRight, BsCircleFill } from 'react-icons/bs';
import { sortBy } from 'lodash';
import { Image } from '../types/sanity';
import { Event } from '../types/event';
import { PageSectionSize } from './enums';
import { BoxSize } from '../../types/enums';
import { SearchResultModel } from '../../types/sanity/types';

export const shouldDisplayImage : (image: Image | undefined) => boolean = (image) => {
  // eslint-disable-next-line no-unneeded-ternary
  return image && image.asset ? true : false;
};

export const PrevArrow: (props: { className?: any; onClick?: any; }) => any = (props) => {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      aria-label="Naviger til forrige element"
      className={`${className}`}
      onClick={onClick}
    >
      <BsChevronLeft />
    </button>
  );
};

export const NextArrow: (props: { className?: any; onClick?: any; }) => any = (props) => {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      aria-label="Naviger til neste element"
      className={`${className}`}
      onClick={onClick}
    >
      <BsChevronRight />
    </button>
  );
};

export const getStartOfToday: () => Date = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return today;
};

export const getCourseTimeTexts:(event: Event) => {dateText: number, monthText: string, timeText: string, dueDate: string | undefined} | null = (event) => {
  if (event.startTime) {
    const startTimeDate = new Date(event.startTime);
    const dateText = startTimeDate.getDate();
    const monthText = startTimeDate.toLocaleString('no-nb', {
      month: 'long',
    });
    const timeText = startTimeDate.toLocaleTimeString('no-nb', {
      hour: '2-digit',
      minute: '2-digit',
    });

    let dueDate;
    if (event.signUpDate) {
      dueDate = new Date(event.signUpDate).toLocaleDateString('no-nb');
    }

    return {
      dateText, monthText, timeText, dueDate,
    };
  }

  return null;
};

export const getPageSectionCssClass = (pageSectionSize: PageSectionSize): string => {
  switch (pageSectionSize) {
    case PageSectionSize.Small:
      return 'md:col-span-6 lg:col-span-4';
    case PageSectionSize.Medium:
      return 'md:col-span-6';
    default:
      return 'md:col-span-12';
  }
};

export const getArticleBoxSize = (pageSectionSize: PageSectionSize): BoxSize => {
  switch (pageSectionSize) {
    case PageSectionSize.Small:
      return BoxSize.Small;
    case PageSectionSize.Medium:
      return BoxSize.Medium;
    default:
      return BoxSize.Large;
  }
};

export const getUrlWithProtocol = (url: string) : string => {
  if (url.startsWith('http') || url.startsWith('//')) {
    return url;
  }
  return `https://${url}`;
};

const prioritizedTypes = [
  'housingOverviewPage',
  'housingAssociationPage',
  'jointCostsPage',
  'rentalPage',
  'contactBoardPage',
  'benefitsPage',
  'bonusPage',
];

export const sortSearchResults = (items: SearchResultModel[], searchText: string[]): SearchResultModel[] => {
  return sortBy(items, [
    (item) => {
      let weight = 0;

      // Start with a bit higher value for prioritized types
      if (prioritizedTypes.includes(item._type)) {
        weight = 1;
      }

      for (let i = 0; i < searchText.length; i += 1) {
        const text = searchText[i];
        // Count number of matches in title and summary
        // Split will return (1 + number of matches) elements
        // Therefore we subtract 1, so score will increase by number of matches

        // Not the most performant solution, but we don't have to account for all
        // edge cases that there are with regex.
        // As long as we don't do this thousands of times, there is no noticeable difference.
        if (item.title) {
          weight
            += item.title.toLowerCase().split(text.toLowerCase()).length - 1;
        }
        if (item.previewText) {
          weight
            += item.previewText.toLowerCase().split(text.toLowerCase()).length - 1;
        }
      }

      // Final score is reversed, so high values go first "alphabetically"
      return weight * -1;
    },
  ]);
};

export const formatAccountNumber = (cardNumber: string): string => {
  if (!cardNumber) {
    return '';
  }
  return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 6)} ${cardNumber.slice(6, 11)}`;
};

export const getMaskedNumber = (lastFourDigits:string): any => {
  const sectionClass = 'flex mr-1.5 text-[0.375rem] items-center';
  const elementClass = 'mr-0.5 mb-1 ';

  const sectionArray = ['one', 'two', 'three'];

  return (
    <div className="flex items-center ml-1">
      { sectionArray.map((e:string) => (
        <div className={sectionClass} key={`cardSection${e}`}>
          <BsCircleFill className={elementClass} />
          <BsCircleFill className={elementClass} />
          <BsCircleFill className={elementClass} />
          <BsCircleFill className={elementClass} />
        </div>
      ))}
      <div className="font-clanot-black">
        { lastFourDigits }
      </div>
    </div>
  );
};

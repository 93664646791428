import React from 'react';

interface Props {
  bgColor?: string;
  className?: string;
}

const RoundedBox: React.FC<Props> = ({ children, bgColor, className }) => (
  <div className={`rounded-bate h-full px-7 py-8 lg:p-10 shadow-lg ${bgColor} ${className}`}>
    {children}
  </div>
);

RoundedBox.defaultProps = {
  bgColor: 'bg-white',
  className: '',
};

export default RoundedBox;

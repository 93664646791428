import { chain, groupBy } from 'lodash';
import React, { useMemo } from 'react';
import { useAppContext } from '../../../context/AppContext';
import { useInterval } from '../../../utils/hooks';
import { DigitalMeetingsBanner, MeetingType } from './DigitalMeetingsBanner';

type Props = {
  hideable?: boolean;
  clientId?: string;
}

export const DigitalMeetingBannerList: React.FC<Props> = ({ hideable, clientId }) => {
  const {
    activeSubmittalMeetings, activeMeetings, physicalMeetings, refetchActiveMeetings, refetchActiveSubmittalMeetings,
  } = useAppContext();

  useInterval(refetchActiveMeetings, 1000 * 60);
  useInterval(refetchActiveSubmittalMeetings, 1000 * 60);

  const filteredActiveMeetings = useMemo(() => {
    if (clientId) {
      return activeMeetings?.filter((meeting) => meeting.clientId === clientId) || [];
    }
    return activeMeetings;
  }, [activeMeetings, clientId]);

  const filteredActiveSubmittalMeetings = useMemo(() => {
    if (clientId) {
      return activeSubmittalMeetings?.filter((meeting) => meeting.clientId === clientId) || [];
    }
    return activeSubmittalMeetings;
  }, [activeSubmittalMeetings, clientId]);

  const filteredPhysicalMeetings = useMemo(() => {
    if (clientId) {
      return physicalMeetings?.filter((meeting) => meeting.clientId === clientId) || [];
    }
    return physicalMeetings;
  }, [physicalMeetings, clientId]);

  if ((filteredActiveSubmittalMeetings?.length || 0)
  + (filteredActiveMeetings?.length || 0)
  + (filteredPhysicalMeetings?.length || 0) > 0) {
    const meetingsById = chain(filteredActiveMeetings).groupBy((meeting) => meeting.clientId).map((value) => value).value();

    return (
      <div className="mb-16">
        { meetingsById && meetingsById.map((meetings, index) => {
          return (
            <DigitalMeetingsBanner
              key={meetings[0].id + index}
              meeting={meetings[0]}
              userMeetings={meetings}
              meetingType={MeetingType.Active}
              hideable={hideable}
            />
          );
        })}
        {filteredActiveSubmittalMeetings && filteredActiveSubmittalMeetings.map((meeting, index) => {
          return (
            <DigitalMeetingsBanner
              key={meeting.id + index}
              meeting={meeting}
              meetingType={MeetingType.Submittal}
              hideable={hideable}
            />
          );
        })}
        {filteredPhysicalMeetings && filteredPhysicalMeetings.map((meeting, index) => {
          return (
            <DigitalMeetingsBanner
              key={meeting.id + index}
              meeting={meeting}
              meetingType={MeetingType.Physical}
              hideable={hideable}
            />
          );
        })}
      </div>
    );
  }
  return null;
};

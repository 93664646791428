import { LinkButtonModel } from '../types/sanity';
import { InternalLinkType } from '../utils/enums';
import { getInternalLinkType, resolveDocumentUrl } from '../utils/resolvers';

const getLinkButtonModel: (node: any) => LinkButtonModel = (node) => {
  const internalLinkType = node?.internalLink ? getInternalLinkType(node?.internalLink) : InternalLinkType.NotSet;
  // :: Link button url
  // Use internal link when set, otherwise use external link
  // eslint-disable-next-line no-nested-ternary
  const url = node.internalLink
    ? resolveDocumentUrl(node.internalLink, node.internalLink.postfix, internalLinkType)
    : node.link
      ? node.link.href
      : '';
  const openInNewWindow = node.link && node.link.blank;

  const button: LinkButtonModel = {
    type: node.type,
    text: node.text,
    url,
    blank: openInNewWindow,
    isInternal: node.internalLink && node.internalLink.slug,
    internalLinkType,
  };
  return button;
};

export default getLinkButtonModel;

import React from 'react';

type Props = {
    checked: boolean | undefined | null;
    onClick: () => void;
    disabled? : boolean;
}

export const ElectionCheckbox: React.FC<Props> = ({
  children, checked, onClick, disabled,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`flex items-center ${disabled ? 'opacity-50' : ''}`}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Rectangle_9363" data-name="Rectangle 9363" fill="#fff" stroke="#DCE3EA" strokeWidth="2">
          <rect width="30" height="30" rx="3" stroke="none" />
          <rect x="1" y="1" width="28" height="28" rx="2" fill="none" />
        </g>
        {checked && (
        <path
          id="ic_check_24px"
          d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z"
          transform="translate(2.795 2.705)"
          fill="#1a7954"
        />
        )}
      </svg>
      {children}
    </button>
  );
};

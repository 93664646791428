import React from 'react';
import { ImArrowDown2, ImArrowRight2, ImArrowLeft2 } from 'react-icons/im';
import { Link } from 'react-router-dom';

export enum ArrowType {
  None,
  Right,
  Down,
  Left,
}
interface Props {
  className?: string;
  href?: string;
  hrefOpensNewWindow?: boolean;
  useLink?: boolean;
  onClick ?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean
  buttonRef?: React.RefObject<HTMLButtonElement>;
  anchorRef?: React.RefObject<HTMLAnchorElement>;
  whiteBackground?: boolean;
  small?: boolean;
  arrowType?: ArrowType;
  arrowClassName?: string;
  buttonClass?: string;
}

const BateButton: React.FC<Props> = ({
  children, className, href = '_', hrefOpensNewWindow, useLink, onClick, disabled, buttonRef, anchorRef,
  whiteBackground = false, small = false, arrowType = ArrowType.Right, arrowClassName, buttonClass,
}) => {
  const arrowStyle = arrowClassName || '';

  const protocolCheck = /^https?/;

  const isAbsoluteUrl = protocolCheck.test(href);

  const btnTypeClass = (): string => {
    switch (arrowType) {
      case ArrowType.Down:
        return 'btn-animate-arrow';
      case ArrowType.Right:
        return 'btn-animate-arrow-right';
      case ArrowType.Left:
        return 'btn-animate-arrow-left';
      default:
        return '';
    }
  };

  const arrowComp = (): JSX.Element | undefined => {
    switch (arrowType) {
      case ArrowType.Down:
        return <ImArrowDown2 className={`text-xl bounce flex-none ${arrowStyle}`} />;
      case ArrowType.Right:
        return <ImArrowRight2 className={`text-xl bounce flex-none ${arrowStyle}`} />;
      case ArrowType.Left:
        return <ImArrowLeft2 className={`text-xl bounce flex-none ml-4 ${arrowStyle}`} />;
      default:
        return undefined;
    }
  };

  if (useLink) {
    const classString = `
    btn
    text-center
    border-2
    rounded-bate
    inline-flex
    ${small ? 'px-4 py-2' : ''}
    ${whiteBackground ? 'btn-white' : 'btn-primary'}
    ${className}
    ${btnTypeClass()}
  `;

    const linkChildren = (
      <div className="flex gap-x-2 text-sm md:text-base font-clanot-black items-center">
        {children}
        {arrowComp()}
      </div>
    );

    if (isAbsoluteUrl) {
      return (
        <a
          href={href}
          target={hrefOpensNewWindow ? '_blank' : undefined}
          className={classString}
          rel="noreferrer"
        >
          {linkChildren}
        </a>
      );
    }

    return (
      <Link
        to={href}
        target={hrefOpensNewWindow ? '_blank' : undefined}
        className={classString}
        ref={anchorRef}
      >
        {linkChildren}
      </Link>
    );
  }

  return (
    <div className={`border-2 rounded-bate inline-flex ${whiteBackground ? 'bg-white' : ''} ${className}`}>
      <button
        className={
            ` text-center
              text-sm md:text-base
              ${small ? 'px-4 py-2' : 'px-8 py-5.5'}
              flex
              justify-between
              ${btnTypeClass()}
              w-full
              gap-x-9
              ${disabled ? 'opacity-50 cursor-default' : ''}
              font-clanot-black
              ${buttonClass}
            `
          }
        type="button"
        onClick={onClick || (() => null)}
        disabled={disabled}
        ref={buttonRef}
      >
        {children}
        { arrowType !== ArrowType.None && (
          <div className="my-auto">
            {arrowComp()}
          </div>
        )}
      </button>
    </div>
  );
};

BateButton.defaultProps = {
  className: '',
  useLink: false,
};

export default BateButton;

import { some } from 'lodash';
import { roleMap } from '../context/AppContext';
import { IRole } from '../types';

export const hasMemberRole: (roles: IRole[] | null) => boolean = (roles) => {
  return some(roles, (r) => r.role === roleMap.member);
};

export const hasResidenceOwnerRole: (roles: IRole[] | null) => boolean = (roles) => {
  return some(
    roles,
    (r) => (
      r.role === roleMap.owner
      || r.role === roleMap.residenceOwner
      || r.role === roleMap.residenceProxy
    ),
  );
};

export const hasBoardPortalRole: (roles: IRole[] | null) => boolean = (roles) => {
  return some(roles, (r) => r.role === roleMap.boardPortalUser || r.role === roleMap.boardPortalAdministrator);
};

export const hasMemberOrResidenceOwnerRole: (roles: IRole[] | null) => boolean = (roles) => {
  return (hasMemberRole(roles) || hasResidenceOwnerRole(roles)) ?? false;
};

export const hasMemberAndResidenceOwnerRoles: (roles: IRole[] | null) => boolean = (roles) => {
  return (hasMemberRole(roles) && hasResidenceOwnerRole(roles)) ?? false;
};

export const hasOnlyMemberRole: (roles: IRole[] | null) => boolean = (roles) => {
  return (hasMemberRole(roles) && !hasResidenceOwnerRole(roles)) ?? false;
};

export const hasOnlyResidenceOwnerRole: (roles: IRole[] | null) => boolean = (roles) => {
  return (hasResidenceOwnerRole(roles) && !hasMemberRole(roles)) ?? false;
};

export const hasResidenceProxyRoleForUnit: (
  roles: IRole[] | null, unitId: string | undefined,
) => boolean = (roles, unitId) => {
  return some(
    roles,
    (role) => {
      return (
        role.role === roleMap.residenceProxy
        && some(role.reference, (reference) => reference.type === 'UnitId' && reference.value === unitId)
      );
    },
  );
};

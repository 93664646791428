/* eslint-disable no-underscore-dangle */
import {
  Form, Formik, FormikHelpers,
} from 'formik';
import React, { useCallback, useState } from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { updateUser } from '../../../../api';
import Loader from '../../../../components/Modules/Loader';
import RouterPrompt from '../../../../components/Modules/RouterPromt';
import { useAppContext } from '../../../../context/AppContext';
import { IAddress, IUserDetails } from '../../../../types';
import FormField from '../../fields/FormField';
import ImagePicker from './ImagePicker';

interface Props {
  setImageRefresh: React.Dispatch<React.SetStateAction<number>>
  allowNonDirtySubmit?: boolean
  onUpdated?: () => void
  showEmail?: boolean
  hideHelpText?: boolean
  hideName?: boolean
}

const OmMeg: React.FC<Props> = ({
  setImageRefresh,
  allowNonDirtySubmit = false,
  onUpdated,
  showEmail = false,
  hideHelpText = false,
  hideName = false,
}) => {
  const { user, setUser } = useAppContext();
  const [showModal, setShowModal] = useState(false);

  const initialValues: IUserDetails = {
    ...user,
    // 'contactInfoConfirmed' updates 'contactInfoConfirmedDate' which we use to track
    //  whether or not we should nudge user about confirming/updating profile
    contactInfoConfirmed: true,
  };

  const handleSubmit = useCallback((
    values: IUserDetails,
    { setSubmitting, resetForm }: FormikHelpers<IUserDetails>,
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setSubmitting(true);
      const { address }: { address: IAddress } = (values as any);
      updateUser({
        ...values,
        city: address.city,
        zip: address.zip,
        addressLine1: address.line1,
      }).then(() => {
        setUser({ ...user, ...values });
        resetForm({ values });
        setSubmitting(false);
        if (onUpdated) onUpdated();
        resolve(true);
      }).catch(() => {
        alert('Feil ved oppdatering av bruker. Prøv igjen senere.');
        reject();
      });
    });
  }, [setUser, user]);

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({
          dirty, isSubmitting, values, ...eventHandlers
        }) => (
          <Form className="relative">
            {!hideHelpText
              && (
                <p className="!mb-7">
                  Her kan du endre kontaktinformasjonen din.
                </p>
              )}
            <RouterPrompt when={dirty} onConfirm={() => handleSubmit(values, eventHandlers)} />
            <div className="grid md:grid-cols-2 gap-x-10 gap-y-4 relative">

              {!hideName
                && (
                  <FormField
                    name="firstname"
                    label="Fornavn Mellomnavn"
                    placeholder={user.firstname}
                    labelClass="font-clanot-black"
                    disabled
                  />
                )}

              {!hideName
                && (
                  <FormField
                    name="surname"
                    label="Etternavn"
                    placeholder={user.surname}
                    labelClass="font-clanot-black"
                    disabled
                  />
                )}

              <FormField
                name="mobile"
                label="Telefon"
                placeholder={user.mobile}
                labelClass="font-clanot-black"
              />

              {showEmail
                && (
                  <FormField
                    name="email"
                    label="E-post (for info fra bate)"
                    placeholder={user.email}
                    labelClass="font-clanot-black"
                  />
                )}

              <FormField
                name="address.line1"
                label="Adresse"
                placeholder={user.address.line1}
                labelClass="font-clanot-black"
              />

              <FormField
                name="address.zip"
                label="Postnummer"
                placeholder={user.address.zip}
                labelClass="font-clanot-black"
              />

              <FormField
                name="address.city"
                label="Sted"
                placeholder={user.address.city}
                labelClass="font-clanot-black"
              />

            </div>
            <div className="flex flex-col w-full md:w-2/3 my-3 mt-5">
              <p className="font-clanot-black">Profilbilde</p>
              <div className="flex gap-5 mt-1 flex-row flex-wrap">
                <div className="flex-none rounded-full w-28 h-28 overflow-hidden">
                  <img
                    className="min-h-[7rem] min-w-auto"
                    src={`https://api.bbld.io/personimage/user/${user.id}/image/?${Date.now()}`}
                    alt="profilbilde"
                  />
                </div>
                <div className="flex flex-wrap items-center">
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="text-sm font-bold pb-2 border-b-2 flex items-center gap-6 border-black hover:text-bate-red"
                  >
                    <span className="text-left">Endre profilbilde</span>
                    <ImArrowRight2 />
                  </button>
                </div>
              </div>
            </div>
            {(dirty || allowNonDirtySubmit)
              && (
                <div className="py-10">
                  {isSubmitting
                    ? <Loader isSmall />
                    : (
                      <button
                        type="submit"
                        className="btn btn-primary rounded-bate"
                      >
                        {allowNonDirtySubmit ? 'Bekreft' : 'Lagre endringer'}
                      </button>
                    )}
                </div>
              )}
          </Form>
        )}
      </Formik>
      <ImagePicker showModal={showModal} setShowModal={setShowModal} setImageRefresh={setImageRefresh} />
    </>
  );
};

export default OmMeg;

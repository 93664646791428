import React from 'react';
import ReactPlayer from 'react-player';
import { VideoModel } from '../../types/sanity';
// eslint-disable-next-line import/no-cycle
import { urlFor } from '../../../utils/sanity.jsx';
import styles from './Video.module.scss';

const VideoPlayIcon: React.FC = () => {
  return (
    <div className="w-20 h-20">
      <svg
        id="ic_play_circle_outline_24px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 109.414 109.414"
      >
        <path
          id="ic_play_circle_outline_24px-2"
          data-name="ic_play_circle_outline_24px"
          d="M45.766,81.325,78.59,56.707,45.766,32.089ZM56.707,2a54.707,54.707,0,1,0,54.707,54.707A54.727,54.727,0,0,0,56.707,2Zm0,98.473a43.766,43.766,0,1,1,43.766-43.766A43.824,43.824,0,0,1,56.707,100.473Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

interface Props {
  video: VideoModel;
}

const Video: React.FC<Props> = ({ video }) => {
  if (!video?.url) return null;

  const imageUrl = !video.previewImage
    ? false
    : urlFor(video.previewImage).width(768).fit('crop').auto('format')
      .url();

  return (
    <div className={`my-8 ${styles.videoWrapper}`}>
      <ReactPlayer
        url={video.url}
        // We only want video to autoplay when a preview image is clicked
        playing={video.previewImage !== undefined}
        controls
        width="100%"
        height="100%"
        className={styles.video}
        // false will display the videoplayer without a preview image
        light={imageUrl || undefined}
        playIcon={<VideoPlayIcon />}
      />
    </div>
  );
};

export default Video;

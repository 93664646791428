/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import BateButton, { ArrowType } from '../Elements/BateButton';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
  },
};

interface Props {
    when: boolean;
    onConfirm: any;
}

const RouterPrompt: React.FC<Props> = ({
  when, onConfirm,
}) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [disrupt, setDisrupt] = useState(false);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        setDisrupt(!disrupt);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when, disrupt]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onConfirm]);

  const handleCancel = useCallback(async () => {
    history.block(() => {});
    history.push(currentPath);
  }, [currentPath, history]);

  const handleContinue = useCallback(async () => {
    history.block(() => {});
    setShowPrompt(false);
    setDisrupt(!disrupt);
  }, [currentPath, history]);

  return (
    <Modal
      isOpen={showPrompt}
      style={modalStyle}
      contentLabel="Forlat side uten å lagre endringer?"
      ariaHideApp={false}
    >
      <div className="p-5 flex flex-col space-y-10 items-center">
        <p className="text-2xl font-clanot-ultra">Du har gjort endringer</p>
        <p>Vil du lagre?</p>
        <div className="flex space-x-5">
          <BateButton arrowType={ArrowType.None} onClick={() => handleConfirm()} className="btn-secondary">Ja</BateButton>
          <BateButton whiteBackground arrowType={ArrowType.None} onClick={() => handleCancel()} className="btn-secondary">Nei</BateButton>
        </div>
        <button type="button" className="text-red-700 underline hover:text-black" onClick={() => handleContinue()}>Fortsett å redigere</button>
      </div>
    </Modal>
  );
};

export default RouterPrompt;

import React, { useCallback } from 'react';
import { ImArrowDown2 } from 'react-icons/im';
import { get } from '../../api';
import { BATE_RED_HEADING_STYLE } from '../../common/utils/constants';
import Greeting from '../../components/Modules/Greeting';
import ResidenceSelector from '../../components/Modules/Residence/ResidenceSelector';
import { useAppContext } from '../../context/AppContext';
import { HistoricalMeetingModel } from '../../types/digitalMeetings';
import { BateIcon } from '../../types/enums';
import { formatDate } from '../../utils/date';
import { useFetchDataWithDependenciesAndLoading } from '../../utils/hooks';
import { DigitalMeetingBannerList } from './Components/DigitalMeetingBannersList';

enum DocumentType {
  Protocol = 'protocol',
  Notice = 'notice'
}

const downloadDocument = async (meetingId: string, name: string, documentType: DocumentType): Promise<void> => {
  const file = await get<{fileContents: string}>(`/digitalmeetings/${meetingId}/attachment/${documentType}`);
  const binary = atob(file.fileContents.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: 'application/pdf' });
  const a = document.createElement('a');
  const blobURL = URL.createObjectURL(blob);
  a.download = name;
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const DigitalMeetingPage: React.FC = () => {
  const { currentResidence } = useAppContext();

  // Get the meeting details
  const fetchMeethingDetails = useCallback(() => {
    const details = get<HistoricalMeetingModel[]>(
      `/digitalmeetings/history/${currentResidence?.clientId}`,
    );
    return details;
  }, [currentResidence]);

  const { data: historicalMeetings } = useFetchDataWithDependenciesAndLoading(
    { fetch: fetchMeethingDetails, dependencies: [currentResidence] },
  );

  return (
    <>
      <div className="flex pb-8 w-full">
        <div className="bate-container">
          <div className="grid md:grid-cols-3 gap-4">
            <div className="md:my-10 lg:my-0 md:col-span-2">
              <Greeting
                icon={BateIcon.Hammer}
                title={currentResidence?.companyType === 'HousingAssociation' ? 'Generalforsamlinger' : 'Årsmøter'}
              />
            </div>
            <div className="flex self-center justify-center md:justify-end mt-4 flex-col">
              <ResidenceSelector simpleView />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8 md:pb-28 md:-mb-24 w-full">
        <div className="bate-container">
          <DigitalMeetingBannerList clientId={currentResidence?.clientId} />

          <div className="bg-light-blue mt-5 rounded-md">
            <h1 className={BATE_RED_HEADING_STYLE}>
              Tidligere
              {' '}
              {currentResidence?.companyType === 'HousingAssociation' ? 'generalforsamlinger' : 'årsmøter' }
            </h1>
            <div className="flex flex-col gap-6 mt-6">
              { historicalMeetings?.map((meeting) => {
                const documentName = `${meeting.name}-`
                  + `${currentResidence?.clientName}-`
                  + `${formatDate(meeting.fromDate)}.pdf`;

                return (
                  <div className="flex flex-col sm:flex-row gap-x-8 gap-y-2" key={meeting.id}>
                    <div className="flex items-center font-clanot-news">
                      {new Date(meeting.fromDate).getFullYear()}
                    </div>
                    <div
                      className="flex gap-4 flex-auto flex-wrap justify-between bg-white
                      border border-white px-7 md:px-10 rounded-bate py-5.5"
                    >
                      <div className="font-clanot-news">
                        {meeting.name}
                        {' '}
                        -
                        {' '}
                        {currentResidence?.clientName}
                      </div>
                      <div className="flex flex-col md:flex-row items-start mb-4 md:mb-0">
                        <button
                          onClick={() => downloadDocument(
                            meeting.id,
                            documentName,
                            DocumentType.Notice,
                          )}
                          className="flex items-center border-b border-dark hover:font-bold
                          btn-animate-arrow mb-4 md:mb-0 md:mr-4"
                          type="button"
                        >
                          <ImArrowDown2 className="mr-2 bounce" />
                          <div className="text-left">
                            Last ned møteinnkallelse
                          </div>
                        </button>
                        <button
                          onClick={() => downloadDocument(
                            meeting.id,
                            documentName,
                            DocumentType.Protocol,
                          )}
                          className="flex items-center border-b border-dark hover:font-bold btn-animate-arrow"
                          type="button"
                        >
                          <ImArrowDown2 className="mr-2 bounce" />
                          <div className="text-left">
                            Last ned protokoll
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {
          !historicalMeetings && (
            <div className="flex justify-center">
              <div className="text-lg">
                Ingen tidligere
                {' '}
                {currentResidence?.companyType === 'HousingAssociation' ? 'generalforsamlinger' : 'årsmøter' }
              </div>
            </div>
          )
        }
          </div>
        </div>
      </div>
    </>
  );
};

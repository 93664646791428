/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Field, FieldProps } from 'formik';

interface FormFieldProps {
  name: string;
  label: string;
  subLabel?: string;
  placeholder: string;
  labelClass?: string;
  wrapperClass?: string;
  validate?(value: any): string | undefined;
  disabled?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  subLabel,
  placeholder,
  labelClass,
  validate,
  wrapperClass,
  disabled = false,
}) => {
  return (
    <div className={`flex flex-col w-full ${wrapperClass}`}>
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
      {!!subLabel && (
        <p>
          {subLabel}
        </p>
      )}
      <Field
        id={name}
        name={name}
        validate={validate}
      >
        {({ field }: FieldProps) => (
          <input
            type="text"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            disabled={disabled}
            placeholder={placeholder}
            className={`pb-2 pr-3 border-b bg-light-blue bg-opacity-0 border-light-blue focus:outline-none ${
              disabled ? 'cursor-not-allowed opacity-30' : ''
            }`}
          />
        )}
      </Field>
    </div>
  );
};

export default FormField;

// /* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { PercentCrop } from 'react-image-crop';
import Modal from 'react-modal';
import { deletePersonImage, postPersonImage } from '../../../../api';
import BateButton, { ArrowType } from '../../../../components/Elements/BateButton';
import { IPersonImage } from '../../../../types';
import { getCroppedImg } from '../../../../utils/file-helpers';
import ImageCrop from './ImageCrop';

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setImageRefresh: React.Dispatch<React.SetStateAction<number>>;
}

const ImagePicker: React.FC<Props> = ({ showModal, setShowModal, setImageRefresh }) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
    },
  };

  const cropRef = useRef<any>(null);
  const [crop, setCrop] = useState<PercentCrop>();

  const handleImageSubmit = useCallback(async (values: IPersonImage, event: FormikHelpers<IPersonImage>) => {
    event.setSubmitting(true);

    if (values.file == null || crop == null) {
      alert('Whoops! Her skjedde det noe galt. Prøv igjen!');
      throw new Error('Missing file or crop');
    }

    let croppedImage;
    try {
      croppedImage = await getCroppedImg(URL.createObjectURL(values.file), crop, values.file.type) as File;
    } catch (err: any) {
      alert('Whoops! Her skjedde det noe galt. Prøv igjen!');
      return null;
    }

    const personImage: IPersonImage = { file: croppedImage };

    return new Promise((resolve, reject) => {
      postPersonImage(personImage, values.file?.name).then(() => {
        event.setSubmitting(false);
        event.resetForm();
        setShowModal(false);
        setImageRefresh(Date.now());
        resolve(true);
        setCrop(undefined);
      }).catch(() => {
        alert('Whoops! Her skjedde det noe galt, og vi kunne ikke lagre informasjonen din. Prøv igjen!');
        reject();
      });
    });
  }, [setImageRefresh, setShowModal, crop]);

  const removeImage = useCallback(() => {
    async function deleteTask():Promise<void> {
      try {
        await deletePersonImage();
        setImageRefresh(Date.now());
        setShowModal(false);
      } catch (err) {
        alert('Whoops! Her skjedde det noe galt. Prøv igjen!');
      }
    }
    deleteTask();
  }, [setImageRefresh, setShowModal]);

  const handleOnImageLoad = useCallback((image: React.SyntheticEvent<HTMLImageElement, Event>) => {
    cropRef.current = image.currentTarget;
  }, []);

  const initialValues:IPersonImage = {
    file: null,
  };

  return (
    <Modal
      isOpen={showModal}
      style={modalStyle}
      contentLabel="Endre profilbilde"
      ariaHideApp={false}
      onRequestClose={() => {
        setShowModal(false);
        setCrop(undefined);
      }}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <div className="p-5 flex flex-col space-y-5 items-center">
        <p className="text-2xl">Endre bilde</p>
        <Formik initialValues={initialValues} onSubmit={handleImageSubmit}>
          {({
            setFieldValue, values, resetForm,
          }) => (
            <Form>
              <div className="w-64 text-center flex flex-col items-center">
                {!values.file ? (
                  <div className="flex flex-col space-y-5">
                    <label className="btn btn-primary inline-flex text-center rounded-bate !mr-0 cursor-pointer">
                      <span className="text-base leading-normal">Last opp bilde</span>
                      <input
                        className="hidden"
                        type="file"
                        name="file"
                        onChange={(e) => e.target.files && setFieldValue('file', e.target.files[0])}
                      />
                    </label>
                    <button
                      type="button"
                      className="text-red-700 underline hover:text-black"
                      onClick={() => removeImage()}
                    >
                      Fjern bilde
                    </button>
                  </div>
                ) : (
                  <div>
                    <ImageCrop
                      src={URL.createObjectURL(values.file)}
                      onImageLoad={handleOnImageLoad}
                      onCropUpdated={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                      currentCrop={crop}
                    />
                    <div className="flex space-x-5 justify-evenly mt-5">
                      <BateButton
                        arrowType={ArrowType.None}
                        className="btn-white"
                        buttonClass=""
                        onClick={() => {
                          setCrop(undefined);
                          resetForm();
                        }}
                      >
                        Avbryt
                      </BateButton>
                      <button
                        type="submit"
                        className="btn btn-primary rounded-bate !m-0"
                      >
                        Lagre
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ImagePicker;

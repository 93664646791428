/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { RiFacebookBoxFill } from 'react-icons/ri';
import {
  FaTwitterSquare,
  FaLinkedin,
  FaInstagram,
  FaBell,
} from 'react-icons/fa';
import { getClient } from '../../../utils/sanity.jsx';
import { FooterModel } from '../../../types/sanity/footer';
import SimpleLinkItem from '../../Elements/SimpleLinkItem';
import ContactInfo from './ContactInfo';
import { useAppContext } from '../../../context/AppContext';
import { useHasMemberOrResidenceOwnerRole, useHasMemberRole, useHasResidenceOwnerRole } from '../../../utils/hooks';
import { SimpleLinkItemModel } from '../../../common/types/sanity';
import { LinkVisibility } from '../../../common/utils/enums';

const query = `*[_type == 'footerMyPage'][0] {
      ...,
      'customerService': *[_type == 'customerService'][0] {
        phoneNumber,
        email,
        openingDays,
        openingTime,
        closingTime,
      }
    }`;

const Footer: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [sanityData, setSanityData] = useState<FooterModel>();
  const { roles } = useAppContext();

  const isMember = useHasMemberRole(roles);
  const isResidenceOwner = useHasResidenceOwnerRole(roles);
  const isMemberOrResidenceOwner = useHasMemberOrResidenceOwnerRole(roles);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const data: FooterModel = await getClient().fetch(
        query,
      );

      setSanityData(data);
    }

    fetchData();
  }, []);

  if (!isAuthenticated || !sanityData) {
    return null;
  }

  const showLink = (link: SimpleLinkItemModel): boolean => {
    switch (link.visibility) {
      case LinkVisibility.Members:
        return isMember;
      case LinkVisibility.ResidenceOwners:
        return isResidenceOwner;
      case LinkVisibility.MembersAndResidenceOwners:
        return isMemberOrResidenceOwner;
      case LinkVisibility.Everyone:
        return true;
      default:
        return true;
    }
  };

  return (
    <footer
      className="bg-bate-red py-22 px-10 lg:px-0 text-white rounded-t-bate"
    >
      <div className="bate-container grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 gap-y-10 lg:gap-y-4">
        <div>
          <div className="text-lg mb-4 font-clanot-black">Mine sider</div>
          <ul className="font-clanot-news">
            {sanityData.myPagesLinks
            && sanityData.myPagesLinks.map((item, index) => (
              showLink(item) && item.url && (
                <li key={index} className="mb-3">
                  <SimpleLinkItem linkItem={item} />
                </li>
              )
            ))}
          </ul>
        </div>
        <div>
          <div className="text-lg mb-4 font-clanot-black">Snarveier</div>
          <ul className="font-clanot-news">
            {sanityData.shortcutLinks
            && sanityData.shortcutLinks.map((item, index) => (
              showLink(item) && item.url && (
              <li key={index} className="mb-3">
                <SimpleLinkItem linkItem={item} />
              </li>
              )
            ))}
          </ul>
        </div>
        <div>
          <div className="text-lg mb-4 font-clanot-black">Kontakt</div>
          <ContactInfo customerService={sanityData.customerService} contactLink={sanityData.contact?.contactLink} />
        </div>

        <div>
          <div className="text-lg mb-4 font-clanot-black">Følg Bate</div>
          <ul className="font-clanot-news">
            {sanityData.socialMediaLinks?.facebookLink?.url && showLink(sanityData.socialMediaLinks.facebookLink)
            && (
            <li className="mb-3">
              <SimpleLinkItem linkItem={sanityData.socialMediaLinks.facebookLink}>
                <RiFacebookBoxFill className="inline-block mr-2 text-2xl" />
              </SimpleLinkItem>
            </li>
            )}
            {sanityData.socialMediaLinks?.twitterLink?.url && showLink(sanityData.socialMediaLinks.twitterLink)
            && (
            <li className="mb-3">
              <SimpleLinkItem linkItem={sanityData.socialMediaLinks.twitterLink}>
                <FaTwitterSquare className="inline-block mr-2 text-2xl" />
              </SimpleLinkItem>
            </li>
            )}
            {sanityData.socialMediaLinks?.linkedInLink?.url && showLink(sanityData.socialMediaLinks.linkedInLink)
            && (
            <li className="mb-3">
              <SimpleLinkItem linkItem={sanityData.socialMediaLinks.linkedInLink}>
                <FaLinkedin className="inline-block mr-2 text-2xl" />
              </SimpleLinkItem>
            </li>
            )}
            {sanityData.socialMediaLinks?.instagramLink?.url && showLink(sanityData.socialMediaLinks.instagramLink)
            && (
            <li className="mb-3">
              <SimpleLinkItem linkItem={sanityData.socialMediaLinks.instagramLink}>
                <FaInstagram className="inline-block mr-2 text-2xl" />
              </SimpleLinkItem>
            </li>
            )}
            {sanityData.socialMediaLinks?.newsLetterLink?.url && showLink(sanityData.socialMediaLinks.newsLetterLink)
            && (
            <li className="mt-6">
              <SimpleLinkItem linkItem={sanityData.socialMediaLinks.newsLetterLink}>
                <FaBell className="inline-block mr-2 text-2xl" />
              </SimpleLinkItem>
            </li>
            )}
          </ul>
        </div>
      </div>

      <div className="bate-container mt-12">
        <div className="text-sm">
          <hr className="border-t-2 border-white" />
          <div className="flex flex-col lg:flex-row justify-evenly gap-y-4 mt-16 lg:text-center xl:w-10/12 mx-auto font-clanot-news">
            <div className="mb-3">
              <img
                src="/Bate_Logo_Hvit_ORIG.png"
                alt="Hvit Bate logo"
              />
            </div>

            {sanityData.contact?.organizationNumberLink && showLink(sanityData.contact?.organizationNumberLink)
            && (
            <div className="mb-3">
              <span className="mr-2">Orgnr.</span>
              <SimpleLinkItem linkItem={sanityData.contact.organizationNumberLink} />
            </div>
            )}

            {sanityData.privacyLinks
              && sanityData.privacyLinks.map((item, index) => (
                showLink(item) && item.url && (
                  <div key={index} className="mb-3">
                    <SimpleLinkItem linkItem={item} />
                  </div>
                )
              ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

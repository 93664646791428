import React,
{
  useCallback, useRef, useState,
} from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { get, post } from '../../../api';
import { formatDate } from '../../../utils/date';
import { useFetchDataWithDependenciesAndLoading } from '../../../utils/hooks';
import { Case } from './Case';
import BateButton from '../../../components/Elements/BateButton';
import RawMarkdownEditor, { EditorHandle } from './RawMarkdownEditor';
import { useForegroundLoader } from '../../../context/LoadingContext';
import Accordion, { AccordionHandle } from './Accordion';
import {
  ActiveSubmittalMeeting, ISuggestedSection,
} from '../../../types/digitalMeetings';

type Props = {
  meeting: ActiveSubmittalMeeting;
}

export const MyCases: React.FC<Props> = ({ meeting }) => {
  const [myCasesOpen, setMyCasesOpen] = useState(false);
  const [newCaseOpen, setNewCaseOpen] = useState(false);
  const [newCaseTitle, setNewCaseTitle] = useState('');
  const [newCaseDescription, setNewCaseDescription] = useState('');
  const [newCaseArgumentation, setNewCaseArgumentation] = useState('');
  const [error, setError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [postAttachments, setPostAttachments] = useState<File[]>([]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = ():void => {
    hiddenFileInput.current?.click();
  };

  const handleFilesChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const inputFiles = event.target.files;

    if (!inputFiles) return;

    const fileArray = Array.from(inputFiles);

    if (fileArray.some((f) => f.type !== 'application/pdf')) {
      setError('Kan ikke legge til andre filer enn PDF');
    }

    const filterPDFs:File[] = fileArray.filter((file) => file.type === 'application/pdf');

    setPostAttachments([...filterPDFs, ...postAttachments]);
  };

  const handleDeleteFile = (id:number):void => {
    const filteredList = postAttachments.filter((file: File, index) => index !== id);
    setPostAttachments(filteredList);
  };

  // New case form ref
  const newCaseFormRef = useRef<AccordionHandle>(null);

  useForegroundLoader([submitLoading]);

  const fetchMySuggestions = useCallback(() => {
    return get<ISuggestedSection[]>(`/digitalmeetings/${meeting.id}/MySuggestedSections`);
  }, [meeting]);

  const descriptionRef = useRef<EditorHandle>(null);
  const argumentationRef = useRef<EditorHandle>(null);

  const { data, setData } = useFetchDataWithDependenciesAndLoading(
    { fetch: fetchMySuggestions },
  );

  const submitNewCase = useCallback(async () => {
    // Check that the fields are filled out
    if (!newCaseTitle || !newCaseDescription || !newCaseArgumentation) {
      setError('Alle felt må fylles ut før skjemaet kan sendes inn*');
      return;
    }
    setError('');
    setSubmitLoading(true);
    // Submit

    try {
      const formData = new FormData();
      formData.append('title', newCaseTitle);
      formData.append('description', newCaseDescription);
      formData.append('suggestedDescision', newCaseArgumentation);
      postAttachments.forEach((file) => {
        formData.append('attachments', file);
      });

      await post(
        `/digitalmeetings/${meeting.id}/MySuggestedSections`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      const newData = await get<ISuggestedSection[]>(`/digitalmeetings/${meeting.id}/MySuggestedSections`);
      setData(newData);
      setSuccess(true);

      // Close the form
      newCaseFormRef.current?.close();

      // reset the form
      setNewCaseTitle('');
      setNewCaseDescription('');
      setNewCaseArgumentation('');
      setPostAttachments([]);
      descriptionRef.current?.clear();
      argumentationRef.current?.clear();
    } catch {
      setError('Noe gikk galt, vennligst forsøk igjen.');
    } finally {
      setSubmitLoading(false);
    }
  }, [meeting.id, newCaseArgumentation, newCaseDescription, newCaseTitle, postAttachments, setData]);

  return (
    <>
      {/* First secttion */}
      <Accordion
        onChange={(status) => setMyCasesOpen(!status)}
        header={(
          <h1 className="heading-underline text-lg font-clanot-news">Mine meldte saker</h1>
        )}
        subHeader={myCasesOpen && (
          <div className="flex">
            <div
              className="mt-5 py-1"
            >
              {data?.length}
              {' '}
              { data?.length && data?.length === 1 ? 'sak' : 'saker'}
            </div>
          </div>
        )}
        disableAnimation
      >
        <div className="mb-5">
          {data?.map((section) => (
            <Case section={section} key={section.id} meetingId={meeting.id} />
          ))}
        </div>
      </Accordion>

      {/* Second section */}
      <Accordion
        header={<h1 className="heading-underline text-lg mb-5 font-clanot-news">Meld inn sak</h1>}
        onChange={(status) => setNewCaseOpen(status)}
        ref={newCaseFormRef}
        subHeader={!newCaseOpen && (
          <div>
            <div className="text-sm">
              {`Du kan nå melde inn sak til ${meeting.title.toLowerCase()} i ${meeting.clientName}. `}
              Frist for innmelding er
              {' '}
              <b>
                {formatDate(meeting.sectionSubmittalDeadline)}
                {' '}
              </b>
            </div>
            {success && (
              <div className="text-sm text-lime-700 font-bold mt-2">
                Ny sak ble meldt inn
              </div>
            )}
          </div>
        )}
      >
        <div>
          <div className="mb-4 font-clanot-news">
            Tittel
          </div>
          <input
            className="appearance-none border rounded w-full py-2 px-3 "
            id="title"
            type="text"
            placeholder="Skriv inn.."
            value={newCaseTitle}
            onChange={(e) => setNewCaseTitle(e.target.value)}
          />

          <div className="mt-10 mb-5 font-clanot-news">
            Beskrivelse
          </div>
          <RawMarkdownEditor
            onChange={(rawHtml) => { setNewCaseDescription(rawHtml); }}
            ref={descriptionRef}
          />

          <div className="mt-10 mb-5 font-clanot-news">
            Forslag til vedtak
          </div>

          <RawMarkdownEditor
            onChange={(rawHtml) => { setNewCaseArgumentation(rawHtml); }}
            ref={argumentationRef}
          />

          <div className="mt-10 mb-5 font-clanot-news">
            Last opp vedlegg
          </div>

          <button
            type="button"
            className="btn btn-secondary rounded-bate"
            onClick={handleClick}
          >
            Velg fil
          </button>
          <input
            type="file"
            className="hidden"
            onChange={handleFilesChange}
            multiple
            ref={hiddenFileInput}
            accept="application/pdf"
          />
          <p className="mt-3 text-sm">Du kan kun laste opp pdf-filer</p>

          { postAttachments && postAttachments.length > 0 && (
            <ul className="mt-6">
              {postAttachments.map((file, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={file.lastModified + i} className="flex items-center">
                  {file.name}
                  <button type="button" className="ml-4" onClick={() => handleDeleteFile(i)}>
                    <IoCloseOutline color="#dc151d" fontSize="2rem" />
                  </button>
                </li>
              ))}
            </ul>
          )}

          <div className="flex justify-between items-center mt-10 mb-5 pr-5">
            <p className="text-sm text-red-500 font-bold">{error}</p>
            <BateButton className="btn-primary" onClick={submitNewCase}>
              Meld inn sak
            </BateButton>
          </div>
        </div>
      </Accordion>
    </>
  );
};

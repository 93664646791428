import React from 'react';

interface Props {
  isSmall?: boolean;
}

const Loader: React.FC<Props> = ({ isSmall }) => {
  if (isSmall) {
    return (
      <div className="w-full h-full overflow-auto">
        <div
          className="w-8 h-8 bg-cover ml-auto mr-auto animate-spin-pulse"
          style={{ backgroundImage: 'url(/loader.png)' }}
        />
      </div>
    );
  }
  return (
    <div className="fixed bg-white bg-opacity-50 z-50 inset-0 w-full h-full overflow-auto">
      <div
        className="w-36 h-36 bg-cover absolute top-4/10 left-0 right-0 ml-auto mr-auto animate-spin-pulse"
        style={{ backgroundImage: 'url(/loader.png)' }}
      />
    </div>
  );
};

export default Loader;

import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { DigitalCastVoteEnum, DigitalVotingTypeEnum } from '../../../types/digitalMeetings';
import { SectionNode } from '../Components/CasesList';

type HasVotedContextProp = {
    sections: SectionNode[]
 }

 type HasVotedContextType = {
    hasVoted: {sectionId: string, voted: boolean}[]
    setVoted: (sectionId: string) => void
 }

const HasVotedContext = createContext<HasVotedContextType>({
  hasVoted: [],
  setVoted: () => { console.log('Set voted function not initiated yet'); },
});

export const HasVotedContextProvider: React.FC<HasVotedContextProp> = ({ sections, children }) => {
  const [votedArray, setVotedArray] = useState<{sectionId: string, voted: boolean}[]>([]);

  useEffect(() => {
    const hasVoted: {sectionId: string, voted: boolean}[] = [];

    for (let i = 0; i < sections.length; i += 1) {
      const section = sections[i];
      // No voting to be done
      if (section.votingOptions === DigitalVotingTypeEnum.IngenStemming) {
        hasVoted.push({
          sectionId: section.id,
          voted: true,
        });
      } else if (section.votingOptions === DigitalVotingTypeEnum.Valg) {
        hasVoted.push({
          sectionId: section.id,
          voted: true,
        });
      } else if (section) {
        hasVoted.push({
          sectionId: section.id,
          voted: section.currentVoting !== DigitalCastVoteEnum.IkkeAngitt,
        });
      }

      // For all the children, we need to check if they have voted
      if (section.children) {
        for (let j = 0; j < section.children.length; j += 1) {
          const child = section.children[j];
          // No voting to be done
          if (child.votingOptions === DigitalVotingTypeEnum.IngenStemming) {
            hasVoted.push({
              sectionId: child.id,
              voted: true,
            });
          } else if (child.votingOptions === DigitalVotingTypeEnum.Valg) {
            hasVoted.push({
              sectionId: child.id,
              voted: true,
            });
          } else if (child) {
            hasVoted.push({
              sectionId: child.id,
              voted: child.currentVoting !== DigitalCastVoteEnum.IkkeAngitt,
            });
          }
        }
      }
    }

    setVotedArray(hasVoted);
  }, [sections, setVotedArray]);

  const setVoted = useCallback((sectionId: string): void => {
    const newVotedArray = votedArray.map((item) => {
      if (item.sectionId === sectionId) {
        return {
          ...item,
          voted: true,
        };
      }
      return item;
    });
    setVotedArray(newVotedArray);
  }, [votedArray, setVotedArray]);

  const value = useMemo(() => ({
    hasVoted: votedArray,
    setVoted,
  }), [votedArray, setVoted]);

  return (
    <HasVotedContext.Provider value={value}>
      {children}
    </HasVotedContext.Provider>
  );
};

type UseHasVotedProps = {
    sectionId: string
}

export const useHasVoted = ({ sectionId }: UseHasVotedProps): {
    voted: boolean,
    setVoted: (sectionId: string) => void,
    canVote: boolean,
} => {
  const { hasVoted, setVoted } = React.useContext(HasVotedContext);

  const voted = useMemo(() => !!(hasVoted.find((item) => item.sectionId === sectionId)?.voted), [hasVoted, sectionId]);

  // Check if all in front of this sections has voted set to true
  const canVote = useMemo(() => {
    // Find index of this section
    const index = hasVoted.findIndex((item) => item.sectionId === sectionId);

    // Check if all before this section has voted
    for (let i = 0; i < index; i += 1) {
      if (!hasVoted[i].voted) {
        return false;
      }
    }
    return true;
  }, [hasVoted, sectionId]);

  return { voted, setVoted, canVote };
};

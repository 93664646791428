/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

// Set up the client for fetching data
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { Link } from 'react-router-dom';
import InternalLink from '../common/components/link/InternalLink';
import Figure from '../common/components/figure/Figure';
import Quote from '../common/components/quote/Quote';
import Video from '../common/components/video/Video';
import LinkButton from '../common/components/link/LinkButton';
import BateButton, { ArrowType } from '../components/Elements/BateButton';
import BlogPostReference from '../common/components/blog/BlogPostReference';
import Facts from '../common/components/facts/Facts';
import Divider from '../common/components/divider/Divider';
import getInternalLinkModel from '../common/mapping/getInternalLinkModel';
import getImageModel from '../common/mapping/getImageModel';
import getQuoteModel from '../common/mapping/getQuoteModel';
import getBlogPostModel from '../common/mapping/getBlogPostModel';
import getVideoModel from '../common/mapping/getVideoModel';
import getLinkButtonModel from '../common/mapping/getLinkButtonModel';
import getFactsModel from '../common/mapping/getFactsModel';

export const config = {
  /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   * */
  dataset: process.env.REACT_APP_PUBLIC_SANITY_DATASET,
  projectId: process.env.REACT_APP_PUBLIC_SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === 'production',
  // https://www.sanity.io/docs/api-versioning
  apiVersion: '2021-12-01',
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   * */
};

if (!config.projectId) {
  throw Error('The Project ID is not set. Check your environment variables.');
}
if (!config.dataset) {
  throw Error('The dataset name is not set. Check your environment variables.');
}

// Helper function for easily switching between normal client and preview client
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getClient = () => sanityClient({ ...config });

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 * */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const urlFor = (source) => imageUrlBuilder(getClient()).image(source);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const shouldDisplayImage = (image) => {
  return !!(image && image.asset);
};

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      // We need to get valid text elements to show in link
      // If the element is of type string, we add it
      // If not, it is probably a React element with props.node.children
      // If it is, we get the valid text elements from that array

      // This means all text that is marked with internalLink, will be rendered,
      // but styling will not be preserved
      const validChildren = [];
      for (const child of children) {
        if (typeof child === 'string') {
          validChildren.push(child);
        } else if (child.props?.node?.children) {
          for (const propChild of child.props.node.children) {
            if (typeof propChild === 'string') {
              validChildren.push(propChild);
            }
          }
        }
      }

      return (
        <InternalLink
          link={getInternalLinkModel(mark, validChildren, mark.postfix)}
          inline
        />
      );
    },
    link: ({ mark, children }) => {
      const { blank, href } = mark;

      // :? Link to a page on minside?
      // :: TODO (?): Add page documents for all pages on minside to be able to use internal links for all pages.
      // For now it will be possible to link to e.g. <min side host>/medlemskap/bonus by using an standard link.
      if (href?.includes(window.location.origin)) {
        // Yes, this is a link to a page on min side.
        const url = new URL(href);

        // Extract relative url
        const relativeUrl = url.toString().substring(url.origin.length);

        // Use a react router link instead of a <a> tag for internal links.
        return (
          <BateButton
            useLink
            whiteBackground
            small
            href={relativeUrl}
            className="border-medium-light-grey rounded-bate text-black font-clanot-black test"
            arrowClassName="text-bate-red"
          >
            {children}
          </BateButton>
        );
      }

      return blank ? (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    fileLink: ({ mark, children }) => {
      return (
        <BateButton
          useLink
          whiteBackground
          small
          href={mark.asset.url}
          arrowType={ArrowType.Down}
          color="light-grey"
          className="border-medium-light-grey rounded-bate text-black font-clanot-black"
        >
          {children}
        </BateButton>
      );
    },
    inlineImage: ({ mark }) => {
      if (!shouldDisplayImage(mark)) return null;
      return (
        <img
          className="inline"
          src={mark.asset?.url}
          alt={mark.alt}
          style={{ width: (mark.width && `${mark.width}px`) || 'auto' }}
        />
      );
    },
  },
  types: {
    figure: ({ node }) => <Figure image={getImageModel(node)} />,
    quote: ({ node }) => <Quote quote={getQuoteModel(node)} />,
    postReference: ({ node }) => (
      <BlogPostReference post={getBlogPostModel(node)} />
    ),
    internalLink: ({ node }) => (
      <span style={{ color: 'red' }}>!! Feil: brutt lenke !!</span>
    ),
    video: ({ node }) => <Video video={getVideoModel(node)} />,
    linkButton: ({ node }) => <LinkButton button={getLinkButtonModel(node)} />,
    facts: ({ node }) => <Facts facts={getFactsModel(node)} />,
    divider: ({ node }) => <Divider width={node.width} isBlue={node.isBlue} />,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const PortableText = ({ blocks }) => {
  return (
    <BlockContent serializers={serializers} blocks={blocks} />
  );
};

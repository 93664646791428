import { VideoModel } from '../types/sanity';

const getVideoModel: (node: any) => VideoModel = (node) => {
  const video: VideoModel = {
    url: node.url,
    previewImage: node.previewImage,
  };
  return video;
};

export default getVideoModel;

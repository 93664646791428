import React from 'react';
import { MdLocalPhone, MdMailOutline } from 'react-icons/md';
import { HiArrowRight } from 'react-icons/hi';
import { CustomerServiceModel } from '../../../../types/sanity/footer';
import { SimpleLinkItemModel } from '../../../../common/types/sanity';
import SimpleLinkItem from '../../../Elements/SimpleLinkItem';

interface Props {
  customerService: CustomerServiceModel;
  contactLink?: SimpleLinkItemModel;
}

const ContactInfo: React.FC<Props> = ({ customerService, contactLink }) => {
  const phoneNumberLink : SimpleLinkItemModel | undefined = customerService.phoneNumber ? {
    text: customerService.phoneNumber,
    url: `tel:${customerService.phoneNumber.replace(/\s/g, '')}`,
  } : undefined;

  const emailLink : SimpleLinkItemModel | undefined = customerService.email ? {
    text: customerService.email,
    url: `mailto:${customerService.email}`,
  } : undefined;

  const openingTime = customerService.openingTime
    ? new Date(customerService.openingTime).toTimeString().substring(0, 5) : '';
  const closingTime = customerService.closingTime
    ? new Date(customerService.closingTime).toTimeString().substring(0, 5) : '';

  return (
    <>
      <ul className="font-clanot-news">
        { phoneNumberLink && (
        <li className="mb-3">
          <SimpleLinkItem linkItem={phoneNumberLink}>
            <MdLocalPhone className="inline-block mr-2 text-2xl" />
          </SimpleLinkItem>
        </li>
        )}
        { emailLink && (
        <li className="mb-3">
          <SimpleLinkItem linkItem={emailLink}>
            <MdMailOutline className="inline-block mr-2 text-2xl" />
          </SimpleLinkItem>
        </li>
        )}
        {/* TODO: Add chat */}
        {/* <li className="mb-3">
              <HiOutlineChatAlt2 className="inline-block mr-2 text-2xl" />
              <button
                type="button"
                onClick={() => (window as any).HubSpotConversations?.widget.open()}
              >
                Chat med oss
              </button>
            </li> */}
      </ul>
      <div className="py-4">
        <div className="text-sm font-clanot-bold">Åpent</div>
        <div className="font-clanot-news">
          {customerService.openingDays}
          .
          {' '}
          {openingTime}
          {' '}
          -
          {' '}
          {closingTime}
        </div>
      </div>
      {contactLink
          && (
          <div className="mb-3 font-clanot-news">
            <SimpleLinkItem linkItem={contactLink}>
              <HiArrowRight className="inline-block mr-2 text-2xl" />
            </SimpleLinkItem>
          </div>
          )}
    </>
  );
};

ContactInfo.defaultProps = {
  contactLink: undefined,
};

export default ContactInfo;

import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useAsyncEffect } from 'use-async-effect';
import { useForegroundLoader } from '../context/LoadingContext';
import { IHousingUnit, IRole } from '../types';
import {
  hasBoardPortalRole,
  hasMemberAndResidenceOwnerRoles,
  hasMemberOrResidenceOwnerRole,
  hasMemberRole,
  hasOnlyMemberRole,
  hasOnlyResidenceOwnerRole,
  hasResidenceOwnerRole,
} from './roles';
import { getClient } from './sanity.jsx';

export const useIsMounted = (): () => boolean => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

type FetchDataResult<T> = {
  data: T | undefined | null,
  loading: boolean,
  refetch: () => Promise<T | null | undefined | void> | T | null | undefined | void,
  setData: React.Dispatch<React.SetStateAction<T | null | undefined>>
}
type FetchDataResultInput<T> = {
  query?: string | null;
  params?: any | null;
  fetch?: (() => Promise<T | null>) | null;
  key?: string | null;
  skipInitialFetch?: boolean;
}

export const useFetchSanityData = <T = unknown>({
  query = null,
  params = null,
}: FetchDataResultInput<T>): FetchDataResult<T> => {
  const isMounted = useIsMounted();

  const [data, setData] = useState<T | undefined | null>(undefined);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async () => {
    if (query) {
      setLoading(true);

      const response: T = params ? await getClient().fetch(
        query,
        params,
      ) : await getClient().fetch(
        query,
      );

      if (isMounted()) {
        setData(response);
        setLoading(false);
      }
    }

    return () => setLoading(false);
  }, [query, params]);

  return {
    data, loading, refetch: () => console.log('Refetch not implemented'), setData,
  };
};

export const useFetchApiData = <T = unknown>({
  fetch = null,
  skipInitialFetch = false,
}: FetchDataResultInput<T>): FetchDataResult<T> => {
  const isMounted = useIsMounted();

  const [data, setData] = useState<T | undefined | null>(undefined);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (!fetch) return null;

    let response: T | null | undefined;
    try {
      setLoading(true);
      response = await fetch();

      if (isMounted()) {
        setData(response);
      }
    } catch {
      console.error('Error fetching data');
    }
    if (isMounted()) {
      setLoading(false);
    }
    return response;
  }, [fetch, isMounted]);

  useAsyncEffect(async () => {
    if (fetch && !skipInitialFetch) {
      await fetchData();
    }
  }, [fetch]);

  return {
    data, loading, refetch: fetchData, setData,
  };
};

interface UseFetchApiDataWithDependenciesAndLoadingParams<T> {
  fetch: (() => Promise<T>) | null;
  dependencies?: unknown[];
}

interface UseFetchApiDataWithDependenciesAndLoadingResult<T> {
  data: T | undefined | null;
  setData: React.Dispatch<React.SetStateAction<T | null | undefined>>;
}

export const useFetchDataWithDependenciesAndLoading = <T>
  ({ fetch, dependencies = [] }:
    UseFetchApiDataWithDependenciesAndLoadingParams<T>): UseFetchApiDataWithDependenciesAndLoadingResult<T> => {
  const {
    data, loading, refetch, setData,
  } = useFetchApiData({ fetch, skipInitialFetch: true });

  useAsyncEffect(async () => {
    if (fetch) {
      // Check that all dependencies is not undefined or null
      if (dependencies.every((dependency) => !!dependency)) {
        await refetch();
      }
    }
  }, [fetch, setData, ...dependencies]);

  useForegroundLoader([loading]);

  return { data, setData };
};

// https://github.com/chaudharykiran/SimpleReactValidatorWithHooks
export const useValidator = (customMessages = {}, customValidators = {}): [validator: SimpleReactValidator, setShow: React.Dispatch<React.SetStateAction<boolean>>] => {
  const [show, setShow] = useState(false);
  const validator = new SimpleReactValidator({
    messages: customMessages,
    validators: customValidators,
  });

  if (show) {
    validator.showMessages();
  }

  return [validator, setShow];
};

export const useInterval = (callback: () => void, delay: number): void => {
  useEffect(
    () => {
      const id = setInterval(callback, delay);
      return () => clearInterval(id);
    },
    [callback, delay],
  );
};

export const useHasMemberRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasMemberRole(roles);
  }, [roles]);
};

export const useHasResidenceOwnerRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasResidenceOwnerRole(roles);
  }, [roles]);
};

export const useHasMemberOrResidenceOwnerRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasMemberOrResidenceOwnerRole(roles);
  }, [roles]);
};

export const useHasMemberAndResidenceOwnerRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasMemberAndResidenceOwnerRoles(roles);
  }, [roles]);
};

export const useHasOnlyMemberRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasOnlyMemberRole(roles);
  }, [roles]);
};

export const useHasOnlyResidenceOwnerRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasOnlyResidenceOwnerRole(roles);
  }, [roles]);
};

export const useHasBoardPortalRole = (roles: IRole[] | null): boolean => {
  return useMemo(() => {
    return hasBoardPortalRole(roles);
  }, [roles]);
};

export const useIsBrl = (currentResidence: IHousingUnit | null): boolean => {
  return currentResidence?.companyType === 'HousingAssociation';
};

type WindowSize = {
  width: number | undefined;
  height: number | undefined;
}

// Hook
export const useWindowSize = (): WindowSize => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    const handleResize = (): void => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

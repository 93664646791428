export interface ActiveSubmittalMeeting {
  id: string;
  title: string;
  clientId: string;
  clientName: string;
  sectionSubmittalDeadline: Date;
  isDigitalMeeting: boolean;
  isDelegateMeeting: boolean;
  previewStart: Date | null;
  commentsStart: Date | null;
  commentsDeadline: Date | null;
  votingStart: Date | null;
  votingDeadline: Date | null;
  physicalMeetingStart: Date | null;
}

export interface IAttachmentMetadataModel {
  blobId: number | null;
  filename: string;
  mimeType: string;
  id: string;
  sortId: number;
}

export interface ISuggestedSection {
  attachments: IAttachmentMetadataModel[];
  created: Date | null;
  description: string;
  id: string;
  suggestedDescision: string;
  title: string;
}

export enum MeetingStateEnum {
  Comments = 'Comments',
  None = 'None',
  Preview = 'Preview',
  Voting = 'Voting'
}

export enum CompanyTypeEnum {
  Cooperative = 'Cooperative',
  Corporation = 'Corporation',
  Foundation = 'Foundation',
  JointOwnership = 'JointOwnership',
  SharedCooperative ='SharedCooperative',
  Unknown = 'Unknown'
}

export enum MeetingTypeEnum {
  Extraordinary = 'Extraordinary',
  Standard = 'Standard'
}

export interface IMeetingQueryModel {
  clientId: string;
  clientName: string;
  clientNo: number | null;
  commentsDeadline: Date | null;
  commentsStart: Date | null;
  companyType: CompanyTypeEnum | null;
  companyTypeDescription: string;
  deadline: Date | null;
  id: string;
  isDelegateMeeting: boolean | null;
  isProxyMeeting: boolean | null;
  meetingAllowsVotingFraction: boolean | null;
  meetingState: MeetingStateEnum | null;
  meetingType: MeetingTypeEnum | null;
  propertyIds: string[];
  proxyId: string;
  proxyOwner: string;
  start: Date | null;
  title: string;
  votingFraction: any[];
}

export interface IMeetingDetailModel {
  allowVoting: boolean | null;
  clientId: string;
  clientName: string;
  clientNo: number | null;
  commentsDeadline: string | null;
  commentsStart: string | null;
  companyType: CompanyTypeEnum | null;
  companyTypeDescription: string;
  deadline: string | null;
  hasProducedNotice: boolean | null;
  isCollectedVotingToggled: boolean | null;
  isDelegateMeeting: boolean | null;
  meetingAllowsVotingFraction: boolean | null;
  meetingState: MeetingStateEnum | null;
  meetingType: string;
  proxyId: string;
  proxyOwnerName: string;
  sections: IMeetingSectionModel[];
  start: string | null;
  startedBy: string;
  title: string;
  votingFraction: number | null;
}

export interface IMeetingSectionModel {
  attachments: ISectionAttachmentModel[];
  boardRecommendation: string;
  currentVoting: DigitalCastVoteEnum | null;
  description: string;
  election: IMeetingElectionFunctionModel;
  hasAarsmeldingPdf: boolean | null;
  hasRevisjonsberetningPdf: boolean | null;
  hasSignertAarsregnskapPdf: boolean | null;
  id: string;
  index: number | null;
  parentIndex: number | null;
  sectionId: string;
  suggestedDescision: string;
  title: string;
  userComments: IMeetingCommentModel[];
  votingId: string;
  votingOptions: DigitalVotingTypeEnum | null;
}

export enum DigitalVotingTypeEnum {
  Akklamasjon = 'Akklamasjon',
  Beslutningssak = 'Beslutningssak',
  IkkeAngitt = 'IkkeAngitt',
  IngenStemming = 'IngenStemming',
  TilOrientering = 'TilOrientering',
  Valg = 'Valg'
}

export interface IMeetingCommentModel {
  comment: string;
  commentedBy: string;
  commentedByFunction: string;
  commentedByNameId: string;
  documentSectionId: string;
  id: string;
  isCachable: boolean | null;
  isDeleted: boolean | null;
  isUpdated: boolean | null;
  lastUpdated: string | null;
  when: string | null;
}

export interface IMeetingElectionFunctionModel {
  candidates: IMeetingElectionCandidateModel[];
  electionDescription: string;
  functionDescription: string;
  functionId: number | null;
  numberOfPersons: number | null;
  sectionId: string;
}

export interface IMeetingElectionCandidateModel {
  candidateVotingId: string;
  id: string;
  isNominatedByCommittee: boolean | null;
  name: string;
  reelection: boolean | null;
  sortNumber: number | null;
  votedFor: boolean | null;
}

export interface ISectionAttachmentModel {
  filnavn: string;
  id: string;
  mimetype: string;
  sekvensteller: number | null;
}

export interface IMeetingElectionVoteModel {
  candidate: IMeetingElectionCandidateModel;
  oldValue: boolean | null;
  newValue: boolean | null;
}

export enum DigitalCastVoteEnum {
  Blankt = 'Blankt',
  For = 'For',
  IkkeAngitt = 'IkkeAngitt',
  Mot = 'Mot',
  TattTilOrientering = 'TattTilOrientering',
  Valgt = 'Valgt'
}

export interface HistoricalMeetingModel {
  fromDate: Date;
  hasNotice: boolean;
  hasProtocol: boolean;
  id: string;
  name: string;
  toDate: Date;
}

export interface PhysicalMeetingModel {
  id: string;
  meetingTitle: string;
  location?: string;
  meetingDate: Date;
  description?: string;
  meetingType: string;
  sectionSubmittalDeadline?: Date;
  previewStart?: Date;
  isProxyOwner: boolean;
  isOwner: boolean;
  clientName: string;
  clientId: string;
  companyType: string;
  proxyOwnerName: string;
  isDelegateMeeting: boolean;
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { BlogPost } from '../../types/blog';
// eslint-disable-next-line import/no-cycle
import { urlFor } from '../../../utils/sanity.jsx';
import styles from './BlogPostReference.module.scss';
import { shouldDisplayImage } from '../../utils/methods';

interface Props {
  post: BlogPost;
}

const BlogPostReference: React.FC<Props> = ({ post }) => {
  return (
    <div
      className={`${styles.container} lg:w-10/12 border-t-2 border-primary post-reference`}
    >
      <div className="flex mt-4 justify-center items-center">
        <div className="w-4/12">
          {shouldDisplayImage(post.image) && (
            <Link to={`${process.env.REACT_APP_BATE_NO_URL}/batebloggen/${post.slug}`}>
              <a>
                <figure>
                  <img
                    className="w-full"
                    src={urlFor(post.image)
                      .width(260)
                      .height(160)
                      .fit('crop')
                      .auto('format')
                      .url() || undefined}
                    alt={post.image.alt}
                  />
                </figure>
              </a>
            </Link>
          )}
        </div>
        <div className="w-8/12">
          <div className="ml-6 xl:ml-8">
            <span className="text-xs">LES OGSÅ:</span>
            <div className="text-sm mt-1 font-clanot-bold">
              <Link to={`${process.env.REACT_APP_BATE_NO_URL}/batebloggen/${post.slug}`}>
                <a>{post.title}</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostReference;

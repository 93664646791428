import { filter, groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { BATE_RED_HEADING_STYLE } from '../../../common/utils/constants';
import { useAppContext } from '../../../context/AppContext';
import { hasResidenceProxyRoleForUnit } from '../../../utils/roles';
import RoundedBox from '../../Containers/RoundedBox';

interface Props {
  shouldDisplayBoardMessage?: boolean;
  simpleView?: boolean;
  fullWidth?: boolean;
  noFormat?: boolean;
  noHeader?: boolean;
}

interface WrapperProps {
  noFormat?: boolean;
  shouldDisplayBoardMessage?: boolean;
  children: any;
}

const SelectWrapper: React.FC<WrapperProps> = ({ noFormat, shouldDisplayBoardMessage, children }) => {
  if (noFormat) {
    return children;
  }
  return (
    <RoundedBox className={`${shouldDisplayBoardMessage ? '' : 'md:col-start-2 lg:col-start-3 h-fit'}`}>
      {children}
    </RoundedBox>

  );
};

const ResidenceSelector: React.FC<Props> = ({
  shouldDisplayBoardMessage = false, simpleView = false, fullWidth = false, noFormat = false, noHeader = false,
}) => {
  const {
    roles, residenceList, currentResidence, setCurrentResidence,
  } = useAppContext();

  const [selectIsOpen, setSelectIsOpen] = useState(false);

  const selectResidence: (residenceId: string) => void = (residenceId) => {
    const residence = residenceList.filter((x) => x.id === residenceId)[0];
    setCurrentResidence(residence);
    localStorage.setItem('selectedResidence', residenceId);
  };

  const isProxyOwnerForCurrentResidence = useMemo(() => {
    return hasResidenceProxyRoleForUnit(roles, currentResidence?.id);
  }, [currentResidence, roles]);

  const groupedResidences = useMemo(() => filter(
    groupBy(residenceList, (r) => r.clientName),
    (g) => g.length > 0,
  ), [residenceList]);

  type SelectOption = {
    readonly label: string,
    readonly value: string,
  }

  type GroupedOption = {
    readonly label: string;
    readonly options: SelectOption[];
  }

  const groupedSelectOptions = useMemo<GroupedOption[]>(() => groupedResidences.map((group) => ({
    label: group[0].clientName,
    options: group.map((residence) => ({
      label: `${residence.address} ${residence.ssbNo ?? ''}`,
      value: residence.id,
    })),
  })), [groupedResidences]);

  const currentSelectValue = useMemo(() => {
    if (!currentResidence) return undefined;

    return ({
      label: `${currentResidence.address} ${currentResidence.ssbNo ?? ''}`,
      value: currentResidence.id,
    });
  }, [currentResidence]);

  if (!residenceList || !currentResidence) {
    return null;
  }

  return (
    <SelectWrapper shouldDisplayBoardMessage={shouldDisplayBoardMessage} noFormat={noFormat}>
      <div>
        {!noHeader && <h2 className={BATE_RED_HEADING_STYLE}>Min bolig</h2>}
        {residenceList.length > 1 ? (
          <Select<SelectOption, false, GroupedOption>
            defaultValue={currentSelectValue}
            options={groupedSelectOptions}
            onChange={(e) => {
              if (e) {
                selectResidence(e.value);
              }
            }}
            className={`select-filter ${noHeader ? '' : 'mt-8'} ${fullWidth ? 'select-filter--full-width' : ''}`}
            placeholder="Søk..."
            noOptionsMessage={() => 'Ingen resultater for søk'}
            // Hide selected value if menu is open to make search more visible
            controlShouldRenderValue={!selectIsOpen}
            onMenuOpen={() => setSelectIsOpen(true)}
            onMenuClose={() => setSelectIsOpen(false)}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: '#f0f4f8',
                paddingBlock: 16,
                paddingRight: 12,
                paddingLeft: 16,
                borderRadius: 50,
                cursor: 'pointer',
                ':hover': {
                  borderColor: '#dc151d',
                },
                boxShadow: state.isFocused ? '0 0 0 1px #dc151d' : 'inherit',
                borderColor: state.isFocused ? '#dc151d' : 'inherit',
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: '#f0f4f8',
              }),
              // Hide indicator in favor of :before indicator added by select-filter class
              indicatorSeparator: (baseStyles) => ({
                display: 'none',
              }),
              dropdownIndicator: (baseStyles) => ({
                display: 'none',
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? '#dc151d' : 'inherit',
                cursor: 'pointer',
                ':hover': {
                  background: state.isSelected ? '#ff1818' : '#e0e4e8',
                },
              }),
            }}
          />
        ) : (
          <div className="mt-8">
            {isProxyOwnerForCurrentResidence && simpleView ? <div>Fullmaktshaver for:</div> : null}
            {simpleView ? currentResidence.address : currentResidence.clientName}
          </div>
        )}
        {!simpleView
          && (
            <div className="mt-8">
              {isProxyOwnerForCurrentResidence ? <p>Fullmaktshaver for:</p> : null}
              <p>
                {currentResidence.address}
              </p>
              <p>
                {currentResidence.zip}
                {' '}
                {currentResidence.city}
              </p>
              {currentResidence.ssbNo ? (
                <p>
                  Leilighetsnummer:
                  {' '}
                  {currentResidence.ssbNo}
                </p>
              ) : null}
              {currentResidence.propertySectionNo ? (
                <p>
                  Andelsnummer:
                  {' '}
                  {currentResidence.propertySectionNo}
                </p>
              ) : null}
              {currentResidence.owners && (
                <div className="pt-8">
                  <div className="pb-4 font-clanot-black">Registrerte beboere</div>
                  {currentResidence.owners.map((owner, index) => (
                    <div key={owner.email ?? index} className="flex pb-6">
                      <div className="w-8 pr-1">
                        {owner.pictureUrl ? (
                          <img
                            className="h-6 w-6 rounded-full"
                            src={owner.pictureUrl}
                            alt={`${owner.firstName} ${owner.lastName}`}
                          />
                        ) : null}
                      </div>
                      <div>
                        <div className="text-sm">
                          {owner.firstName}
                          {' '}
                          {owner.lastName}
                        </div>
                        <div className="text-xs">
                          {owner.email}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
      </div>
    </SelectWrapper>
  );
};

ResidenceSelector.defaultProps = {
  shouldDisplayBoardMessage: false,
  simpleView: false,
  fullWidth: false,
};

export default ResidenceSelector;
